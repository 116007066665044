@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import "bootstrap";
@import "../../layout-one/sass/variables";
@import "../../layout-one/sass/mixins";
@import "../../layout-one/sass/global";

.guest-modal {
  height: auto !important;
  position: fixed !important;
  z-index: 9999;
  bottom: 0;
  // left: 0;
  // right: 0;
  background-color: #F8F8F8 !important;
  overflow-x: hidden;
  transition: 0.5s;
  // width: 100% !important;
  border-radius: 20px 20px 0px 0px;
  font-size:  13px !important;
// @include middleMin {
//     width: 49.8% !important;
// }
@include above1200 {
    width: 49.8% !important;
}

  

  .mobCode {
    color: #000000 !important;
  }

  .BackBtn {
    padding: 5px !important;
    background-color: #e8e8e8 !important;
    color: #000000 !important;
    font-family: GraphikArabic !important;
    font-weight: 600 !important;
    box-shadow: none !important;
  }

  h1 {
    font-size: 30px !important;
    line-height: 41px;

    @include below768 {
      font-size: 20px !important;
    }
  }

  .textf4f4 {
    color: map-get($colors, "textf4f4");
  }

  .textf9f9 {
    color: map-get($colors, "textf9f9");
  }

  .termsAndCondition {
    .MuiSvgIcon-root {
      font-size: 1rem !important;
    }

  }

  .rights-reserved {
    color: map-get($colors, "text7C7A7A");
  }

  hr {
    border: 1px solid #000000;
    opacity: 10% !important;
  }

  .loginTitle {
    font-size: 16px;
    line-height: 22px;
    color: #4f4f4f !important;
  }

  // .notRegisterMain {
  //   span {
  //     font-size: 16px !important;
  //     @include below768 {
  //       font-size: 12px !important;
  //     }

  //   }
  // }
  .notRegister {
    // border-bottom: 1px solid #000000 !important;
    // padding-bottom:  5px !important;
    // padding-top: 5px !important;
    color: #000000;
  }

  .createAccountAndforgotPass {
    cursor: pointer !important;
    color: #000000 !important;
    text-decoration: underline !important;
  }



  .MuiButton-root {
    border-radius: $borderRadius  !important;
    text-transform: none !important;
    font-size: 0.75rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .rememberMeSec {
    p {
      font-size: 16px !important;

      @include below768 {
        font-size: 12px !important;
      }

      @include x-small-max {
        font-size: 10px !important;
      }
    }
  }

  .selectCountry {
    legend {
      display: none !important;
    }

    .MuiSelect-icon {
      @include below375 {
        display: none !important;
      }
    }
  }

  // padding for sajid bhai
  .classforBtnPadding {
    // .MuiButton-root {
    //   padding: 5px 16px !important;
    // }
  }

  .classforPaddingTablist {

    // .TabUnstyled-root {
    //   padding: 0px !important;
    // }
    .TabUnstyled-root {
      padding: 9px 12px !important;
    }
  }

  .classforPaddingTextfield {
    .MuiOutlinedInput-input {
      text-align: left !important;
      padding: 16.5px 14px !important;
    }

    // .MuiInputLabel-root {
    //   top: -6px !important;
    //   color: #353535 !important;
    //   font-size: $paragraphSize;
    //   font-family: Poppins !important;
    //   font-weight: 300;
    // }
  }

  .classforPaddinglogo {
    img {
      height: 52px !important;
    }
  }

  .classforPaddingcheckbox {
    .MuiCheckbox-root {
      padding: 0px !important;

      &:hover {
        background-color: transparent !important;
      }
    }
  }

  .classforPaddingSeclectTextfield {
    .MuiOutlinedInput-input {
      padding: 15px 8px !important;
      // padding: 6.5px 14px !important;
    }

    .MuiFormControl-root {
      margin: 11px !important;
    }
  }

  // padding for sajid bhai
  .arabicBtn {
    padding: 5px !important;
    background-color: #e8e8e8 !important;
    color: #000000 !important;
    font-family: GraphikArabic !important;
    font-weight: 600 !important;
    box-shadow: none !important;
  }

  .checkboxParent {
    .MuiIconButton-label {
      color: #000000 !important;
    }
  }

  .MuiOutlinedInput-root {
    border-radius: $borderRadius  !important;
    font-size: $paragraphSize;
    font-family: Poppins !important;
    color: #353535;
    font-weight: 300;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 2px solid #e3e3e3 !important;
  }

  .LoginModalTab {
    .percentage {
      font-size: 12px !important;
      color: #666666;

      @include below768 {
        font-size: 9px !important;
      }

      @include x-small-max {
        font-size: 6px !important;
      }
    }

    .price {
      font-size: 15px !important;

      @include below768 {
        font-size: 12px !important;
      }

      @include x-small-max {
        font-size: 8px !important;
      }
    }

    .TabsListUnstyled-root {
      min-width: 0px !important;
      margin-bottom: 0px;
    }
  }

  .tabNavigation {
    .Mui-selected {
      background-color: #ffffff !important;
      border-radius: $borderRadius  !important;
      color: #000000;
      font-size: 9px !important;
      font-family: Poppins !important;
      font-weight: 800;
      outline: none !important;

      @include below375 {
        font-size: 7px !important;
      }

      @include x-small-max {
        font-size: 6px !important;
      }

      &::focus {
        outline: none !important;
      }
    }

    .TabUnstyled-root {
      background-color: #e8e8e8;
      border-radius: $borderRadius  !important;
      font-size: 9px !important;
      font-family: Poppins !important;
      font-weight: 800;
      color: #000000;

      @include below375 {
        font-size: 7px !important;
      }

      @include x-small-max {
        font-size: 6px !important;
      }
    }

    .TabsListUnstyled-root {
      background-color: #e8e8e8;
    }
  }

  // className for arabic
  .classforArabicPhone {
    direction: ltr !important;
  }

  .classforArabic {
    .MuiTextField-root {
      position: relative !important;
    }

    .MuiInputLabel-root {
      right: 27px !important;
      width: fit-content !important;
    }

    // .Mui-focused{
    //   right: 0px !important;
    // }
    .MuiOutlinedInput-notchedOutline {
      text-align: right !important;
    }

    .MuiOutlinedInput-input {
      text-align: right !important;
    }

    .MuiInputLabel-shrink {
      transform-origin: top right !important;
    }
  }

  .classforArabicSelectField {
    .MuiSelect-outlined {
      position: relative !important;
    }

    .MuiSelect-icon {
      position: absolute !important;
      left: 5px !important;
      right: auto !important;
    }
  }

  // className for arabic
  .textCapitalized {
    text-transform: capitalize;
  }

  .fontGraphik {
    font-family: GraphikArabic !important;
  }

  .classForNonSelectableInput {
    .MuiOutlinedInput-input {
      padding: 14.5px 14px !important;
    }

    .countryCode {
      @include below375 {
        display: none !important;
      }
    }

    .flagImg {
      @include below375 {
        display: none !important;
      }
    }
  }

  // .socialLoginIconOnLargeScreen {
  //   @include below425 {
  //     display: none !important;
  //   }
  // }

  // .socialLoginIconOnsmallScreen {
  //   display: none !important;
  //   @include below425 {
  //     display: flex !important;
  //   }
  // }
  .MuiBackdrop-root {
    @include above768 {
      background-color: transparent !important;
    }
  }
.mb-14{
  margin-bottom: 12px !important;
}

.error-text {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
}
}

.guest-modal-arabic {
  // margin-left: auto !important;
  // right: 0 !important;
  // left: auto !important;
  // width: 100% !important;
  // @include above992 {
  //     width:49.666667% !important;
  // }

  .classforArabicTextField {
    .MuiInputLabel-root {
      right: 11px;
      left: auto !important;
      padding-right: 14px;
    }

    .Mui-focused {
      padding-right: 0px !important;
      right: 0px !important;
    }

    .MuiOutlinedInput-notchedOutline {
      text-align: right !important;
      right: 0 !important;
    }
  }

  .classforArabicPhoneField {
    .MuiInputLabel-root {
      right: 11px;
      left: auto !important;
    }

    label.Mui-focused {
      padding-right: 0px !important;
      right: 11px !important;
    }

    .MuiOutlinedInput-notchedOutline {
      text-align: right !important;
      right: 0 !important;
    }
  }
}
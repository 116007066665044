// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

#deliveryOptionsBtns{
  font-size: 12px !important;
  padding: 8px 20px !important;
    .subMenu-content{
      background: #FAFAFA !important;
      
    p {
      font-size: 1rem;
      font-family: 'Manrope';
    }
    .des{
      font-size: 0.9rem;
      font-family: 'Manrope';
      color: gary !important;
    }
  
    svg{
      color: gray !important;
    }
  
    .MuiCheckbox-colorSecondary.Mui-checked {
      color: $th-primary;
    }
    .MuiButtonBase-root{
      width: 100% !important;
      border-radius: 15px !important;
    }
    }

    .activeClass{
      border: 2px solid $th-primary !important;
      border-radius: 15px !important;
    }
   .timeInputContainer {
      display: flex !important; 
      align-items: flex-end !important;
      // justify-items: flex-end;
    }
    .active{
        background-color: $th-primary !important;
    }
    .non-active{
      border-color: $th-primary !important;
      color: $th-primary !important;
    }
  
    .timeClass{
    
      .MuiOutlinedInput-root{
        border: $th-primary;
        border-radius: 10px !important;
      }
      .MuiInputBase-root{
        width: 100% !important;
      }
      
      input{
        font-size: 2rem !important;
        text-align: center !important;
        color: black !important
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $th-primary !important; /* Focused border color */
      }
    }
    /* customStyles.css */
.custom-calendar {
  border: 2px solid $th-primary !important; 
  border-radius: 8px;
  padding: 10px;
}

.custom-calendar .react-calendar__tile {
  background-color: #f0f8ff;
}

.custom-calendar .react-calendar__tile--active {
  background-color: #007BFF;
  color: white;
}
.btn-div{
.next-btn{
  background: $th-primary !important;
  border: none;
  color: white;
  border-radius: 10px !important;
  width: 25% !important;
  margin-top: 10px !important;

  i {
      font-size: 1rem !important;
      color: white !important;
  }
}
} 
.optionsBtn{
  border-radius: 10px !important;
  transition: 0.3s;
  border: 1px solid $th-primary !important;
  color: $th-primary !important;
  width: 100% !important;
  margin-top: 10px !important;
}

.option-btn-active{
  color: #f0f8ff !important;
  transition: 0.3s;
  background-color: $th-primary !important;
  border-radius: 10px !important;
  border: 1px solid $th-primary !important;
  width: 100% !important;
  margin-top: 10px !important;
}

.btn-padding5{
  padding: 2px !important;
}
.btn-padding22 {
  padding: 14px 10px !important
}
.show-time-content{
  font-size: 12px !important;
}
  }
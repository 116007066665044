// $large: 1200px;
// $low: 768px;
// $x-small: 360px;
// $medium: 992px;
// $x-large: 1440px;
// $M-mobile: 375px;
// $Moderate-mobile: 425px;

// kanwar sikandar ali

@mixin lowMax {
    @media (max-width:#{$low}) {
      @content;
    }
  }
  
  @mixin extraSmall {
    @media (max-width:#{$x-small}) {
      @content;
    }
  }
  @mixin above768 {
    @media (min-width:#{$low}) {
      @content;
    }
  }
  @mixin below768 {
    @media (max-width:#{$low}) {
      @content;
    }
  }
  @mixin below992 {
    @media (max-width:#{$medium}) {
      @content;
    }
  }
  
  @mixin below1200 {
    @media (max-width:#{$large}) {
      @content;
    }
  }
  @mixin above1200 {
    @media (min-width:#{$large}) {
      @content;
    }
  }
  @mixin above1440 {
    @media (min-width:#{$x-large}) {
      @content;
    }
  }
  @mixin above425 {
    @media (min-width:#{$Moderate-mobile}) {
        @content;
    }
}
  @mixin below425 {
    @media (max-width:#{$Moderate-mobile}) {
      @content;
    }
  }
  @mixin below375 {
    @media (max-width:#{$M-mobile}) {
      @content;
    }
  }
  
  @mixin paymentMethodBtn {
    background-color: #f4f4f4 !important;
    border-radius: $borderRadius !important;
    color: #000000;
    font-size: $paragraphSize;
    font-family: Manrope !important;
    font-weight: 800;
  }
  // kanwar sikandar ali

  
@mixin Large {
    @media (min-width:#{$Large}) {
        @content;
    }
}

@mixin Medium {
    @media (min-width: #{$Medium}) {
        @content;
    }
}

@mixin LargeMax {
    @media (max-width: #{$Large}) {
        @content;
    }
}

@mixin x-small-max {
    @media (max-width: #{$x-small-max}) {
        @content;
    }
}

@mixin TopNavBar {
    position: absolute;
    width: 100%;
    top: 25%;
    .row {
        width: 100%;
        justify-content: space-between;
        padding: 5px 20px !important;
        a {
            color: #fff;
        }
        .signInBtn {
            color: #fff !important;
            background: transparent !important;
            border-radius: 0 !important;
            height: 100%;
            border: 1px solid #fff !important;
        }
    }
}
@mixin TopNavBarPayment {
    position: absolute;
    width: 100%;
    top: 11%;
    z-index: 1;
    .row {
        width: 100%;
        justify-content: space-between;
        align-items: center !important;
        padding: 5px 20px !important;
        a {
            color: #fff;
        }
        .signInBtn {
            color: #fff !important;
            background: transparent !important;
            border-radius: 0 !important;
            height: 100%;
            // border: 1px solid #fff !important;
        }
    }
}


.login-fields{
    display: flex;
    align-items: center;
    justify-content: center;
}


@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import "bootstrap";
@import "../../layout-one/sass/variables";
@import "../../layout-one/sass/mixins";
@import "../../layout-one/sass/global";

.classToTargetEnLoadModal {



  .MuiPaper-root {
    border-radius: 0px !important;
    height: 100% !important;
    margin: 0px !important;

    @media (min-width: 630px) {
      height: 100% !important;
    }


    @media (min-width: 425px) {
      height: 100% !important;
    }

    @media (min-width: 375) {
      height: 100% !important;
    }

    .MuiDialogContent-root {
      display: flex !important;
      flex-direction: column !important;
    }


    .promotionBannerParent {
      position: relative !important;
      height: 100% !important;
      background: #ffff !important;
      // background-color: #fe595a;


      .promotionBannerImage {
        object-fit: contain !important;
        height: 100%;
        width: 100%;
      }
     

      .loadingContent {
        position: absolute;
        bottom: 40px;

        .loadingImage {
          height: 100%;

        }

        .loadingText {
          font-weight: 700 !important;
          // color: rgba(0, 0, 0, 0) !important;
          font-size: 12px !important;
        }
        

        .dot-bricks {
          position: relative;
          top: 8px;
          left: -9999px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: rgba(254, 89, 90, 1); /* updated to #fe595a */
          color: rgba(254, 89, 90, 1); /* updated to #fe595a */
          box-shadow: 
            9991px -16px 0 0 rgba(254, 89, 90, 0.2), 
            9991px 0 0 0 rgba(254, 89, 90, 0.5), 
            10007px 0 0 0 rgba(254, 89, 90, 1);
          animation: dot-bricks-simple 2s infinite ease;
        }
        
        @keyframes dot-bricks-simple {
          0% {
            box-shadow: 9991px -16px 0 0 rgba(254, 89, 90, 1), 9991px 0 0 0 rgba(254, 89, 90, 1), 10007px 0 0 0 rgba(254, 89, 90, 1);
          }
          8.333% {
            box-shadow: 10007px -16px 0 0 rgba(254, 89, 90, 1), 9991px 0 0 0 rgba(254, 89, 90, 1), 10007px 0 0 0 rgba(254, 89, 90, 1);
          }
          16.667% {
            box-shadow: 10007px -16px 0 0 rgba(254, 89, 90, 1), 9991px -16px 0 0 rgba(254, 89, 90, 1), 10007px 0 0 0 rgba(254, 89, 90, 1);
          }
          25% {
            box-shadow: 10007px -16px 0 0 rgba(254, 89, 90, 1), 9991px -16px 0 0 rgba(254, 89, 90, 1), 9991px 0 0 0 rgba(254, 89, 90, 1);
          }
          33.333% {
            box-shadow: 10007px 0 0 0 rgba(254, 89, 90, 0.5), 9991px -16px 0 0 rgba(254, 89, 90, 0.5), 9991px 0 0 0 rgba(254, 89, 90, 0.5);
          }
          41.667% {
            box-shadow: 10007px 0 0 0 rgba(254, 89, 90, 0.5), 10007px -16px 0 0 rgba(254, 89, 90, 0.5), 9991px 0 0 0 rgba(254, 89, 90, 0.5);
          }
          50% {
            box-shadow: 10007px 0 0 0 rgba(254, 89, 90, 0.5), 10007px -16px 0 0 rgba(254, 89, 90, 0.5), 9991px -16px 0 0 rgba(254, 89, 90, 0.5);
          }
          58.333% {
            box-shadow: 9991px 0 0 0 rgba(254, 89, 90, 0.5), 10007px -16px 0 0 rgba(254, 89, 90, 0.5), 9991px -16px 0 0 rgba(254, 89, 90, 0.5);
          }
          66.666% {
            box-shadow: 9991px 0 0 0 rgba(254, 89, 90, 0.5), 10007px 0 0 0 rgba(254, 89, 90, 0.5), 9991px -16px 0 0 rgba(254, 89, 90, 0.5);
          }
          75% {
            box-shadow: 9991px 0 0 0 rgba(254, 89, 90, 0.2), 10007px 0 0 0 rgba(254, 89, 90, 0.2), 10007px -16px 0 0 rgba(254, 89, 90, 0.2);
          }
          83.333% {
            box-shadow: 9991px -16px 0 0 rgba(254, 89, 90, 1), 10007px 0 0 0 rgba(254, 89, 90, 1), 10007px -16px 0 0 rgba(254, 89, 90, 1);
          }
          91.667% {
            box-shadow: 9991px -16px 0 0 rgba(254, 89, 90, 1), 9991px 0 0 0 rgba(254, 89, 90, 1), 10007px -16px 0 0 rgba(254, 89, 90, 1);
          }
          100% {
            box-shadow: 9991px -16px 0 0 rgba(254, 89, 90, 1), 9991px 0 0 0 rgba(254, 89, 90, 1), 10007px 0 0 0 rgba(254, 89, 90, 1);
          }
        }
        
      }

      .loadingContentWithOutImage{
        position: absolute;
        bottom: 60px;

        .loadingImage {
          height: 18px;

        }

        .loadingText {
          font-weight: 700 !important;
          // color: rgba(0, 0, 0, 0) !important;
          font-size: 12px !important;
        }

        .dot-bricks {
          position: relative;
          top: 8px;
          left: -9999px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #EB5B5E;
          color: #EB5B5E;
          box-shadow: 9991px -16px 0 0 #EB5B5E, 9991px 0 0 0 #EB5B5E, 10007px 0 0 0 #EB5B5E;
          animation: dot-bricks 2s infinite ease;
        }
        
        @keyframes dot-bricks {
          0% {
            box-shadow: 9991px -16px 0 0 #EB5B5E, 9991px 0 0 0 #EB5B5E, 10007px 0 0 0 #EB5B5E;
          }
          8.333% {
            box-shadow: 10007px -16px 0 0 #EB5B5E, 9991px 0 0 0 #EB5B5E, 10007px 0 0 0 #EB5B5E;
          }
          16.667% {
            box-shadow: 10007px -16px 0 0 #EB5B5E, 9991px -16px 0 0 #EB5B5E, 10007px 0 0 0 #EB5B5E;
          }
          25% {
            box-shadow: 10007px -16px 0 0 #EB5B5E, 9991px -16px 0 0 #EB5B5E, 9991px 0 0 0 #EB5B5E;
          }
          33.333% {
            box-shadow: 10007px 0 0 0 #EB5B5E, 9991px -16px 0 0 #EB5B5E, 9991px 0 0 0 #EB5B5E;
          }
          41.667% {
            box-shadow: 10007px 0 0 0 #EB5B5E, 10007px -16px 0 0 #EB5B5E, 9991px 0 0 0 #EB5B5E;
          }
          50% {
            box-shadow: 10007px 0 0 0 #EB5B5E, 10007px -16px 0 0 #EB5B5E, 9991px -16px 0 0 #EB5B5E;
          }
          58.333% {
            box-shadow: 9991px 0 0 0 #EB5B5E, 10007px -16px 0 0 #EB5B5E, 9991px -16px 0 0 #EB5B5E;
          }
          66.666% {
            box-shadow: 9991px 0 0 0 #EB5B5E, 10007px 0 0 0 #EB5B5E, 9991px -16px 0 0 #EB5B5E;
          }
          75% {
            box-shadow: 9991px 0 0 0 #EB5B5E, 10007px 0 0 0 #EB5B5E, 10007px -16px 0 0 #EB5B5E;
          }
          83.333% {
            box-shadow: 9991px -16px 0 0 #EB5B5E, 10007px 0 0 0 #EB5B5E, 10007px -16px 0 0 #EB5B5E;
          }
          91.667% {
            box-shadow: 9991px -16px 0 0 #EB5B5E, 9991px 0 0 0 #EB5B5E, 10007px -16px 0 0 #EB5B5E;
          }
          100% {
            box-shadow: 9991px -16px 0 0 #EB5B5E, 9991px 0 0 0 #EB5B5E, 10007px 0 0 0 #EB5B5E;
          }
        }
      }
    }

    .close {
      position: absolute !important;
      // position: fixed !important;
      right: 14px !important;
      top: 10px !important;
      color: #fff !important;
      width: 29.5px !important;
      height: 29.5px !important;
      background: #FFFFFF !important;
      border-radius: 24px !important;
      opacity: 1 !important;
      z-index: 9999 !important;

      .MuiSvgIcon-root {
        font-size: 30px !important;
        color: #fff !important;
        margin-top: 10px !important;
      }


    }
    .bottomBtn {
      .confirmBtn {
        background-color: $th-primary  !important;
        color: #FFF !important;
        border-radius: 10px !important;
        font-size: inherit !important;
        text-transform: capitalize !important;
        border: none !important;
      }
    }
    .MuiButton-root {
      border-radius: $borderRadius  !important;
      text-transform: none !important;
      font-size: 0.75rem;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }

}
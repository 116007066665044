// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

.DiscountOptionsMain {
  // padding: 0 20px !important;
  // .MuiTabs-root{
  //   padding: 0 20px !important;
  // }
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  .reOrderheading {
    font-size: 1.3rem;
    color: #000000;
  }

  .vocherBtn{
    font-size: 1rem !important;
    color: $th-primary !important;
  }

  .owl-carousel {
    .owl-nav {
      display: none !important;
      justify-content: space-between !important;
      position: absolute !important;
      top: 80px !important;
      width: 100% !important;
      margin-top: 0px !important;

      button {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: #fff !important;
        color: #000000 !important;
        box-shadow: 0px 2px 18px rgb(0 0 0 / 9%);
        font-size: 1.25rem;
        margin: 0 !important;

      }
    }

    .owl-dots {
      display: none !important;
    }

    .owl-stage-outer {
      .owl-stage {
        display: flex !important;
        align-items: center !important;
      }

      .owl-item {
        border-radius: 16px;
        padding: 0px 0px !important;
      }
    }

    .cardProductName {
      color: #FFF !important;
      font-size: 16px !important;

      @include below375 {
        font-size: 10px !important;
      }
    }

    .cardPrice {
      color: rgba(255, 255, 255, 0.60) !important;
      font-size: 16px !important;
      font-weight: 300;

      // opacity: 0.6 !important;
      @include below375 {
        font-size: 11px !important;
      }
    }

    .cardDates {
      color: #666666 !important;
      font-size: 12px !important;

      @include below375 {
        font-size: 10px !important;
      }
    }

    .item {
      padding: 0px 4px !important;

      .cardNotActive {
        opacity: 0.8 !important;
        pointer-events: none !important;
      }

      .cardActive {
        cursor: pointer !important;
      }
      .disableClass{
        opacity: 0.7 !important;
      }
    }

    .orderAgain {
      .MuiButton-root {
        color: rgb(32, 32, 32) !important;
        // border-radius: 15px !important;
        background-color: transparent !important;
        font-weight: bolder !important;
      }

      .MuiButton-label {
        @include below768 {
          font-size: 10px !important;
        }
      }
    }

    .skipBtn {
      color: #000000 !important;
      text-decoration: underline !important;
    }

    .smallAvatar {
      .MuiAvatarGroup-root {
        justify-content: flex-end !important;
      }

      .MuiAvatar-root {
        @include below1200 {
          height: 30px !important;
          width: 30px !important;
        }
      }

      .MuiAvatar-root {
        border-radius: $borderRadius  !important;
      }
    }

  }

  .productsRow {

    .CardRow {
      .cardContent {
        padding: 30px 0px !important;
        border-radius: 8px;
        // height: 140px;
        // border: 1px solid var(--light-grayscale-100, #EEE);
        background: #FFF;
        .cancelIcon {
          position: absolute !important;
          right: 10px;
          top: 5px;
        }

        .punchRatingMain {
          .Mui-disabled {
            opacity: 100 !important;
          }

          .MuiRating-decimal {
            .MuiRating-iconFilled {
              color: $th-primary  !important;
            }

            .MuiRating-iconEmpty {
              color: $th-primary  !important;
            }
          }

          .CheckboxParent {
            .Mui-checked {
              color: $th-primary  !important;
            }
        
          }
        }
      }

      .cardImage {
        padding: 30px 0px !important;
        background: radial-gradient(0px at 0 0, #0000 98%, #f9f9f9) 0 0 /51% 51% no-repeat,
          radial-gradient(16px at 100% 0, #0000 98%, #f9f9f9) 100% 0 /51% 51% no-repeat,
          radial-gradient(0px at 0 100%, #0000 98%, #f9f9f9) 0 100%/51% 51% no-repeat,
          radial-gradient(16px at 100% 100%, #0000 98%, #f9f9f9) 100% 100%/51% 51% no-repeat;
        border-radius: 16px 0px 0px 16px !important;
        filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.1));

        // box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        // box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
        // background-color: rgb(255, 255, 255);
      }

      .cardProductName {
        color: #000000 !important;
        font-size: 16px !important;

        @include below375 {
          font-size: 10px !important;
        }
      }

      .cardPrice {
        color: #5d5e65 !important;
        font-size: 16px !important;
        font-weight: 300;

        // opacity: 0.6 !important;
        @include below375 {
          font-size: 11px !important;
        }
      }

      .cardDates {
        color: #666666 !important;
        font-size: 12px !important;

        @include below375 {
          font-size: 10px !important;
        }
      }

      .item {
        padding: 0px 15px !important;
      }

      .orderAgain {
        .MuiButton-root {
          color: rgb(32, 32, 32) !important;
          // border-radius: 15px !important;
          background-color: transparent !important;
          font-weight: bolder !important;
        }

        .MuiButton-label {
          @include below768 {
            font-size: 10px !important;
          }
        }
      }

      .skipBtn {
        color: #000000 !important;
        text-decoration: underline !important;
      }

      .smallAvatar {
        .MuiAvatarGroup-root {
          justify-content: flex-end !important;
        }

        .MuiAvatar-root {
          @include below1200 {
            height: 30px !important;
            width: 30px !important;
          }
        }

        .MuiAvatar-root {
          border-radius: $borderRadius  !important;
        }
      }
    }

    .radio-item {
      margin: 5px;
      padding: 10px;
      cursor: pointer;

      &.selected {
        border: 2px solid $th-primary  !important;
        border-radius: 10px !important;
      }
    }

    .cardNotActive {
      opacity: 0.8 !important;
      pointer-events: none !important;
    }

    .cardActive {
      cursor: pointer !important;
    }
  }


  .loyaltyTabsParent {
    direction: ltr !important;

    .showIndicator{
      .MuiTabs-indicator {
        background-color: #EB5B5E !important;
        height: 3px !important;
      }
    }
    .dontshowIndicator{
      .MuiTabs-indicator {
       display: none;
      }
    }

    

    .categoryTab {
      padding: 0 !important;
      margin: 0px 6px 0px 6px !important;
      cursor: none !important;
      width: 100% !important;
      color: #fff;
      border-radius: 8px !important;
      text-align: center;
      position: relative;
      height: 150px;
      // border: 1px solid #EEE !important;
      display: flex;
      flex-direction: column;
      justify-content: start !important;

      .CardRow {
        border-radius: $borderRadius !important;
        padding: 0 0px !important;
        .cardContent {
          // padding: 30px 0px !important;
          .punchRatingMain {
            .Mui-disabled {
              opacity: 100 !important;
              font-size: 1.3rem !important ;
              gap: 3px !important;
            }

            .MuiRating-decimal {

              .MuiRating-iconFilled {
                color: #fff  !important;
              }

              .MuiRating-iconEmpty {
                color: #fff   !important;

              }
            }
          }
        }
      }
    }

    .Mui-disabled {
      opacity: 1 !important;
    }

    .MuiTabScrollButton-root {
      display: none !important;
      svg {
        font-size: 30px !important;
        font-family: Poppins !important;
        color: #74757B !important;
      }

    }


    .largeAvatar {
      .MuiAvatar-root {
        height: 70px !important;
        width: 70px !important;
  
        @include below425 {
          height: 70px !important;
          width: 70px !important;
        }
  
        img {
          border-radius: 10px;
        }
      }
    }
  
    .cardProductName {
      color: #FFF !important;
      font-size: 16px !important;
  
      @include below375 {
        font-size: 10px !important;
      }
    }
    .cardPrice {
      color: rgba(255, 255, 255, 0.60) !important;
      font-size: 16px !important;
      font-weight: 300;
  
      // opacity: 0.6 !important;
      @include below375 {
        font-size: 11px !important;
      }
    }
  
    .cardDates {
      color: #666666 !important;
      font-size: 12px !important;
  
      @include below375 {
        font-size: 10px !important;
      }
    }
  
    .featuredAppBar {
      // .MuiButtonBase-root {
      //   border: 1px solid
      // }
      .Mui-checked{

        color: $th-primary !important;
      }
    }
   
  }

  .loyaltyTabsParentEn {
    .MuiTabScrollButton-root {
      position: absolute !important;
      top: 14px !important;
      right: 0 !important;
      display: none !important;
    }

    [data-testid="KeyboardArrowLeftIcon"] {
      position: absolute;
      top: 1px;
      right: 66px;
    }

    [data-testid="KeyboardArrowRightIcon"] {
      position: absolute;
      top: 1px;
      left: 0px;
      // transform: scaleX(1);
    }
  }

  .loyaltyTabsParentArabic {
    .MuiTabScrollButton-root {
      position: absolute !important;
      top: 24px !important;
      left: 0 !important;
      display: none !important;
    }

    [data-testid="KeyboardArrowLeftIcon"] {
      position: absolute;
      top: -8px;
      left: 22px;
      // transform: scaleX(-1);
    }

    [data-testid="KeyboardArrowRightIcon"] {
      position: absolute;
      top: -8px;
      left: 70px;
      // transform: scaleX(-1);
    }

  }
}


.DiscountOptionsMainArabic {
  .owl-carousel {
    direction: ltr !important;

    .owl-stage-outer {
      direction: ltr !important;

      .owl-stage {
        direction: ltr !important;
      }

      .owl-item {
        direction: ltr !important;
      }

      .active {
        direction: ltr !important;
      }
    }
  }
}

.promoVocherMain{
 .MuiButton-textPrimary{
  color: $th-primary !important;
 } 
}
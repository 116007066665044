@mixin above1440 {
    @media (min-width:#{$x-large}) {
        @content;
    }
}

@mixin below1200 {
    @media (max-width:#{$Large}) {
        @content;
    }
}

@mixin above1200 {
    @media (min-width:#{$Large}) {
        @content;
    }
}

@mixin middleMin {
    @media (min-width:#{$middle}) {
        @content;
    }
}

@mixin lowMax {
    @media (max-width:#{$low}) {
        @content;
    }
}

@mixin above768 {
    @media (min-width:#{$low}) {
        @content;
    }
}

@mixin middleMax {
    @media (max-width: #{$middle}) {
        @content;
    }
}

@mixin x-small-max {
    @media (max-width: #{$x-small-max}) {
        @content;
    }
}

@mixin above425 {
    @media (min-width:#{$Moderate-mobile}) {
        @content;
    }
}

@mixin below425 {
    @media (max-width:#{$Moderate-mobile}) {
        @content;
    }
}

@mixin below768 {
    @media (max-width:#{$low}) {
        @content;
    }
}

@mixin below375 {
    @media (max-width:#{$M-mobile}) {
        @content;
    }
}

@mixin below600 {
    @media (max-width:#{$Screen600}) {
        @content;
    }
}




// $x-large: 1440px;
// $high: 1200px;
// $Large: 1200px;
// $low: 768px;
// $Moderate-mobile: 425px;
// $M-mobile: 375px;
// $x-small-max: 360px;
// $Screen600: 600px;
// $Screen628: 600px;

@mixin buttonAbsolutePart {
    position: absolute;
    background: $th-primary; //Darken 10%
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    color: white;
}

@mixin close_btn {
    position: absolute;
    top: 6%;
    right: 5%;
    cursor: pointer;
}

@mixin TopNavBar {
    position: fixed;
    width: 100%;
    top: 1%;
    z-index: 1;
    transition: all 0.5s ease-in-out;

    .classForSvgColor {
        svg {
            color: #fff !important;
            transition: all 0.5s ease-in-out;

            path {
                color: #fff !important;
            }
        }
    }

    .row {
        width: 50%;

        @include below1200 {
            width: 100% !important;
        }
                justify-content: space-between;
        align-items: center;

        .arabicBtn {
            position: relative !important;

            .arabicText {
                position: relative !important;
                bottom: 5px !important;
            }
        }

        .arabicbutton {
            position: relative !important;

            .arabicbuttonSpan {
                position: relative !important;
                bottom: 4px !important;
            }
        }

        .engBtn {
            position: relative !important;

            .engBtnSpan {
                position: relative !important;
                bottom: 0px !important;
            }
        }

        a {
            color: #fff;
            transition: all 0.5s ease-in-out;
        }

        .signInBtn {
            display: block;

            @include middleMax {
                display: block;
            }

            border: none !important;
            color: #fff !important;
            font-size: 1.2rem !important;
            padding: 15px !important;
            transition: all 0.5s ease-in-out;

            .fa-stack-1x {
                line-height: inherit !important;
                position: absolute !important;
                top: -2px !important;
                color: #fff !important;
                transition: all 0.5s ease-in-out;
            }

            .fa-stack-2x {
                color: #0693e3 !important;
            }
        }
    }

    .fa-shopping-cart:before {
        content: "\f07a";
        font-size: 1.2rem !important;
    }
}

@mixin TopNavBarScroll {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
    transition: all 0.5s ease-in-out;

    .classForSvgColor {
        svg {
            color: $th-primary !important;
            transition: all 0.5s ease-in-out;

            path {
                fill: $th-primary !important;
            }
        }
    }

    .row {
        width: 50%;
        @include below1200 {
            width: 100% !important;
        }
        justify-content: space-between;
        align-items: center;
        background-color: white;
        transition: all 0.5s ease-in-out;
        border-bottom: 1px solid lightgray;

        .arabicbutton {
            position: relative !important;

            .arabicbuttonSpan {
                position: relative !important;
                bottom: 4px !important;
            }
        }

        .arabicBtn {
            position: relative !important;

            .arabicText {
                position: relative !important;
                bottom: 5px !important;
            }
        }

        .engBtn {
            position: relative !important;

            .engBtnSpan {
                position: relative !important;
                bottom: 0px !important;
            }
        }

        a {
            color: $th-primary !important;
            transition: all 0.5s ease-in-out;
        }

        .backBtn {
            color: $th-primary !important;
            transition: all 0.5s ease-in-out;
        }

        .signInBtn {
            display: block;

            @include middleMax {
                display: block;
            }

            border: none !important;
            color: $th-primary !important;
            font-size: 1.2rem !important;
            padding: 15px !important;

            .fa-stack-1x {
                line-height: inherit !important;
                position: absolute !important;
                top: -2px !important;

                color: $th-primary !important;
                transition: all 0.5s ease-in-out;
            }

            .fa-stack-2x {
                color: $th-primary !important;
                transition: all 0.5s ease-in-out;
            }
        }
    }

    .fa-shopping-cart:before {
        content: "\f07a";
        font-size: 1.2rem !important;
    }
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

a {
    &:hover {
        text-decoration: none;
    }
}

button {
    &:focus {
        outline: none;
    }
}

.text-primary {
    color: $th-primary !important;
}

.text-secondary {
    color: $th-secondary !important;
}

.p-relative {
    position: relative;
}

a {
    &:hover {
        text-decoration: none;
    }
}

.prColumn {
    min-height: 60vh;
}

.circularProgressContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
        color: $th-primary;
    }
}

.minHeight70 {
    min-height: 70vh;
}

.errorField {
    fieldset {
        border-color: red !important;
    }

    .MuiInput-underline:before {
        border-color: red !important;
    }
}

.err_message {
    color: red;
}
.mb_fix {
    background-color: #fff;
    position: fixed;
    bottom: 3%;
    width: 100%;
    left: 0;
    @include Large {
        position: relative;
    }
}
.cart {
    padding: 20px;
    @include Large {
        padding: 0;
    }
}

.cPointer {
    cursor: pointer;
}

.btn-main {
    color: #fff !important;
    background: $th-primary !important;
    padding: 5px 10px;

    &.btn-main-inverse {
        background: none;
        color: $th-primary;

        &:hover {
            background: #eee;
            color: $th-primary;
        }
    }

    &.btn-main-outline {
        background: #fff;
        color: $th-primary;
        border: 1px solid $th-primary-lighten; //Lighten 20%

        &:hover {
            // border: 1px solid darken($th-primary, 20%);
            background: $th-primary-darken;
            color: #fff;
        }
    }
   

    @media screen and (max-width: 480px) {
        &.btnSmallContact{
            font-size: 11px !important;
        }
      }

    a {
        &:hover {
            color: rgb(0, 0, 0) !important;
            background: $th-primary-darken !important; //Darken 10%
        }
    }
}


.btn-cart {
    color: #fff !important;
    background: $th-secondary !important;
    padding: 5px 10px;
    border-radius:  3px !important;
    &.btn-main-inverse {
        background: none;
        color: $th-primary;

        &:hover {
            background: #eee;
            color: $th-primary;
        }
    }

    &.btn-main-outline {
        background: #fff;
        color: $th-primary;
        border: 1px solid $th-primary-lighten; //Lighten 20%

        &:hover {
            // border: 1px solid darken($th-primary, 20%);
            background: $th-primary-darken;
            color: #fff;
        }
    }

    a {
        &:hover {
            color: rgb(0, 0, 0) !important;
            background: $th-primary-darken !important; //Darken 10%
        }
    }
    .MuiSvgIcon-root {
    padding: 0px !important;
    }
}




// 27 sep 

.MuiFormControl-root{
    width: 100% !important;
}
.MuiFormControl-marginNormal{
    margin-top: 0px !important;
}
.MuiSelect-select:focus{
    background-color: #F9F9F9 !important;
}

.NotFoundorAvailable{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: red !important;
    font-family: Montserrat !important;
    font-size: 17px !important;

}
.mobileHide{
    @include Medium {
        display: block !important;
    }
display: none !important;
}

.mobileShow{
    @include Medium{
        display: none !important;
    }
display: block !important;
}
.hoverColorRemove:hover{
    color: white !important;

}
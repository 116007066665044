@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import "bootstrap";
@import "../Sass/variables";
@import "../Sass/mixins";
@import "../Sass/global";
@import "../Sass/svg_animate";

#verifyPhoneNumberMain {
  padding-bottom: 200px;
  h1 {
    font-size: 30px !important;
    line-height: 41px;
    @include below375 {
      font-size: 20px !important;
      line-height: 21px;
    }
  }
  .loginTitle {
    font-size: 13px;
    line-height: 22px;
    color: #4f4f4f !important;
  }
  .optMain {
    div {
      justify-content: space-between;
      padding-top: 5px !important;
    }
    // .otpValue{
    //   input{
    //     border: 2px solid #000000 !important;
    //   }

    // }
    .otpInput {
      input {
        width: 57px !important ;
        height: 57px !important;
        border-radius: $borderRadius !important;
        font-family: Poppins !important;
        font-weight: 600 !important;
        border: 2px solid #e3e3e3;
        &:focus {
          border-radius: $borderRadius !important;
        }
        @include below768 {
          width: 50px !important ;
          height: 50px !important;
        }
        @include below375 {
          width: 35px !important ;
          height: 35px !important;
          border-radius: 5px !important;
          &:focus {
            border-radius: 5px !important;
          }
        }
        @include extraSmall {
          width: 28px !important ;
          height: 28px !important;
          border-radius: 5px !important;
          &:focus {
            border-radius: 5px !important;
          }
        }
      }
    }
  }
  .classforArabic {
    .otpInput {
      input {
        margin-right: 0px !important;
      }
    }
  }

  .notRegisterMain {
    span {
      font-size: 16px !important;
      @include below768 {
        font-size: 10px !important;
      }
      @include extraSmall {
        font-size: 8px !important;
      }
    }
  }
  .notRegister {
    color: #4f4f4f;
  }
  .createAccountAndforgotPass {
    color: #000000 !important;
    text-decoration: underline !important;
    cursor: pointer !important;
    background: transparent !important;
    border: none !important;
  }
  .MuiButton-root {
    border-radius: $borderRadius !important;
  }

  // padding for sajid bhai

  .classforPaddinglogo {
    img {
      height: 52px !important;
    }
  }

  // padding for sajid bhai
}

#verifyModal {
  .MuiDialog-paperFullWidth {
    max-width: 415px !important;
    border-radius: 24px !important;
  }
}

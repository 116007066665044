// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
// @import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

#cartItemMainl1 {
  .MuiAvatar-root {
    height: 120px !important;
    width: 120px !important;

    @include below375 {
      height: 100px !important;
      width: 100px !important;
    }

    @include x-small-max  {
      height: 50px !important;
      width: 50px !important;
    }
  }

  .familyManrope800 {
    font-family: Poppins !important;
    font-weight: 800 !important;
  }

  .imgDiv {
    img {
      border-radius: 10px;
    }

    // border-radius: $borderRadius;
    // width: 80px;
  }

  .deletebtn {
    text-decoration: none !important;
    // background-color: rgba(0, 0, 0, 0.04) !important;
    background-color: #E3E3E3 !important;
    border-radius: 40px !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 300 !important;

  }

  h1 {
    font-size: 20px;
    color: #000000;
    font-weight: 300 !important;

  }

  h3 {
    font-size: 18px;
    color: #000000;
    font-weight: 300 !important;
  }

  h4 {
    color: #545454;
    font-size: 16px !important;
    opacity: 0.6 !important;
    font-weight: 300 !important;
  }

  p {
    font-size: $paragraphSize;
    color: #000000;
    font-weight: 300;
    font-family: Manrope;
    font-size: 16px !important;

  }


  .card-main {
    @include above768 {
      font-size: 10px !important;
      background-color: #ffff;
      border-radius: $borderRadius;
      box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.09);
    }

  }

  .IncDecBtnMain {
    .MuiButton-root {
      height: 36px !important;
      min-width: 36px !important;
      padding: 0px !important;
      border-radius: $borderRadius;
      width: 36px;

      @include below1200 {
        height: 26px !important;
        min-width: 26px !important;
        width: 26px;
      }
    }

    .decrementBtn {
      background-color: #BABABA !important;
    }

    .incrementBtn {
      background-color: $th-primary  !important;
    }



    .IncDecValue {
      height: 43px;
      width: 36px;

      @include below1200 {
        height: 27px !important;

        width: 36px;
      }
    }
  }

  .addonsWrapEn{
    margin-left: 120px !important;

    @include below375 {
      margin-left: 100px !important;
    }

    @include x-small-max  {
      margin-left: 50px !important;
    }

    .addonsh3 {
      font-size: 16px;
      color: #000000;

      @include below375 {
        font-size: 12px !important;
      }
    }
  
    .addonsh4{
      color: #545454;
      font-size: 16px;
      opacity: 0.6 !important;
      @include below375 {
        font-size: 12px !important;
      }
      
    }   
  }
  .addonsWrapAr{
    margin-right: 120px !important;

    @include below375 {
      margin-right: 100px !important;
    }

    @include x-small-max  {
      margin-right: 50px !important;
    }

    .addonsh3 {
      font-size: 16px;
      color: #000000;
      

      @include below375 {
        font-size: 12px !important;
      }
    }
  
    .addonsh4{
      color: #545454;
      font-size: 16px;
      opacity: 0.6 !important;
      @include below375 {
        font-size: 12px !important;
      }
      
    }   
  }

  .addonsMainDiv{
    svg{
      font-size: 0.7rem !important;
      margin-bottom: 0.5rem !important;
    }
  }

  .w-70 {
    width: 70%;
  }

  .w-80 {
    width: 80%;
  }

  .w-30 {
    width: 30%;
  }

  .w-20 {
    width: 20%;
  }

  .font-weight-900 {
    font-weight: 900 !important;
  }

  .rejectedColor {
    background-color: #f74e4e;
    color: #fff;
  }

  .readyColor {
    background-color: #53c146;
    color: #fff;
  }

  .pendingColor {
    background-color: #ffa500;
    color: #fff;
  }

  .processColor {
    background-color: #4595ff;
    color: #fff;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import "bootstrap";
@import "../../layout-one/sass/variables";
@import "../../layout-one/sass/mixins";
@import "../../layout-one/sass/global";

.classToTargetsomModal {



  .MuiPaper-root {
    border-radius: 20px !important;
    height: auto !important;
    // border: 10px solid yellow !important;  
    margin: 14px !important;
    @media (min-width: 630px){
      height: auto !important;
    }


    @media (min-width: 425px){
      height: auto !important;
    }

    @media (min-width: 375){
      height: auto !important;
    }
    .MuiDialogContent-root {
      display: flex !important;
      flex-direction: column !important;
      // justify-content: space-between !important;
    }

    .owl-dots {
      // display: none !important ;
      // position: absolute !important;
      // top: 415px !important;
      width: 100%;
      // margin: 20px 0px !important;
      height: 30px;

      .owl-dot.active {
        span {
          width: 25px !important;
          background-color: $th-primary  !important;
          // opacity: 100% !important;
        }
      }

      .owl-dot {
        span {
          width: 17px;
          height: 6px !important;
          background: #D6D6D6 !important;
          // opacity: 50% !important ;
        }
      }
    }

    .owl-carousel {
      display: none;
    }

    .no-js {
      .owl-carousel {
        display: block;
      }
    }

    .owl-carousel.owl-loaded {
      display: block;
    }

    .owl-carousel {
      height: auto !important;
     
      // @media (max-height: 768px) {
      //   height: 75% !important;

      // }
      // @media (max-height: 500px) {
      //   height: 60% !important;

      // }

      @media (min-width: 630px){
        height: auto !important;
      }
  
  
      @media (min-width: 425px){
        height: auto !important;
      }
      @media (max-width: 375px){
        height: auto !important;
      }
      // @media (min-width: 375){
      //   height: 70% !important;
      // }






      .owl-stage-outer {
        height: 100% !important;

        .owl-stage {
          height: 100% !important;

          .owl-item.active {
            height: 100% !important;

          }
        //   .owl-item {
        //     float: left !important;
        //     width: 100px !important;
        //     height: 100px !important;
        //     object-fit: cover !important;
        // }

          .owl-item {
            height: 100% !important;
            background-color: #f9f9f9;
              
          }
        }
      }
          
    }

    .promotionBannerParent {
      position: relative !important;
      height: 100% !important;    
       

      .promotionBannerImage {
        // border-radius: 16px !important;
        // height: auto !important;
        // min-height: 415px !important;
        // height: 100% !important;
        object-fit: contain !important;
        // display: block !important;
        // width: auto !important;
        // max-height: 100% !important
        // img{
        //   float: left !important;
        //   width: 100px !important;
        //   height: 100px !important;
        //   object-fit: cover !important;
        // }
       
      }
    }

    .close {
      position: absolute !important;
      // position: fixed !important;
      right: 14px !important;
      top: 10px !important;
      color: #fff !important;
      width: 29.5px !important;
      height: 29.5px !important;
      background: #FFFFFF !important;
      border-radius: 24px !important;
      opacity: 1 !important;
      z-index: 9999 !important;

      .MuiSvgIcon-root {
        font-size: 30px !important;
        color: #fff !important;
        margin-top: 10px !important;
      }


    }


    .bottomBtn {
      .confirmBtn {
        background-color: $th-primary  !important;
        color: #FFF !important;
        border-radius: 10px !important;
        font-size: inherit !important;
        text-transform: capitalize !important;
        border: none !important;
      }



    }

    .MuiButton-root {
      border-radius: $borderRadius  !important;
      text-transform: none !important;
      font-size: 0.75rem;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }

}
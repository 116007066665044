@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import "bootstrap";
@import "../../layout-one/sass/variables";
@import "../../layout-one/sass/mixins";
@import "../../layout-one/sass/global";

.verifyPhoneNumberv2Popup {
  height: auto !important;
  position: fixed !important;
  z-index: 999999;
  bottom: 0;
  // left: 0;
  // right: 0;
  background-color: #F8F8F8 !important;
  overflow-x: hidden;
  transition: 0.5s;
  width: 100% !important;
  border-radius: 20px 20px 0px 0px;
  padding-bottom: 200px !important;
  .MuiBackdrop-root {
    z-index: 5 !important;
  }
 @include above1200 {
      width: 49.8% !important;
  }

  .MuiInputBase-input {
    padding: 16.5px 14px !important;
  }
  padding-bottom: 200px !important;



  .BackBtn {
    background-color: #e8e8e8 !important;
    color: #000000 !important;
    font-family: GraphikArabic !important;
    font-weight: 600 !important;
    box-shadow: none !important;
  }

  h1 {
    font-size: 30px !important;
    line-height: 41px;

    @include below768 {
      font-size: 20px !important;
    }
  }

  .loginTitle {
    color: #4f4f4f !important;
  }

  .textf4f4 {
    color: map-get($colors, "textf4f4");
  }

  .textf9f9 {
    color: map-get($colors, "textf9f9");
  }

  .textEB5B5E {
    color: #EB5B5E;
  }

  .termsAndCondition {
    .MuiSvgIcon-root {
      font-size: 1rem !important;
    }

  }

  .hideBelow425 {
    @include below425 {
      display: none !important;
    }

  }

  .showBelow425 {
    display: none;

    @include below425 {
      display: flex !important;
    }
  }

  .rights-reserved {
    color: map-get($colors, "text7C7A7A");
  }

  hr {
    border: 1px solid #000000;
    opacity: 10% !important;
  }

  .optMain {
    div {
      justify-content: space-between;
      padding-top: 5px !important;
      direction: ltr !important;
      font-size: 2rem !important;
    }

    .MuiOutlinedInput-input {
      text-align: center !important;
      padding: 0px 14px !important;
    }



  }

  .classforArabic {
    .MuiInputLabel-shrink {
      transform-origin: top right !important;
    }
  }

  .btnEffect {

    background-position: center;
    transition: background 1.5s;
    border-radius: 10px;


    &:hover {
      background: transparent radial-gradient(circle, transparent 10%, transparent 10%) center/15000%;
    }

    &:active {
      background-color: #e8e8e8;
      background-size: 100%;
      transition: background 0s;
      border-radius: 10px;
    }

  }

  .arabicBtn {
    background-color: #e8e8e8 !important;
    color: #000000 !important;
    font-family: GraphikArabic !important;
    font-weight: 600 !important;
    box-shadow: none !important;
  }

  // .notRegisterMain {
  //   span {
  //     font-size: 16px !important;
  //     @include below768 {
  //       font-size: 10px !important;
  //     }
  //     @include extraSmall {
  //       font-size: 8px !important;
  //     }
  //   }
  // }
  .notRegister {
    color: #000000 !important;
  }

  .createAccountAndforgotPass {
    color: #000000 !important;
    text-decoration: underline !important;
    cursor: pointer !important;
    background: transparent !important;
    border: none !important;
  }

  .MuiButton-root {
    border-radius: $borderRadius  !important;
  }

  // padding for sajid bhai

  .classforPaddinglogo {
    img {
      height: 52px !important;
    }
  }




}

.verifyPhoneNumberv2PopupArabic {
  // margin-left: auto !important;
  // right: 0 !important;
  // left: auto !important; 
  width: 100% !important;
  @include above1200 {
      width: 49.8% !important;
  }
}
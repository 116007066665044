@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import "bootstrap";
@import "../../layout-one/sass/variables";
@import "../../layout-one/sass/mixins";
@import "../../layout-one/sass/global";

.tipPopup {
    height: auto !important;
    position: fixed !important;
    z-index: 99999;
    bottom: 0;
    // left: 0;
    // right: 0;
    background-color: #fff !important;
    overflow-x: hidden;
    transition: 0.5s;
    width: 100% !important;
    border-radius: 20px 20px 0px 0px;
  // @include middleMin {
  //     width: 49.8% !important;
  // }
  @include above1200 {
      width: 49.8% !important;
  }

  a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;

    &:hover {
      color: #f1f1f1;
    }
  }

  .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    display: none !important;
  }
  .content {
    h1 {
      font-size: 18px !important;
      color: #000;
    }

    .MuiButton-root {
      border-radius: $borderRadius !important;
      width: 100% !important;
      pointer-events: initial !important;
      font-size: $paragraphSize;
      color: #fff !important;
      font-family: "Poppins", sans-serif;
      font-weight: 600 !important;
      background-color: #272727 !important;
      cursor: pointer !important;
    }
    .MuiSvgIcon-root {
      font-size: 1.1rem !important;
    }
  }

  #tip {
    .tip-content {
      .Mui-selected {
        background-color: #f4f4f4 !important;
        border-radius: $borderRadius  !important;
        border: 2px solid #000000;
        color: #000000;
        font-size: 9px !important;
        font-family: Poppins !important;
        font-weight: 800;
  
        @include below375 {
          font-size: 7px !important;
        }
  
        @include x-small-max {
          font-size: 6px !important;
        }
      }
  
      .TabUnstyled-root {
        border: 1px solid #f4f4f4 !important;
        background-color: #fff;
        border-radius: 0 !important;
        font-size: 16px !important;
        font-family: Poppins !important;
        font-weight: 800;
        color: #000000;
  
        @include below1200 {
          font-size: 13px !important;
        }
  
        @include below375 {
          font-size: 10px !important;
        }
  
        @include x-small-max {
          font-size: 8px !important;
        }
      }
  
      .TabsListUnstyled-root {
        background-color: #ffff !important;
        min-width: 100% !important;
        margin: 0px !important;
      }
  
      .percentage {
        font-size: 12px !important;
        color: #666666;
  
        @include below768 {
          font-size: 9px !important;
        }
  
        @include x-small-max {
          font-size: 6px !important;
        }
      }
  
      .price {
        font-size: 13px !important;
  
        @include below768 {
          font-size: 10px !important;
        }
  
        @include x-small-max {
          font-size: 8px !important;
        }
      }
  
      img {
        height: 22px !important;
      }
    }
  
    // margin-top: 5%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 5px 20px;
  
    .tip-content {
      display: flex;
      // background: #f9f9f9;
      padding: 10px !important;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px !important;
    }
  
    h2 {
      font-size: 1rem;
      font-weight: 300;
    }
  
    h4 {
      font-size: 1rem;
      font-weight: 300;
      color: #000;
    }
  
    h3 {
      font-size: 1.3rem;
      font-weight: 300;
    }
  
    .icons {
      display: flex;
      align-items: center;
  
      img {
        margin: 0px 10px;
      }
    }
  
    .MuiCheckbox-colorSecondary.Mui-checked {
      color: $th-primary;
    }
  }
  #TipCheckBoxParent {
    margin-top: 20px !important;
  
    .TipselectPaymentRow {
      .MuiButton-label {
        height: 100% !important;
      }
  
      .tipManinCol {
        padding: 0 0.25rem !important;
  
        &:nth-child(1) {
          padding: 0 !important;
        }
  
        &:nth-child(4) {
          padding: 0 !important;
  
        }
      }
  
      .MuiButton-outlined {
        border: none !important;
      }
  
      .paymentContainer {
        position: relative;
        text-align: center !important;
        height: 100% !important;
  
        input {
          visibility: hidden;
          position: absolute;
        }
  
        .tiptext {
          color: #9898a1 !important;
          font-size: 18px !important;
        }
  
        p {
          font-size: 20px;
  
        }
        .customText{
          font-size: 0.8rem !important;
        }
  
        span {
          font-size: 0.8rem;
  
          @include below1200 {
            font-size: 0.8rem;
          }
  
          @include below375 {
            font-size: 0.7rem;
          }
  
  
        }
  
        label {
          padding: 0px !important;
          width: 100%;
          height: 100%;
          background: #fff;
          // line-height: 60px;
          border: 1px solid #E5E5E5 !important;
          cursor: pointer;
          transition: 0.3s ease;
          user-select: none;
          opacity: 1 !important;
          text-align: center !important;
          border-radius: 12px !important;
  
          &:hover {
            background: #fff;
            border: 1px solid #E5E5E5 !important;
            // opacity: 1;
          }
        }
  
        input:checked~label {
          // background: #f4f4f4;
          border: 2px solid #000000 !important;
          // opacity: 1;
          text-align: center !important;
        }
      }
    }
  }
}


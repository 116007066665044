// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

.startGroupOrderBtnMain {


    .group_bill_parent {

        .your_order {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          // padding: 5px 20px;
      
          .groupUserBtn{
            background-color: $th-primary !important;
            color: #fff !important;
        border-radius: 8px !important;
        padding: 6px !important ;
          }
        }
      
        .view_menu {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          padding: 15px 20px;
      
          a {
            text-decoration: none;
            color: #000;
            text-transform: capitalize !important;
      
            &:hover {
              text-decoration: none;
            }
          }
        }
      
      
        .viewMenu-content {
          display: flex;
          background: #FAFAFA !important;
          align-items: center;
          justify-content: space-between;
          border-radius: 10px !important;
      
        }
      
        h2 {
          font-size: 1rem;
          color: #000 !important;
        }
      
        h4 {
          font-size: 1rem;
          font-weight: 300;
          color: #000;
        }
      
        h3 {
          font-size: 1.3rem;
          font-weight: 300;
        }
      
        .icons {
          display: flex;
          align-items: center;
      
          img {
            margin: 0px 10px;
          }
          svg{
            color: #22282F !important;
            font-size: 1.2rem !important;
          }
        }
      
        .MuiCheckbox-colorSecondary.Mui-checked {
          color: $th-primary;
        }
      }
}



.web-share-fade {
  z-index: 99999 !important;
}
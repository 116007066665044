// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

#address-form {
 
.active-btn{
  background: $th-primary !important;
  border: none;
  color: white;
  border-radius: 10px !important;
  width: 25% !important;
  margin-top: 10px !important;

  i {
      font-size:0.75em	 !important;
      color: white !important;
  }
}

.non-active-btn{
    background: #fff !important;
    border: none;
    color: $th-primary;
    border: 1px solid $th-primary !important;
    border-radius: 10px !important;
    width: 25% !important;
    margin-top: 10px !important;
  
    i {
        font-size: 0.75em	!important;
        color: $th-primary !important;
    }
}
.address-content{
  font-size: 0.8125em	!important;
}
 .arabic-class{
    .MuiInputLabel-root {
        left: auto !important;
        right: 4px;
        padding-right: 14px;
      }
  
    //   .Mui-focused {
    //     right: 11px;
    //     padding-right: 14px;
    //   }
    
  
      .MuiOutlinedInput-notchedOutline {
        text-align: right !important;
        right: 11 !important;
      }
 }
 .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $th-primary !important; /* Focused border color */
  }

  .textArea {
    width: 100%;
    font-size: 0.75em	!important;
    font-weight: normal; /* Change this to the desired font weight */
    padding: 10px;
    margin-top: 15px;
    // border: none;
    resize: none;
    border-radius: 7px;
    border-color: $th-primary !important;
    // background-color: rgba(0, 0, 0, 0.05) !important;
    &:focus {
    //   outline: none;
      @include lowMax {
        max-height: 180px;
        font-size: 1rem;
      }
    }

    
    .noteContent {
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h2 {
        //   font-weight: 300;
        }
        h4 {
        //   font-weight: 300;
          @include lowMax {
            font-size: 1rem;
          }
        }
        span {
          color: gray;
        }
      }
    }
  }

  .textArea::placeholder {
    font-weight: normal; /* Change this to the desired font weight */
  } 

  .btn-padding{
    padding: 10px 55px !important;
    font-size: 0.75em	 !important;
    // margin: 10px 1px !important;
  }

  .rider-note-field{
    @include below600{
      margin-bottom: 100px !important;
    }
  }
  }
// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

.deliveryAddsPopup {
  height: auto !important;
  // height: 52% !important;
  // @include below768 {
    // height: 390px !important;
  // }
  position: fixed !important;
  z-index: 1270 !important;
  bottom: 0;
  // left: 0;
  // right: 0;
  background-color: #f8f8f8 !important;
  overflow-x: hidden;
  transition: 0.5s;
  width: 100% !important;
  border-radius: 20px 20px 0px 0px;

  @include above1200 {
    width: 49.8% !important;
  }

  a {
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;

    &:hover {
      color: #f1f1f1;
    }
  }

  .viewMenuBtn {
    background: $th-primary !important;
    color: #fff !important;
    text-align: center !important;
    text-transform: capitalize !important;
   
  }
  .disable-confirm-btn{
   opacity: 0.5 !important;
   pointer-events: none !important
  }

  // .confirmBtnClass{
  //   position: fixed !important;
  //   bottom: 0 !important;
  // }
  .payBillBtn {
    color: #d35255 !important;
    background: #fff !important;
    border: 2px solid #eeeeee !important;
    text-transform: capitalize !important;
  }

  .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    display: none !important;
  }

  .content {
    .disable-confirm-btn{
      opacity: 0.5 !important;
      pointer-events: none !important
     }
    h1 {
      font-size: 24px !important;
      color: #000;

      @include below1200 {
        font-size: 18px !important;
      }

      @include below425 {
        font-size: 14px !important;
      }
    }

    .MuiButton-root {
      border-radius: $borderRadius !important;
      width: 100% !important;
      font-size: $paragraphSize;
      color: #000000;
      font-family: Manrope;
      font-weight: 300;
      background-color: #f4f4f4;
      // cursor: pointer !important;
      padding: 12px !important;
    }

    .MuiSvgIcon-root {
      font-size: 1.1rem !important;
    }
  }

  #storeInfoMainPayTable {
    border-bottom: 1px solid $borderColor;
    border-radius: 0px 0px 10px 10px;
    background-color: #fff;

    h3 {
      font-size: $paragraphSize;
      color: #666666;

      @include x-small-max {
        font-size: 0.6rem;
      }
    }

    h4 {
      color: #000000 !important;
      // @include x-small-max {
      //     font-size: 0.6rem;
      // }
    }

    p {
      text-transform: capitalize;
      font-size: $paragraphSize;
      color: #000000;
      font-family: Manrope;
      font-weight: 800;

      @include x-small-max {
        font-size: 0.6rem;
        line-height: 22px;
      }
    }

    .img-pro {
      max-width: 65%;
      height: auto;
    }
  }

  #storeTitlePayTable {
    background-color: #ffff !important;
    .tableNo {
      color: #666666 !important;
    }
    .MuiAvatar-root {
      height: 50px !important;
      width: 70px !important;
    }

    h1 {
      font-size: $headingSize;
      color: black;

      text-transform: capitalize !important;

      @include lowMax {
        font-size: 15px;
      }
    }

    p {
      font-size: 15px;
      color: #4f4f4f;
      font-weight: 300;
      font-family: Manrope;

      @include lowMax {
        font-size: 8px;
      }
    }

    .imgDiv {
      width: 60px;
    }

    .MuiButton-root {
      border-radius: $borderRadius !important;
      font-size: $paragraphSize;
      color: #000000;
    }

    .infoBtn {
      padding: 5px 15px;
      background: #eeeeee;
      border-radius: 12px;
      border: none;
    }
  }

  .paySecure {
    .img-pro {
      max-width: 65%;
      height: 21px !important;
    }

    p {
      color: #7c7a7a !important;
    }
  }

  .MuiAvatar-img {
    object-fit: contain !important;
  }

  .textArea {
    width: 100%;
    font-size: 1.2rem;
    // padding: 10px;
    margin-top: 15px;
    border: none;
    resize: none;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.05) !important;
    &:focus {
      outline: none;
      @include lowMax {
        max-height: 180px;
        font-size: 1rem;
      }
    }
    .noteContent {
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h2 {
          font-weight: 300;
        }
        h4 {
          @include lowMax {
            font-size: 1rem;
          }
        }
        span {
          color: gray;
        }
      }
    }
  }
 
 
}

// .deliveryAddsPopupActive{
//   display: inline-block !important;
//   transition: 0.3s 
// }
// .deliveryAddsPopupClose{
//   display: none !important;
//   // transition: all 0.5s ease-in-out;

// }
// .ArabicdeliveryAddsPopup {
//   // margin-left: auto !important;
//   // right: 0;
//   // left: auto;
//   // width: 100% !important;

//   // @include middleMin {
//   //   width: 49.8% !important;
//   // }
// }
.DeliverySvg{
  .MuiSvgIcon-root{
    font-size:  2rem !important;
      }
      
}
.selectLocations{
  background-color: #ffff !important;
  direction: rtl !important;
}
// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

.payemntMethodsBorder{
  border-radius: 20px 20px 0px 0px;
  z-index: 99999;

}
.paymentMethodsl1 {
    height: auto;
    position: fixed;
    bottom: 0;
    background-color: #FFF;
    overflow-x: hidden;
    transition: 0.5s;
    width: 100% !important;
    .circleDiv{
      height: 36px !important;
      width: 36px !important;
      border-radius: 50% ;
      border: 1px solid #E5E5E5;
      // opacity: 100% !important;

    }

    @include above1200 {
        width: 49.8% !important;
    }

    h3{
        font-size: 1.3rem !important;
        font-weight: 600 !important;
    }

    .paymentMethod {
        img {
          height: 30px !important;
        }
        .TabUnstyled-root {
          padding: 12px 0px !important;
        }
      }
      .tabNavigation {
        .base--selected{
          background-color: #f4f4f4 !important;
          border-radius: $borderRadius !important;
          border: 1px solid #FF595A !important;
          color: #000000;
          font-size: 9px !important;
          font-family: Poppins !important;
          z-index: 1 !important;
          font-weight: 800;
          @include below375 {
            font-size: 7px !important;
          }
          @include x-small-max {
            font-size: 6px !important;
          }
        }
        .Mui-selected {
          background-color: #f4f4f4 !important;
          border-radius: $borderRadius !important;
          border: 1px solid #FF595A !important;
          color: #000000;
          font-size: 9px !important;
          font-family: Poppins !important;
          z-index: 1 !important;
          font-weight: 800;
          @include below375 {
            font-size: 7px !important;
          }
          @include x-small-max {
            font-size: 6px !important;
          }
        }

        .base--selected {
          background-color: #f4f4f4 !important;
          border-radius: $borderRadius !important;
          border: 1px solid #FF595A !important;
          color: #000000;
          font-size: 9px !important;
          font-family: Poppins !important;
          z-index: 1 !important;
          font-weight: 800;
          @include below375 {
            font-size: 7px !important;
          }
          @include x-small-max {
            font-size: 6px !important;
          }
        }


        .customTabs{
          background-color: #fff ;
          border-radius: $borderRadius !important;
          font-size: 16px !important;
          border: 1px solid #E5E5E5 ;
          font-family: Poppins !important;
          font-weight: 800;
          color: #000000;
          padding: 12px 0px !important;
          width: 100% !important;
          margin: 6px !important;



          @include below1200 {
            font-size: 13px !important;
          }
    
          @include below375 {
            font-size: 10px !important;
          }
          @include x-small-max {
            font-size: 8px !important;
          }

        }
        Button {
          background-color: #fff ;
          border-radius: $borderRadius !important;
          font-size: 16px !important;
          border: 1px solid #E5E5E5 ;
          font-family: Poppins !important;
          font-weight: 800;
          color: #000000;
          width: 100% !important;
          margin: 6px !important;
          @include below1200 {
            font-size: 13px !important;
          }
    
          @include below375 {
            font-size: 10px !important;
          }
          @include x-small-max {
            font-size: 8px !important;
          }
        }
        .tabListCustomized{
          background-color: #ffff !important;
          min-width: 320px;
          border-radius: 8px;
          margin-bottom: 16px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          place-content: space-between center;
        }
        .MuiTabsList-root {
          background-color: #ffff !important;
          min-width: 320px;
          border-radius: 8px;
          margin-bottom: 16px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          place-content: space-between center;
        }
      }

      .divider{
      
        span {
          display: flex;
          flex-direction: row;
          text-transform: uppercase !important;
          color: #000000 !important;
          font-size: small !important;
       
        }
        span:before, span:after{
          content: "";
          flex: 1 1;
          border-bottom: 1px solid;
          margin: auto;
          opacity: 0.1 !important;
        }
        span:before {
          margin-right: 0px
        }
        span:after {
          margin-left: 0px
        }
      }

      .save_card_checkbox{
        svg{
          color: $th-primary;
        }
      }
    
}

// .paymentMethodsl1Arabic {
//     margin-left: auto !important;
//     right: 0 !important;
//     left: auto !important;
//     width: 100% !important;

  
// }

// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";

#store-options{
  .MuiIconButton-root{
    padding: 5px !important;
  }
  .MuiSvgIcon-root {
    font-size: 1.3rem !important;
  }
  .DeliveryTitle{
    font-size: 1rem;
    @include below600{
      font-size: 0.8rem !important;
    }
  }
  .DeliveryTitle2{
    font-size: 1rem;
    @include below600{
      font-size: 0.8rem !important;
    }
  }
}
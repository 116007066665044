// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

#reOrderMainV2 {
  position: relative !important;
  z-index: 0 !important;

  #tabs_Sec {
    position: absolute !important;
    width: 100% !important;
    bottom: 0% !important;
  
    // &::before {
    //     content: "";
    //     position: absolute;
    //     // height: 100%;
    //     height: 10%;
    //     width: 100%;
    //     top: 0%;
    //     // background:   !important;
    //     box-shadow: 20px 20px 0px 30px #000;
    //     opacity: .3;
    //     background: #000 !important;}
    .MuiTab-wrapper {
      text-transform: capitalize !important;
    }
  
    .MuiTab-root {
      font-weight: 900 !important;
    }
  }
.largeAvatar{
  .MuiAvatar-root {
    height: 70px !important;
    width: 70px !important;
    @include below425 {
      height: 70px !important;
      width: 70px !important;
    }
  
    img {
      border-radius: 10px;
    }
  }
}
  
  
  .reOrderheading {
    font-size: 20px;
    color: #000000;

    @include below1200 {
      font-size: 14px !important;
    }
    @include below768 {
      font-size: 14px !important;
    }
    @include below375 {
      font-size: 12px !important;
    }
  }

  .imgDiv {
    border-radius: $borderRadius;
    width: 80px;
  }
  .h-80px{
    height: 80px !important;
  }
 
  .re-order-layout {
    .MuiTabScrollButton-root {
      &:nth-child(1) {
        left: 25px !important;
        z-index: 999;
      }

      &:nth-child(4) {
        right: 25px !important;
        z-index: 999;
      }
    }

    .MuiSvgIcon-root {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: #fff !important;
      color: #000000 !important;
      box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.09);
      padding: 10px !important;
    }

    .Mui-disabled {
      opacity: 2 !important;
    }

    .MuiTab-textColorPrimary {
      font-family: Poppins !important;
      font-weight: 700 !important;
      color: #6b6b6b !important;
      max-width: 100px !important;
      font-size: 12px !important;
      border-radius: $borderRadius;
      margin: 0px 5px !important;

      img {
        height: 100px !important;
        width: 100px !important;
        margin-top: 2px !important;
        object-fit: cover !important;
        border-radius: 8px !important;
      }
    }

    .Mui-selected {
      font-family: Poppins !important;
      font-weight: 700 !important;
      color: #000000 !important;
      border-radius: $borderRadius  !important;
      border: 2px solid #000000 !important;
    }

    .MuiTabs-indicator {
      display: none !important;
    }
  }

  .tabLabel {
    text-align: center;
    max-width: 100px; /* Adjust based on design */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .productName {
    font-size: 1em;
    font-weight: bold;
    max-width: 100%; /* Limits width to contain text */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .productPrice {
    font-size: 0.9em;
    color: #888;
    display: inline-block;
  }
}


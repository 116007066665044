// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

.SelectloyalityPopupMain {
  height: auto !important;
  position: fixed !important;
  z-index: 9999;
  bottom: 0;
  // left: 0;
  // right: 0;
  background-color: #F8F8F8 !important;
  overflow-x: hidden;
  transition: 0.5s;
  width: 100% !important;
  border-radius: 20px 20px 0px 0px;
// @include middleMin {
//     width: 49.8% !important;
// }
@include above1200 {
    width: 49.8% !important;
}

  // .arrowButtons{
  //   // position: absolute !important;
  //   // left: 0 !important;
  //   // right: 0 !important;
  //   // top: 1;
  //   .MuiButtonBase-root{
  //     color: $th-primary !important
  //   }
  //   .Mui-disabled{
  //     color: gray;
  //   }
  // }

  #TagCheckBoxParent {
    margin-top: 0px !important;

    .TagselectPaymentRow {
      .MuiButton-outlined {
        border: none !important;
      }
    }
  }

  .content {
    h1 {
      font-size: 18px !important;
      color: #000;
    }

    .MuiButton-root {
      border-radius: $borderRadius  !important;
      width: 100% !important;
      pointer-events: initial !important;
      font-size: $paragraphSize;
      font-family: "Poppins", sans-serif;
      font-weight: 600 !important;
      background-color: #fff !important;
      cursor: pointer !important;
    }
  }

  .bottomBtn {
    .confirmBtn {
      background-color: $th-primary  !important;
      color: #fff !important;
      // font-size: inherit !important;
    }
  }

  .progress_card {
    border-radius: 8px !important;
    background: #f4f4f4 !important;

    .MuiLinearProgress-bar {
      background-color: #ff595a !important;
      height: 10px !important;
      border-radius: 5px !important;
    }

    .liner {
      color: #5d5e65 !important;
    }

    .MuiLinearProgress-root {
      background-color: #eeeeee !important;
      height: 10px !important;
      border-radius: 5px !important;
    }

    .MuiAvatar-root {
      height: 96px !important;
      width: 96px !important;
    }
  }

  .productsRow {
    height: 430px !important;
    overflow: auto !important;

    .CardRow {
      .cardContent {
        padding: 30px 0px !important;
        background: radial-gradient(16px at 0 0, #0000 98%, #f9f9f9) 0 0 /51% 51% no-repeat,
          radial-gradient(0px at 100% 0, #0000 98%, #f9f9f9) 100% 0 /51% 51% no-repeat,
          radial-gradient(16px at 0 100%, #0000 98%, #f9f9f9) 0 100%/51% 51% no-repeat,
          radial-gradient(0px at 100% 100%, #0000 98%, #f9f9f9) 100% 100%/51% 51% no-repeat;
        border-radius: 0px 16px 16px 0px !important;
        // box-shadow: 0 8px 6px -6px black;
        // box-shadow:  0 0 10px #f8a100 !important;
        filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.1));

        // box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, ;
        // background-color: rgb(255, 255, 255);
        .cardContentInner {
          border-left: 2px dashed #eeeeee !important;
        }

        .punchRatingMain {
          .Mui-disabled {
            opacity: 100 !important;
          }

          .MuiRating-decimal {
            .MuiRating-iconFilled {
              color: $th-primary  !important;
            }

            .MuiRating-iconEmpty {
              color: $th-primary  !important;
            }
          }
        }
      }

  

      .cardImage {
        .cardImageInner {
          width: 70% !important;
          height: 100px !important;
          .largeAvatar{
            .MuiAvatar-root{
              width: 100% !important;
              height: 100% !important;
              border-radius: 10px !important;
            }
          }
        }
        padding: 0px 0px !important;
        background: radial-gradient(0px at 0 0, #0000 98%, #f9f9f9) 0 0 /51% 51% no-repeat,
          radial-gradient(16px at 100% 0, #0000 98%, #f9f9f9) 100% 0 /51% 51% no-repeat,
          radial-gradient(0px at 0 100%, #0000 98%, #f9f9f9) 0 100%/51% 51% no-repeat,
          radial-gradient(16px at 100% 100%, #0000 98%, #f9f9f9) 100% 100%/51% 51% no-repeat;
        border-radius: 16px 0px 0px 16px !important;
        filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.1));

        // box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        // box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
        // background-color: rgb(255, 255, 255);
      }


      .cardProductName {
        color: #000000 !important;
        font-size: 16px !important;

        @include below375 {
          font-size: 10px !important;
        }
      }

      .cardPrice {
        color: #5d5e65 !important;
        font-size: 16px !important;
        font-weight: 300;

        // opacity: 0.6 !important;
        @include below375 {
          font-size: 11px !important;
        }
      }

      .cardDates {
        color: #666666 !important;
        font-size: 12px !important;

        @include below375 {
          font-size: 10px !important;
        }
      }

      .item {
        padding: 0px 15px !important;
      }

      .orderAgain {
        .MuiButton-root {
          color: rgb(32, 32, 32) !important;
          // border-radius: 15px !important;
          background-color: transparent !important;
          font-weight: bolder !important;
        }

        .MuiButton-label {
          @include below768 {
            font-size: 10px !important;
          }
        }
      }

      .skipBtn {
        color: #000000 !important;
        text-decoration: underline !important;
      }

      .smallAvatar {
        .MuiAvatarGroup-root {
          justify-content: flex-end !important;
        }

        .MuiAvatar-root {
          @include below1200 {
            height: 30px !important;
            width: 30px !important;
          }
        }

        .MuiAvatar-root {
          border-radius: $borderRadius  !important;
        }
      }
    }

    .radio-item {
      margin: 5px;
      padding: 10px;
      cursor: pointer;

      &.selected {
        border: 2px solid $th-primary  !important;
        border-radius: 10px !important;
      }
    }

    .cardNotActive {
      opacity: 0.8 !important;
      pointer-events: none !important;
    }

    .cardActive {
      cursor: pointer !important;
    }

    .disabled {
      opacity: 0.7 !important;
    }
  }

  .punchRatingMain {
    .Mui-disabled {
      opacity: 100 !important;
    }

    .MuiRating-decimal {
      .MuiRating-iconFilled {
        color: $th-primary  !important;
      }

      .MuiRating-iconEmpty {
        color: $th-primary  !important;
      }
    }
  }

  .tabsParent {
    width: 100% !important;

    .MuiBox-root {
      padding: 0 !important;
    }
  }
}

.SelectloyalityPopupMainMainArabic {
  margin-left: auto !important;
  right: 0 !important;
  left: auto !important;
  width: 100% !important;

  @include above1200 {
    width: 49.8% !important;
  }
}
// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

.CollapsFields {
  .MuiFormControl-root {
    width: 100% !important;
  }
  .MuiButtonBase-root {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding: 2px !important;
  }
  .CityName{
    .MuiListItemText-root{
      color: #000 !important;
    }
  }

  .cityNameSelected {
    .MuiTypography-root{
      color: #ec5a5e !important;
      font-weight: 900 !important;
    }
  }

 
}

// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

.delivery-google-map {
  height: auto !important;
  // height: 78% !important;
  // @include below425{
  //   height: 80%;
  // }
  position: fixed !important;
  z-index: 1270 !important;
  bottom: 0;
  // left: 0;
  // right: 0;
  background-color: #f8f8f8 !important;
  overflow-x: hidden;
  transition: 0.5s;
  width: 100% !important;
  border-radius: 20px 20px 0px 0px;

  @include above1200 {
    width: 49.8% !important;
  }

  a {
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;

    &:hover {
      color: #f1f1f1;
    }
  }

  .viewMenuBtn {
    background: #ec5a5e !important;
    color: #fff !important;
    position: relative;
    text-transform: capitalize !important;
    .iconsParent {
      // position: absolute;
      // left: 10px !important;
      .iconsBg {
        background: #fff !important;
        height: 32px; //24
        width: 32px;
        border-radius: 50%;
        margin: 0px 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px;
      }
    }
  }

  // .confirmBtnClass{
  //   position: fixed !important;
  //   bottom: 0 !important;
  // }
  .payBillBtn {
    color: #d35255 !important;
    background: #fff !important;
    border: 2px solid #eeeeee !important;
    text-transform: capitalize !important;
  }

  .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    display: none !important;
  }

  .content {
    h1 {
      font-size: 24px !important;
      color: #000;

      @include below1200 {
        font-size: 18px !important;
      }

      @include below425 {
        font-size: 14px !important;
      }
    }

    .MuiButton-root {
      border-radius: $borderRadius !important;
      width: 100% !important;
      pointer-events: initial !important;
      font-size: $paragraphSize;
      color: #000000;
      font-family: Manrope;
      font-weight: 300;
      background-color: #f4f4f4;
      cursor: pointer !important;
    }

    .MuiSvgIcon-root {
      font-size: 1.1rem !important;
    }
  }
.deliveryAddsPopupGoogleMap{
  height: auto !important;
}
 
 
}


// .ArabicdeliveryAddsPopup {
//   // margin-left: auto !important;
//   // right: 0;
//   // left: auto;
//   // width: 100% !important;

//   // @include middleMin {
//   //   width: 49.8% !important;
//   // }
// }
.DeliverySvg{
  .MuiSvgIcon-root{
    font-size:  2rem !important;
      }
      
}
.selectLocations{
  background-color: #ffff !important;
  direction: rtl !important;
}
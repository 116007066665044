// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";



.PayTablePopUpArabic {
    margin-left: auto !important;
    right: 0 ;
    left: auto ;
    width: 100% !important;

    @include middleMin {
        width: 49.8% !important;
      
    }
}

.iconsParent{
    .iconsBg{
        background: #fff !important;
        height: 32px ;
        width: 32px;
        border-radius: 50%;
        margin: 0px 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px ;
    }
}
.payBtn{
    border-radius: 10px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    padding: 1rem 0.5rem !important;
    background-color: $th-primary !important;
    color: #fff !important;
}
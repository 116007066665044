// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

#google-location {
 
.active-btn{
  background: $th-primary !important;
  border: none;
  color: white;
  border-radius: 10px !important;
  width: 25% !important;
  margin-top: 10px !important;

  i {
      font-size: 1rem !important;
      color: white !important;
  }
}

.non-active-btn{
    background: #fff !important;
    border: none;
    color: $th-primary;
    border: 1px solid $th-primary !important;
    border-radius: 10px !important;
    width: 25% !important;
    margin-top: 10px !important;
  
    i {
        font-size: 1rem !important;
        color: $th-primary !important;
    }
}

 .arabic-class{
    .MuiInputLabel-root {
        left: auto !important;
        right: 4px;
        padding-right: 14px;
      }
  
    //   .Mui-focused {
    //     right: 11px;
    //     padding-right: 14px;
    //   }
  
      .MuiOutlinedInput-notchedOutline {
        text-align: right !important;
        right: 11 !important;
      }
 }
  }
// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";



#wrapperLoyaltySlider {
  position: relative !important;
  z-index: 0 !important;


.readyToCalime{
  color: #fff !important;
font-weight: 700 !important
}

  .loyaltyTabsParent {
    direction: ltr !important;

    .MuiTabs-indicator {
      display: none;
      background-color: #EB5B5E !important;
      height: 3px !important;
    }

    .categoryTab {
      padding: 0 !important;
      margin: 30px 6px 0px 6px !important;
      width: 100% !important;
      color: #fff;
      border-radius: 8px !important;
      text-align: center;
      position: relative;
      height: 160px;
      // border: 1px solid #EEE !important;
      display: flex;
      flex-direction: column;
      justify-content: start !important;

      .CardRow {
        border-radius: $borderRadius !important;
        padding: 0 15px !important;
        .cardContent {
          // padding: 30px 0px !important;
          .punchRatingMain {
            .Mui-disabled {
              opacity: 100 !important;
              font-size: 1.3rem !important ;
              gap: 3px !important;
            }

            .MuiRating-decimal {

              .MuiRating-iconFilled {
                color: #fff  !important;
              }

              .MuiRating-iconEmpty {
                color: #fff   !important;

              }
            }
          }
        }
      }
    }

    .Mui-disabled {
      opacity: 1 !important;
    }

    .MuiTabScrollButton-root {
      display: none !important;
      svg {
        font-size: 30px !important;
        font-family: Poppins !important;
        color: #74757B !important;
      }

    }


    .largeAvatar {
      .MuiAvatar-root {
        height: 70px !important;
        width: 70px !important;
  
        @include below425 {
          height: 70px !important;
          width: 70px !important;
        }
  
        img {
          border-radius: 10px;
        }
      }
    }
  
    .cardProductName {
      color: #FFF !important;
      font-size: 16px !important;
  
      @include below375 {
        font-size: 10px !important;
      }
    }
    .cardPrice {
      color: rgba(255, 255, 255, 0.60) !important;
      font-size: 16px !important;
      font-weight: 300;
  
      // opacity: 0.6 !important;
      @include below375 {
        font-size: 11px !important;
      }
    }
  
    .cardDates {
      color: #666666 !important;
      font-size: 12px !important;
  
      @include below375 {
        font-size: 10px !important;
      }
    }
  
    .featuredAppBar {
      .MuiButtonBase-root {
        border: 1px solid
      }
    }
   
  }

  .loyaltyTabsParentEn {
    .MuiTabScrollButton-root {
      position: absolute !important;
      top: 14px !important;
      right: 0 !important;
      display: none !important;
    }

    [data-testid="KeyboardArrowLeftIcon"] {
      position: absolute;
      top: 1px;
      right: 66px;
    }

    [data-testid="KeyboardArrowRightIcon"] {
      position: absolute;
      top: 1px;
      left: 0px;
      // transform: scaleX(1);
    }
  }

  .loyaltyTabsParentArabic {
    .MuiTabScrollButton-root {
      position: absolute !important;
      top: 24px !important;
      left: 0 !important;
      display: none !important;
    }

    [data-testid="KeyboardArrowLeftIcon"] {
      position: absolute;
      top: -8px;
      left: 22px;
      // transform: scaleX(-1);
    }

    [data-testid="KeyboardArrowRightIcon"] {
      position: absolute;
      top: -8px;
      left: 70px;
      // transform: scaleX(-1);
    }

  }

}
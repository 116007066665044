// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

.groupOrderPopupMain {
  height: auto !important;
  position: sticky !important;
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff !important;
  overflow-x: hidden;
  transition: 0.5s;
  width: 100% !important;
  border-radius: 20px 20px 0px 0px;
  // @include above992 {
  //     width: 49.8% !important;
  // }

  .content {
    h1 {
      font-size: 18px !important;
      color: #000;
     
    }
    // .groupOrderHead{
    //   position: absolute ;
    //   left: 50%;
    //   margin: 0px 0 0 -50px;
    // }

    .displayLine {
      color: #000 !important;
      opacity: 0.44 !important;
    }

    .MuiSvgIcon-root {
      font-size: 1.1rem !important;
    }

    .shareBtnParent {
      .shareBtn {
        font-size: $paragraphSize;
        background: rgba(235, 91, 94, 0.1);
        border-radius: 12px;
        color: #EB5B5E !important;
      }
    }

    .bottomBtn {
      // position: fixed !important;
      // left: 0 !important;
      // bottom: 0 !important;
      // right: 0 !important;
      z-index: 0 !important;
      // padding: 10px 20px !important;
      background-color: #fff !important;

      .confirmBtn {
        background-color: $th-primary  !important;
        color: #fff !important;
      }

      .MuiButton-root {
        border-radius: $borderRadius  !important;
        width: 100% !important;
        pointer-events: initial !important;
        font-size: $paragraphSize;
        font-family: "Poppins", sans-serif;
        font-weight: 600 !important;
        cursor: pointer !important;
      }

      .closeBtn {
        background-color: transparent !important;
        color: $th-primary  !important;
      }
    }
  }


}
.groupOrderPopupMainArabic{
  margin-left: auto !important;
  right: 0 !important;
  left: auto !important;
  width: 100% !important;
  // @include above992 {
  //     width: 49.8% !important;
  // }
}



.web-share-fade {
  z-index: 99999 !important;
}
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import "bootstrap";
@import "../../layout-one/sass/variables";
@import "../../layout-one/sass/mixins";
@import "../../layout-one/sass/global";

.payCustomAmount {
    height: auto !important;
    position: fixed !important;
    z-index: 9999;
    bottom: 0;
//  left: 0;
//  right: 0;
    background-color: #F8F8F8 !important;
    overflow-x: hidden;
    transition: 0.5s;
    width: 100% !important;
    border-radius: 20px 20px 0px 0px;

    @include above1200 {
        width: 49.8% !important;
    }

    a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 25px;
        color: #818181;
        display: block;
        transition: 0.3s;

        &:hover {
            color: #f1f1f1;
        }
    }

    .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
        display: none !important;
    }

    .content {
        h1 {
            font-size: 18px !important;
            color: #000;

        }

        .MuiButton-root {
            border-radius: $borderRadius  !important;
            width: 100% !important;
            pointer-events: initial !important;
            font-size: $paragraphSize;
            color: #000 !important;
            font-family: 'Poppins', sans-serif;
            font-weight: 600 !important;
            background-color: #FFF !important;
            cursor: pointer !important;

         
        }

        .MuiSvgIcon-root {
            font-size: 1.1rem !important;
        }
    }

    .totalAmount {
        font-size: 18px !important;
        color: #000000;

    }

    .bottomBtn {
        .confirmBtn {
            background-color: $th-primary  !important;
            color: #FFF !important;
            // font-size: inherit !important;
        }

        .deletebtn {
            background-color: transparent !important;
            color: $th-primary  !important;
            // font-size: inherit !important;
        }
        .disabledBtn{
            background-color: #cccccc !important;
            color: #FFF !important;
        }

    }

    .inputFieldParent {
        background-color: #F3F3F3 !important;
        border-radius: $borderRadius  !important;

        div {
            direction: ltr !important;
            font-size: 2rem !important;
            font-family: Poppins !important;
            font-weight: 800 !important;
        }

        .MuiOutlinedInput-input {
            text-align: center !important;

        }
        fieldset{
            border: none !important;
        }
    }



}

// .payCustomAmountArabic {
//     margin-left: auto !important;
//     right: 0 !important;
//     left: auto !important;
//     width: 100% !important;

//     // @include above1200 {
//     //     width: 49.8% !important;
//     // }
// }
// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../../sass/variables";
// Mixins
@import "../../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../../sass/global";


#ProductsFeatured2{
  align-items: center;
    justify-content: space-between;
    min-width: 100%;
  img {
    height: 90px !important;
    width: 90px !important;
    border-radius: 5px !important;
  }
 .priceAmoutn{
  font-weight: 900 !important;
 }
 .catName{
  font-weight: 700 !important;
 }
 button{
  svg{
    font-size: 2.7rem !important;
    border: 1px solid #EEE;
  }
 }
}


#AskConfirm{
  .MuiPaper-root{
    border-radius: 15px !important;
  }
  .addBtn {
    background: $th-primary !important;
    border: none;
    color: white;
    border-radius: 10px !important;
    padding: 10px 55px !important;

    i {
        font-size: 1rem !important;
        color: white !important;
    }
}
.cancleBtn{
  background: transparent !important;
  border: 1px solid $th-primary !important;
  color: $th-primary !important;
  border-radius: 10px !important;
  padding: 10px 40px !important;

  i {
      font-size: 1rem !important;
      color: #EB5B5E !important;
  }
}
}



// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";
#category-product-v2{
  border-bottom: 2px solid #ebebeb !important;
  
  .MuiTab-root {
    min-width: 120px;
    @include middleMin {
      min-width: 140px;
    }
  }
  .MuiAppBar-root {
    box-shadow: none !important;
    border: 1px solid #eeee !important;
  }
  .MuiTab-wrapper {
    text-transform: capitalize !important;
  }
    .MuiTabs-textColorPrimary {
    font-family: Poppins !important;
    font-weight: 700 !important;
    color: #6b6b6b !important;
  }

  .Mui-selected {
    color: $th-primary  !important;
    font-family: Poppins !important;
    font-weight: 700 !important;
  }

  .MuiTab-textColorPrimary {
    .Mui-selected {
      color: $th-primary  !important;
      font-family: Poppins !important;
      font-weight: 700 !important;
    }
  }
  }
// Layout Two Scss
    // Variables
    @import "variables";
    // Bootstrap
    @import "~bootstrap/scss/bootstrap";
    // Config
    @import "config";
    // Mixins
    @import "mixins";
    // Global
    @import "global";
    html {
        scroll-behavior: smooth;
    }
    
    body {
        font-family: "Poppins", sans-serif;
        background-color: #fff;
        
        // background: #f4f5f5;
    }
    
    // .a{
    //     border:2px solid red
    // }
    // .b{
    //     border:2px solid blue
    // }
    // .c{
    //     border:2px solid black
    // }
    .wpc_top_navbar {
        .navbar {
            &.bg-light {
                background-color: #fff !important;
                box-shadow: 0px 0px 8px 0px #c1c1c1;
            }
        }
        .navbar-brand {
            padding: 5px 0;
            max-width: 140px;
            height: 70px;
            img {
                max-width: 100%;
                height: auto;
                max-height: 100%;
            }
        }
        .btnNavItem {
            min-width: 100px;
            border: 1px solid $th-primary;
            margin: 0 5px;
            button {
                width: 100%;
            }
        }
        p {
            margin: 0;
        }
        .popper {
            z-index: 99;
        }
        .nav_menu_toggler {
            // color: #fff;
            display: block;
        }
        .nav-mob {
            position: absolute;
            right: 10px;
            top: 15px;
            z-index: 1;
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 0 15px;
            left: 0;
        }
        .nav-item {
            &.nav-item-outline {
                border: 1px solid $th-primary;
                border-radius: 5px;
                margin: 0 5px;
                padding: 3px;
            }
            .MuiButton-containedPrimary {
                background-color: $th-primary;
            }
            .MuiBadge-badge {
                font-size: 0.5rem !important;
                height: 15px !important;
                width: 15px !important;
            }
            .nav-link {
                // color: $th-primary;
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    width: 2px;
                }
                &.other-links {
                    color: #666666;
                    &::after {
                        top: 0;
                        height: 100%;
                        background-color: #eee;
                        right: 0;
                    }
                    &:nth-last-child(3) {
                        &::after {
                            position: relative;
                        }
                    }
                }
                &.text-link {
                    font-size: 14px;
                    &::after {
                        right: 0;
                        top: 28%;
                        height: 50%;
                        background: $th-primary;
                    }
                }
            }
            &.active {
                .nav-link {
                    color: $th-secondary;
                }
            }
            .MuiSvgIcon-root {
                width: 0.7em;
                height: 0.83em;
            }
            .fas.fa-wallet {
                font-size: 20px;
            }
            Infomration {
                line-height: 1;
                padding: 0 10px;
                p {
                    font-size: 12px;
                    font-weight: bold;
                    a.small {
                        font-size: 12px;
                        font-weight: 300;
                        color: rgba(0, 0, 0, 0.5);
                    }
                }
            }
            .fa-shopping-cart {
                font-size: 15px;
            }
            .userIcon {
                width: 0.8em;
            }
            .MuiList-root {
                li {
                    font-size: 13px;
                }
            }
        }
        .navbar-nav {
            align-items: center;
        }
    }
    
    // Sidebar
    .MuiDrawer-paper {
        width: 300px;
    }
    
    .PrivateSwipeArea-root-13 {
        width: 50px;
    }
    
    .sidebar-button {
        border-radius: 5px;
        border: 0;
        width: 100%;
        padding: 3px 0;
        font-size: 14px;
    }
    
    .sidebar-logout-button {
        background: $th-primary;
        color: #fff;
    }
    
    .sidebar-wallet-amount {
        background: #fff;
        color: lighten(#000, 10%);
    }
    
    .side-menu-item {
        padding: 0 !important;
    }
    
    .side-menu-item a,
    .side-menu-item button {
        padding: 10px 20px 10px 5px !important;
        width: 100%;
        font-size: 16px;
        line-height: inherit;
        border-radius: none;
        font-weight: 400 !important;
    }
    
    .mob-menu-items {
        padding: 30px;
    }
    
    // Category Tabs
    .wpc_categoryTabs,
    .CheckoutTabs {
        .MuiLink-root {
            border-bottom: 1px solid #9c9c9c;
        }
        .MuiAppBar-colorPrimary {
            box-shadow: none !important;
            background-color: transparent;
            color: #000;
            // font-weight: bold;
        }
        .MuiTabs-indicator {
            background-color: $th-primary;
            height: 4px !important;
        }
        .Mui-selected {
            color: $th-primary;
        }
        .MuiTab-root {
            min-width: 50px !important;
            font-size: 10.5px !important;
            @include Medium {
                min-width: 120px !important;
                font-size: 13px !important;
            }
        }
        .paymentDetails {
            display: flex;
            flex-direction: column;
             :nth-child(1) {
                color: black;
                font-size: 1.2rem;
                font-weight: 900;
                @include Medium {
                    font-size: 1rem;
                }
            }
             :nth-child(2) {
                color: #666666;
                font-size: .8rem;
                font-weight: 300;
                @include Medium {
                    font-size: .6rem;
                }
            }
        }
    }
    
    // side componend here store information and add to card information css
    .storeInfo {
        p{
            font-size: 0.8rem !important;
        }
        @include x-small-max{
            p{
                font-size: 0.6rem !important;
            }
        }
    }
    
    // Banner Section
    // .wpc_BannerSection {
    //     // padding: 20px 10px;
    //     // height: 25vh;
    // }
    .topSection {
        background: #fff;
    }
    
    .signoutButton {
        justify-content: left;
    }
    
    .wpc_moreDropdown {
        .MuiButtonGroup-root {
            width: 100%;
            button {
                background: $th-primary;
                width: 100%;
            }
        }
        @include Large {
            .MuiButtonGroup-root {
                width: auto;
                button {
                    width: auto;
                }
            }
        }
        .popper {
            z-index: 1;
        }
    }
    
    .bannerCoverSection {
        .bannerImg {
            height: 35vh;
            @include Large {
                height: 25vh;
            }
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        .delivery_time {
            background: #fff;
            padding: 10px 0;
            .delivery_icon {
                padding: 0;
                text-align: -webkit-center;
            }
            .delivery_content p {
                font-size: 13px;
            }
        }
    }
    
    .cart-section {
        background: #fff;
        height: 100%;
        hr {
            margin: 0;
        }
        @include Large {
            // position: -webkit-sticky;
            position: absolute;
            top: 0;
            // left: 0;
            z-index: 1;
            right: 0;
            width: 100%;
            .cart {
                background: #fff;
                padding: 0 15px;
            }
        }
    }
    
    .cart {
        .cart_checkout_button {
            padding: 10px;
            button,
            a {
                width: 100% !important;
                background: $th-primary !important;
                color: #fff;
                text-decoration: none;
                box-shadow: none;
                border-radius: 0;
                &.Mui-disabled {
                    opacity: 0.5;
                }
                &:hover {
                    background: $th-secondary !important;
                    color: #fff;
                }
            }
        }
    }
    
    // Cart Section
    #home {
        // .categoryBar {
        //     background: #fff;
        // }
        .categoryBar {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            // left: 0;
            z-index: 1;
        }
    }
    
    // .wpc_top_navbar .nav_menu_toggler
    // .wpc_bg-light {
    //     background: #fff;
    //     min-height: 40vh;
    // }
    // #home {
    //     .wpc_bg-light {
    //         background: #f4f5f5;
    //     }
    // }
    // Login Modal Tabs
    .MainDashboardTabs {
        .MuiAppBar-colorPrimary {
            background-color: transparent !important;
            box-shadow: none !important;
            color: #000 !important;
        }
        .MuiTabs-flexContainer {
            justify-content: center;
            .MuiButtonBase-root {
                color: #000;
                border-bottom: 1px solid rgb(224, 224, 224);
                // border-radius: 8px;
                font-size: 10px;
                margin: 0 2px;
                text-decoration: none;
                @include Large {
                    font-size: 13px;
                    margin: 0 5px;
                }
            }
        }
        .MuiTabs-indicator {
            background-color: $th-primary !important;
            // height: 100% !important;
            // border-radius: 8px !important;
        }
        .MuiTab-textColorInherit.Mui-selected {
            z-index: 099;
            color: $th-primary;
            border-color: $th-primary;
        }
        .MuiTab-root,
        .MuiTabs-root {
            min-height: 30px !important;
            margin: 0 !important;
            @include Large {
                min-height: 40px !important;
            }
            @include Medium {
                min-width: 140px !important;
            }
            white-space: inherit;
        }
    }
    
    .wpc_products {
        .wpc_category_name {
            padding-left: 10px;
            h1 {
                font-size: 35px;
            }
        }
        // Product Card
        .stock {
            color: red;
        }
        .wpc_prod_link {
            color: black !important;
            text-decoration: none !important;
        }
        a {
            text-decoration: none !important;
            color: rgba(0, 0, 0, 0.8) !important
        }
        .wpc_product_card {
            padding: 0 5px;
            background: #fff;
            border: 1px solid rgb(224, 221, 221);
            margin: 5px 0;
            &:hover {
                box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.08);
            }
            .wpc_product_inner {
                padding: 15px;
                // border-radius: 8px 8px 0 0;
                p {
                    margin: 0;
                }
                .wpc_product_title {
                    margin-top: 10px;
                    p {
                        font-weight: 300;
                    }
                }
                .wpc_product_image {
                    height: 100px;
                    img {
                        width: 100%;
                        height: 100%;
                        // border-radius: 8px 8px 0 0;
                    }
                }
                // .wpc_product_content {
                //     // padding: 0 5px;
                // }
                .wpc_product_price {
                    margin-top: 5px;
                    p {
                        .wpc_discounted_price {
                            color: red;
                            font-size: 12px !important;
                        }
                        font-size: 15px;
                        // font-weight: bold;
                    }
                }
                .wpc_product_buttons {
                    padding: 5px 0;
                    button {
                        margin: 0 5px;
                        text-transform: none;
                    }
                    .MuiButton-outlinedPrimary {
                        color: $th-primary;
                        border-color: $th-primary;
                    }
                }
            }
        }
    }
    
    // Single Product Details
    .wpc_single_product_details {
        padding: 1.5rem;
        .wpc_single_product_image {
            height: 55vh;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .wpc_buy_add_to_cart_button {
            button {
                min-width: 100%;
                // margin: 0 10px;
                background: $th-primary;
                &:hover {
                    background: $th-secondary;
                }
            }
        }
    }
    
    // Checkout
    // Footer
    .wpc_footer {
        padding: 0;
        .wpc_socialMediaIcons {
            a {
                display: block;
                padding: 0 10px;
                font-size: 16px;
            }
        }
        .wpc_footer_logo {
            img {
                min-width: 40px;
                max-width: 120px;
                max-height: 50px;
            }
        }
    }
    
    #checkout {
        .CheckoutTabs {
            .MuiTabs-flexContainer {
                justify-content: center;
            }
            .MuiPaper-root {
                box-shadow: none;
                background: inherit;
            }
        }
        .checkout_form {
            color: #000;
        }
        .AddPromoCode {
            .form-group {
                position: relative;
                .loadingAnimation {
                    position: absolute;
                    top: 30%;
                    right: 5%;
                    .MuiCircularProgress-root {
                        width: 20px !important;
                        height: 20px !important;
                    }
                }
            }
        }
        .field-control {
            width: 100%;
            padding: 0 5px;
            input {
                height: 0.8rem !important;
            }
        }
        .field-select {
            width: 100%;
            padding: 0 5px;
        }
        .cart-section {
            hr {
                margin: 0;
            }
            .cart-section_banner {
                height: 25vh;
                img {
                    height: 100%;
                }
            }
            .cart_delivery_time {
                background: #fff;
                padding: 10px 0;
                .delivery_icon {
                    padding: 0;
                    text-align: -webkit-center;
                }
                .delivery_content p {
                    font-size: 13px;
                }
            }
            // .cart_checkout_button {
            //     padding: 10px;
            //     button {
            //         width: 100%;
            //         background: $th-primary;
            //         &:hover {
            //             background: $th-secondary;
            //         }
            //     }
            // }
        }
    }
    
    #mobCart {
        .cart_checkout_button {
            padding: 10px;
            button {
                width: 100%;
                margin: 5px 0;
                &.button-contained {
                    // background: #0E2855;
                    background: $th-primary;
                    color: #fff;
                }
                &.disabled {
                    background: rgb(177, 177, 177);
                }
                &.button-outlined {
                    background: white;
                    color: $th-primary;
                }
                &:hover {
                    background: $th-secondary;
                    color: #fff;
                }
            }
        }
    }
    
    .mobCartAbsolute {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
    }
    
    // Addons
    .AddOns {
        .MuiButtonBase-root {
            padding: 0;
            padding-right: 10px;
        }
        .MuiFormControlLabel-root {
            margin: 0;
        }
    }
    
    .w-80 {
        width: 80%;
    }
    
    .txtBtnLeft {
        .MuiButton-label {
            justify-content: left !important;
        }
    }
    
    $text-field-line-height: 10px !important;
    button {
        text-transform: none !important;
    }
    
    // button[disabled] {
    //     cursor: not-allowed;
    // }
    .paymentContainer {
        position: relative;
        text-align: center;
        input {
            visibility: hidden;
            position: absolute;
        }
        span {
            font-size: 20px;
            // color: $primary;
        }
        .topUp_Label {
            margin: 0px !important;
            @include Medium {
                margin: 0px !important;
                line-height: 20px !important;
            }
        }
        label {
            width: 100%;
            height: 100%;
            line-height: 30px;
            background: #fff;
            // border-radius: 8px;
            cursor: pointer;
            transition: 0.3s ease;
            user-select: none;
            border-bottom: 5px solid #fff;
            opacity: 0.5;
            &:hover {
                background: #fff;
                border-color: $th-primary;
                opacity: 1;
            }
        }
        input:checked~label {
            // background: $primary;
            background: #fff;
            border-color: $th-primary !important;
            opacity: 1;
        }
.topUpBtn{
    background-color: $th-primary !important;
}

    }
    
    // -----------------------
    //       Sajid work
    // -----------------------
    .wpc_single_product- {
        h1 {
            color: $th-secondary;
            font-weight: 300;
        }
    }
    
    .wpc_single_product_price {
        h4 {
            color: $th-secondary;
        }
    }
    
    .wpc_buy_add_to_cart_button {
        span {
            margin-right: 2%;
        }
    }
    
    .quantity_inc_dec {
        h5 {
            color: $th-primary;
        }
    }
    
    .leftNav {
        width: 100%;
        max-width: 360;
    }
    
    .navText {
        color: black;
    }
    
    .footer_div {
        box-shadow: 0px -1px 7px 2px #b7b7b7;
        padding: 6px;
    }
    
    // SAJIDD
    // sikandar
    .a {
        border: "2px solid white";
    }
    
    // mainLoginModal
    .mainLoginModal {
        .login-head {
            text-align: center !important;
            // padding-bottom: 20px;
            h2 {
                font-weight: 700 !important;
            }
            #social-icons {
                display: flex;
                justify-content: center;
                margin-top: 15px;
                .innerdivSocialIcon {
                    height: 100%;
                    display: flex;
                    padding: 1px 1px 1px 1px;
                }
            }
        }
        .login-fields {
            max-width: 100%;
            // padding: 10px 0px;
            display: block;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            // .MuiFilledInput-underline:after {
            //     border-color: none !important;
            // }
            .MuiFilledInput-input {
                padding: 12px 12px !important;
            }
            .MuiFilledInput-underline {
                border: none !important;
            }
            .MuiFilledInput-underline:before {
                border: none !important;
            }
            .MuiFilledInput-underline:after {
                border-bottom: 2px solid $primary !important;
            }
            .row {
                padding: 10px !important;
            }
            .orclass {
                p {
                    width: 100%;
                    text-align: center;
                    border-bottom: 1px solid #a9a9a9;
                    line-height: 0.1em;
                    margin: 10px 0 20px;
                    span {
                        background: #fff;
                        padding: 0 10px;
                        font-weight: bolder;
                    }
                }
            }
            button {
                width: 100%;
                font-weight: 300;
                padding: 12px;
                // @include x-small-max {
                //     font-size: 0.7rem !important;
                // }
            }
            .register-btn {
                /* width: 100%; */
                width: 100%;
                color: #192841 !important;
                background-color: none !important;
                background: none !important;
                border: 2px solid #a9a9a9 !important;
                border-radius: 5px !important;
                text-align: center;
                padding: 10px;
            }
            // .forget-field {
            // //     span {
            //         // @include x-small-max {
            //         //     font-size: 0.5rem !important;
            //         // }
            //         // @include lowMax {
            //         //     font-size: 0.6rem;
            //         // }
            //     // }
            // }
        }
        // Login Modal
        .MuiBadge-badge {
            // font-size: 0.5rem !important;
            height: 20px !important;
            width: 15px !important;
            padding: 10px 6px !important;
        }
        .MuiBadge-colorPrimary {
            background-color: red !important;
        }
        .MuiBadge-anchorOriginTopRightRectangle {
            left: 95px !important;
        }
        .nav-link {
            // color: $th-primary;
            padding: 0 !important;
            &.active {
                .nav-link {
                    color: $th-secondary;
                }
            }
            position: relative;
            &::after {
                content: "";
                position: absolute;
                width: 2px;
            }
            &.other-links {
                color: #666666;
                &::after {
                    top: 0;
                    height: 100%;
                    background-color: #eee;
                    right: 0;
                }
                &:nth-last-child(3) {
                    &::after {
                        position: relative;
                    }
                }
            }
            &.text-link {
                font-size: 14px;
                &::after {
                    right: 0;
                    top: 28%;
                    height: 50%;
                    background: $th-primary;
                }
            }
        }
    }
    
    .MuiFilledInput-underline:before {
        border: none !important;
    }
    
    // sikandar changes
    // tool tip
    .tooltip {
        position: relative;
        display: inline-block;
        border-bottom: 1px dotted black;
    }
    
    .tooltip .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        top: 100%;
        left: 50%;
        margin-left: -60px;
    }
    
    .tooltip:hover .tooltiptext {
        visibility: visible;
    }
    
    .MuiTabs-indicator {
        background-color: $th-primary !important;
        height: 3px !important;
        // width: 70px !important;
    }
    
    .MuiTab-textColorInherit.Mui-selected {
        z-index: 099 !important;
        color: $th-primary !important;
        border-color: #E4E4E4 !important;
    }
    
    // .MuiTab-textColorPrimary.Mui-selected {
    //     color: $th-primary !important;
    //     font-family: Montserrat;
    //     font-weight: 300;
    // }
    
    .MuiTab-textColorPrimary {
        color: #303030 !important;
        font-family: Montserrat;
        font-weight: 300;
    }
    
    .MuiIconButton-root {
        padding: none !important;
        // padding: 3px !important;
    }
    
    .MuiSvgIcon-root {
        padding: 0 !important;
        height: 19px;
        width: 19px;
    }

    .newStoreArrowBack  .MuiSvgIcon-root{
        padding: 0px 0px 0px 5px !important;
        height: 22px;
        width: 22px;
    }
    
    .carousel .carousel-status {
        display: none;
    }
    
    .carousel .thumb {
        transition: border .15s ease-in;
        display: inline-block;
        margin-right: 6px;
        white-space: nowrap;
        overflow: hidden;
        border: 3px solid #fff;
        padding: 2px;
        width: 100px;
        height: 50px;
    }
    
    .carousel .slide {
        min-width: 100%;
        margin: 0;
        position: relative;
        text-align: center;
        height: 290px;
        padding-right: 0px !important;
    }
    
    .imgCenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        background-size: contain;
        text-align: center;
    }
    
    .MuiDialog-paperFullWidth {
        min-height: 95%;
        width: calc(100% - 64px);
        max-width: 90%;
        min-width: 95%;
        max-height: 95%;
    }
    
    .dialogs {
        .MuiDialogContent-dividers {
            border: none !important;
        }
        .MuiOutlinedInput-inputAdornedEnd {
            padding-right: 0;
            text-align: center !important;
        }
        .MuiButton-containedPrimary {
            color: #fff !important;
            background-color: $th-secondary !important;
            text-transform: none !important;
            padding: 2px 25px;
            span {
                font-size: 15px !important;
            }
        }
        .selectPayment {
            margin-top: 20px !important;
            .selectPaymentRow {
                .paymentContainer {
                    position: relative;
                    text-align: center;
                    input {
                        visibility: hidden;
                        position: absolute;
                    }
                    span {
                        font-size: 20px;
                        // color: $primary;
                    }
                    label {
                        width: 100%;
                        height: 100%;
                        line-height: 75px;
                        background: #fff;
                        // border-radius: 8px;
                        cursor: pointer;
                        transition: .3s ease;
                        user-select: none;
                        // border-bottom: 5px solid #fff;
                        opacity: .5;
                        &:hover {
                            background: #fff;
                            border-color: $th-primary;
                            opacity: 1;
                        }
                    }
                    input:checked~label {
                        // background: $primary;
                        background: #fff;
                        border-color: $th-primary;
                        opacity: 1;
                    }
                }
            }
        }
    }
    
    #storeProduct_layoutTwo {
        .MuiIconButton-root {
            padding: 2px !important;
        }
    }
    
    #cartSecLayoutTwo {
        box-shadow: 0px 0px 8px #0000000d !important;
    }
    
    .makeStyles-addToCart-5 {
        display: block;
        box-shadow: 0px 0px 8px #0000000d !important;
    }
    
    // work 14 sep and work on 23 sep
    .imgDivOfSlider {
        height: 380px !important;
    }
    
    .imgOfSlider {
        height: 100% !important;
    }
    
    .thumbs-wrapper {
        display: flex !important;
        justify-content: center !important;
    }
    
    .thumb {
        width: 143px !important;
        height: 73px !important;
    }
    
    .storeNameLogo {
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        font-size: 33px;
        line-height: 50px;
    }
    
    .newNavBar .nav_menu_toggler {
        padding-right: 16px !important;
        padding-top: 11px !important;
    }
    
    @media only screen and (max-width: 1010px) {
        .storeNameLogo {
            font-size: 23px;
            line-height: 66px;
        }
    }
    
    @media only screen and (max-width: 450px) {
        .storeNameLogo {
            font-size: 15px;
            line-height: 67px;
        }
    }
    
    @media only screen and (max-width: 450px) {
        .thumb {
            width: 94px !important;
            height: 60px !important;
        }
    }
    
    @media only screen and (max-width: 950px) {
        .MuiGrid-root {
            // padding-right: 0px !important;
        }
    }
    
    .wpc_side_AR {
        position: fixed !important;
        left: 0 !important;
    }
    
    .wpc_side_EN {
        position: fixed !important;
        right: 0 !important;
    }
    
    .app-rtl {
        .MuiTabs-scrollable {
            direction: rtl !important;
        }
    }



// 24 sep 2021 work

// .makeStyles-arrowforward-74{
//     width: 36px !important;
//     padding-right: 19px !important;
// }

.textDark{
    color:#000000 !important;
}

.fontFamilyMont{
    font-family: 'Montserrat', sans-serif !important;
}
.fontFamilyRoboto{
    font-family: 'Roboto', sans-serif !important;
}
.fontFamilyPoppins{
    font-family: 'Poppins', sans-serif !important;
}
.borderRadius6px{
    border-radius: 6px !important;
}

.ButtonColorBlue{
    background-color: $th-primary !important;
}

.handMadebtn .MuiSvgIcon-root{
    padding: 0px 0px 0px 2px !important;
    

}


.ordersForm{
    span{
        text-transform: capitalize  !important;
    }
    
}

.wpc_tabs {
    .MuiTabs-fixed {
    background: transparent !important;
    border: none !important;
    border-radius: 0 !important;
}
}

.MuiIconButton-sizeSmall {
     padding: none !important;
    font-size: 1.125rem;
}

//   16 october
.positionForOptionalInput{
    position: relative;
    top: 28px !important;
    font-family: Montserrat !important;
    line-height: 0px;
    }

.l2HomeAppBar Header{
    border-bottom: 1px solid #DADADA !important;
}

// 18 oct
.sideCartIncDec .MuiSvgIcon-root{
    border-radius: 15px !important;
    border: 1px solid #F2F2F2 !important;
    font-size: 18px !important;
    font-family: 'Roboto', sans-serif !important;
}
.NewStoreIncDec .MuiSvgIcon-root{
    border-radius: 15px !important;
    border: 1px solid #F2F2F2 !important;
    font-size: 21px !important;
    font-family: 'Roboto', sans-serif !important;
    color: #55708B !important;
}
.textC2{
    color: #858585 !important;
}
.textBF{
    color:#BFBFBF !important;
}
.text7B{
    color:#7B7B7B !important
}
.newstoreBreadCrumb .MuiTypography-body1 {
    font-size: 14px !important;
}

@media only screen and (max-width: 1200px) {
    .newstoreBreadCrumb .MuiSvgIcon-fontSizeSmall{
        font-size: 16px;
        margin-top: 2px;
    }
    
}
@media only screen and (max-width: 330px) {
    .fontSize12px{
        font-size: 11px !important;
    }
    
}

@media only screen and (max-width: 500px) {
    .fontSize14px{
        font-size: 14px !important;
    }
    
}

#DineIn .conditionLoader{
    text-align: center !important;
}
#Delivery .conditionLoader{
    text-align: center !important;
}
.forLoader .conditionLoader{
    text-align: center !important;
}

.cursor-pointer{
    cursor: pointer !important;
}

.disableClass {
    pointer-events: none !important;
    opacity: 0.7 !important;
    // background-color: rgba(136, 136, 136, 0.5) !important;
    background-color: linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ) !important;
  }

  .loginBtnDiv{
      .loginBtnAr{
        font-size: 12px;
        padding: 3px !important;
    
      }
  }

  .newStoreArrowBackAr .MuiSvgIcon-root {
    padding: 4px !important;
   
}

  @media (max-width: 1279.95px){
    .newStoreArrowBack .MuiSvgIcon-root {
        padding: 0px 0px 0px 5px !important;
        width: 15px !important;
        height: 15px !important;
    }
    .newStoreArrowBackAr .MuiSvgIcon-root {
        padding: 2px !important;
        width: 15px !important;
        height: 15px !important;
    }
    #storeProduct_layoutTwo .MuiIconButton-root{
        margin-top: 5px;
    }
  }
  
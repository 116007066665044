// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../../sass/variables";
// Mixins
@import "../../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../../sass/global";



#wrapperFeaturedProductSlider2 {
  position: relative;
  .MuiAppBar-root{
    z-index: 0 !important;
  }

  h4 {
    font-size: 1.3rem !important;
  }

  .headLine {
    font-size: 0.9rem !important;
  }

  section#ProductsFeatured {
    .inner_prod {
      position: relative;

      // .prod_link {
      //     // background: #f9f9f9;
      //     // padding: 10px;
      //     // border-radius: 10px;
      // }
      span {
        text-decoration: none;
        display: block;
      color: rgb(26, 26, 26);



        .prod_categ_img {
          width: 100%;
          background-size: cover !important;
          color: #fff;
          text-align: center;
          position: relative;
          height: 100px;

          // padding: 0;
          // &::before {
          //     content: "";
          //     padding-left: 5%;
          //     position: absolute;
          //     left: 0;
          //     top: 0;
          //     height: 100%;
          //     width: 100%;
          //     border-radius: 10px;
          //     background: linear-gradient(
          //         rgba(0, 0, 0, 0.1),
          //         rgba(0, 0, 0, 0.2)
          //     );
          // }
          img {
            border-radius: 8px;
            height: 100%;
            width: 100%;
            // padding-left: 5%;
          }

          h5 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .prdCard {
          width: 100%;
          // background-color: #fff !important;
          // color: #fff;
          // border-radius: 8px !important;
          // text-align: center;
          // position: relative;
          // height: 140px;
          // border: 1px solid #EEE !important;

          .catName {
            color: #2E3039 !important;
            font-size: 14px !important;
            text-transform: capitalize !important;
          }

          .quantityAvatar {
            .MuiAvatar-root {
              background-color: #FF9E1B !important;
              width: 20px !important;
              height: 20px !important;
              font-size: 12px !important;
            }
          }

          .AvatarParent {
            position: relative;

            .ChipClass {
              position: absolute;
              bottom: 0 !important;
              background: #0EB153 !important;
              color: #fff !important;
              font-size: 0.76rem !important;
              z-index: 999;
              left: 0;
              right: 0;
              border-radius: 0 !important;
              opacity: 0;
              transform: translateY(20px);
              /* Optional: Starting from a slightly lower position */
              transition: opacity 1s ease-in-out, transform 1s ease-in-out;

              span {
                color: #fff !important;
              }

              svg {
                color: #fff !important;
              }
            }

            .ChipClassAnimation {
              opacity: 1;
              transform: translateY(0);
              /* Bring it back to its original position */
            }

            .MuiAvatar-root {
              width: 100% !important;
              height: 104px !important;
              border-radius: 8px 8px 0 0 !important;
            }
          }



          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            // background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
            border-radius: 10px !important;
          }

          img {
            border-radius: 8px 8px 0px 0px !important;
            height: 100%;
            width: 100% !important;
          }

          .prodAddToCart {
            background-color: $th-primary  !important;

            span {
              color: #fff !important;
              display: flex !important;
            }
          }

        }

        .prod_content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          color: #000 !important;

          h5 {
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
          }

          h6 {
            color: #000;
            margin: 5px 0;
            font-size: 13px;
            font-weight: 300;
          }

          h4 {
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;

            @include lowMax {
              font-size: 1.1rem !important;
            }
          }

          p {
            font-size: 12px;
            color: rgba(39, 39, 39, 0.89);

          }
        }
      }
    }
  }



  .foodHallOwlCarousel {
    direction: ltr !important;

    .MuiTabs-indicator {
      display: none;
      background-color: #EB5B5E !important;
      height: 3px !important;
    }

    .categoryTab {
      padding: 0 !important;
      margin: 0 6px !important;
      width: 310px !important;
      background-color: #fff !important;
      color: #fff;
      position: relative;
      height: 99px !important;
      // border: 1px solid #EEE !important;/
      display: flex;
      flex-direction: column;
      justify-content: start !important;

      .item {
        width: 100% !important;
      }
    }

    .Mui-disabled {
      opacity: 1 !important;
    }

    .MuiTabScrollButton-root {
      display: none !important;

      svg {
        font-size: 30px !important;
        font-family: Poppins !important;
        color: #74757B !important;
      }

    }

    .owl-carousel {
      direction: ltr !important;

      .owl-stage-outer {
        .owl-stage {
          padding-left: 0 !important;
          // padding-right: 20px;
        }
      }

      direction: ltr !important;

      .owl-item {
        padding: 0 6px !important;
      }

      .owl-dots {
        display: none !important;
      }

      .owl-nav {
        font-size: 30px !important;
        font-family: Poppins !important;
        color: #74757B !important;
      }
    }
  }

  .foodHallOwlCarouselEn {
    .MuiTabScrollButton-root {
      position: absolute !important;
      top: 14px !important;
      right: 0 !important;
    }

    [data-testid="KeyboardArrowLeftIcon"] {
      position: absolute;
      top: 1px;
      right: 66px;
    }

    [data-testid="KeyboardArrowRightIcon"] {
      position: absolute;
      top: 1px;
      left: 0px;
      // transform: scaleX(1);
    }

    .owl-nav {
      position: absolute !important;
      top: -55px !important;
      margin: 0 !important;
      right: 0 !important;
    }

  }

  .foodHallOwlCarouselArabic {
    .MuiTabScrollButton-root {
      position: absolute !important;
      top: 24px !important;
      left: 0 !important;
    }

    [data-testid="KeyboardArrowLeftIcon"] {
      position: absolute;
      top: -8px;
      left: 22px;
      // transform: scaleX(-1);
    }

    [data-testid="KeyboardArrowRightIcon"] {
      position: absolute;
      top: -8px;
      left: 70px;
      // transform: scaleX(-1);
    }

    .owl-nav {
      position: absolute !important;
      top: -55px !important;
      left: 0 !important;
    }
  }

}
// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import "../../layout-one/sass/variables";
@import "../../layout-one/sass/mixins";
@import "../../layout-one/sass/global";

.TopUpPopUpl1 {
    // height: 250px;
    position: fixed;
    z-index: 9999;
    bottom: 0;
    left: 0;
    background-color: #FFF;
    overflow-x: hidden;
    transition: 0.5s;
    width: 100% !important;
    border-radius: 20px 20px 0px 0px;
    @include middleMin {
        width: 49.8% !important;

    }
  

    .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
        display: none !important;
    }
   
}
.TopUpPopUpl1Arabic {
    margin-left: auto !important;
    right: 0 !important;
    left: auto !important;
    width: 100% !important;
    @include above1200 {
        width: 49.8% !important;
    }
}


.MuiDialogContent-root {
    padding: 10px 24px !important;
  }
  
  #TopUpPopUpMainl1 {
    background-color: #ffff;
    .store-tilte-card {
      .bussinessDecs {
        font-size: 10px !important;
      }
    }
    h1 {
      font-size: 25px !important;
      color: black;
      font-weight: 800;
      font-family: Manrope;

    }
    h2 {
      font-size: 17px !important;
      color: black;
      font-weight: 800;
      font-family: Manrope;
      @include below1200 {
        font-size: 17px !important;
      }
      @include below425 {
        font-size: 17px !important;
      }
    }
  
    h3 {
      font-size: 18px;
      color: black;
      font-weight: 800;
      font-family: Manrope;
    
    }
    h4{
        color: #707070 !important;
    }
    p {
      font-size: 18px;
      color: #4f4f4f;
      font-weight: 300;
      font-family: Manrope;

    }
    .offset-ar-2 {
      margin-right: 16.66666667% !important;
    }
    .imgDiv {
      height: 82px !important;
    }
    .contactDiv {
      .MuiButton-root {
        border-radius: $borderRadius !important;
        width: 100% !important;
        font-size: $paragraphSize;
        color: #000000;
        font-family: Manrope;
        font-weight: 300;
        background-color: #f4f4f4;
        @include x-small-max {
          font-size: 0.6rem;
          border-radius: 8px !important;
        }
      }
    }
    .submitBtn {
      .MuiButton-root {
        border-radius: $borderRadius !important;
      }
    }
  
    .classForAr {
      .MuiButton-startIcon {
        display: inherit;
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
    }
    .storeClosed {
      color: red !important;
      font-size: 10px !important;
    }
    .storeOpen {
      color: #289570 !important;
      font-size: 10px !important;
    }
    .MuiRating-root {
      direction: ltr !important;
      label {
        font-size: 60px !important;
  
        @include above1440 {
          padding: 0px 20px 0px 20px;
        }
        @include below1200 {
          padding: 0px 10px 0px 10px;
          font-size: 30px !important;
        }
        @include below425 {
          padding: 0px 10px 0px 10px;
          font-size: 30px !important;
        }
        @include x-small-max {
          font-size: 21px !important;
        }
      }
    }
    .addInstructions {
      p {
        font-size: 18px;
        color: #000000 !important;
       
      }
    }
    .textArea {
      width: 100%;
      font-size: $paragraphSize;
      padding: 26px 30px;
      border: none;
      resize: none;
      border-radius: $borderRadius;
      border: 2px solid #eaeaea;
      @include below1200 {
        padding: 12px 22px !important;
      }
      @include below425 {
        padding: 12px 22px !important;
      }
    }
    .popUp-amount {
      font-size: 1.1rem;
      font-weight: 900;
      // border: 1px solid $primary;
      // border-radius: 5px;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $th-primary !important;
      }
      .MuiOutlinedInput-input {
        /* padding: 18.5px 14px; */
        padding: 0px;
      }
     
      .amount-field:focus {
        border: 1px solid $th-primary !important;
        border-radius: 5px !important;
      }
      .amount-field {
        padding: 20px;
        @include lowMax {
          padding: 10px !important;
        }
        // color: $primary !important;
        color: #000;
        font-size: 1.2rem !important;
        input {
          text-align: center;
        }
        border: 1px solid $th-primary !important;
      }
    }
  }
  // css for scroll hide and modal width size
  .MuiDialog-paperWidthLg {
    max-width: 695px !important;
    border-radius: 24px !important;
  }
  .MuiDialogContent-root::-webkit-scrollbar {
    display: none !important;
  }
// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

#status-header {
  background-color: #ffff !important;
  // z-index: 1101 !important;
  left: 0 !important;
  // transition: all 3s ease-in-out;

  .statusAvatar {
    width: 50px !important;
    height: 50px !important;
  }

  .svgImg {
    width: 150px !important;
  }

  .LangButton {
    background-color: #F4F4F4 !important;
    padding: 0px !important;

  }

  .familyGraphikArabic {
    font-family: GraphikArabic !important;
  }

  .status-heading {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 15px !important;
  }

  .status-description {
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 15px !important;
  }

  .classForSvgColor {
    svg {
      color: #000 !important;
      transition: all 0.5s ease-in-out;

      path {
        fill: #000 !important;
      }
    }
  }

  .lineDiv {
    width: 100%;
    height: 10px;
    border-bottom: 4px solid #45474F;
  }

  .triangle {
    position: relative !important;
    bottom: 10px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }

  .serviceStatusIcon {
    .gentle-tilt-move-shake {
      animation: tilt-n-move-shaking 0.25s infinite;
    }

    @keyframes tilt-n-move-shaking {
      0% {
        transform: translate(0, 0) rotate(0deg);
      }

      25% {
        transform: translate(5px, 5px) rotate(5deg);
      }

      50% {
        transform: translate(0, 0) rotate(0eg);
      }

      75% {
        transform: translate(-5px, 5px) rotate(-5deg);
      }

      100% {
        transform: translate(0, 0) rotate(0deg);
      }
    }
  }
}


.progress_Status_Header {

  .MuiLinearProgress-bar {
    // background-color: #FF595A !important;
    background-color: $th-primary !important;
    height: 6px !important;
    border-radius: 5px !important;
  }

  .MuiLinearProgress-root {
    background-color: #EEEEEE !important;
    height: 6px !important;
    border-radius: 5px !important;
  }

  .MuiAvatar-root {
    height: 96px !important;
    width: 96px !important;
  }
}

.classeOnScrollOpenOrder {
  // animation: mymove 0.5s alternate;
  // animation-timing-function:ease-in ;
  // transition: mymove 0.5s ease-in-out;
  position: absolute !important;
  // .svgImg{
  //   display: none !important;
  // }
  // .statusAvatar{  
  //   display: block !important;
  // }
}

@keyframes mymove {
  from {
    top: 64px;
  }

  to {
    top: 60px;
  }
}

// .classeOffScrollOpenOrder{
//   .svgImg{
//     display: block !important;
//   }
//   .statusAvatar{
//     display: none !important;
//   }
// }
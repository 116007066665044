// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

.orderProductv3Parent {


  .addonsWrapEn{
    padding-left: 33px !important;
 

    .addonsh3 {
      font-size: 15px;
      color: #5D5E65;
      
      @include below375 {
        margin-top: 7px !important;
        font-size: 12px !important;
      }
    }
  
    .addonsh4{
      color: #5D5E65;
      font-size: 15px;
      opacity: 0.6 !important;
      @include below375 {
        font-size: 12px !important;
      }
      
    }   
  }
  .addonsWrapAr{
    padding-right: 33px !important;

    .addonsh3 {
      font-size: 16px;
      color: #000000;
      

      @include below375 {
        font-size: 12px !important;
      }
    }
  
    .addonsh4{
      color: #545454;
      font-size: 16px;
      opacity: 0.6 !important;
      @include below375 {
        font-size: 12px !important;
      }
      
    }   
  }

  .addonsMainDiv{
    svg{
      font-size: 0.7rem !important;
      margin-bottom: 0.5rem !important;
    }
    .addonsParent{
      & > * {
      padding: 2px !important;
      }
    }
  }

  .orderImage {
    height: 86px !important;
    width: 86px !important;
    border-radius: 6px !important;
  }

  .ChipClass {
    border: transparent;
    height: 26px !important;
    text-transform: capitalize !important;
    .MuiChip-icon {
      margin: 0 0 0 7px !important;
    }
    .MuiChip-label {
      padding: 0px 13px !important;
    }
  }


  .pending {
    color: #FFBA00 !important;
    background: #FFF7D8 !important;
  }

  .rejected {
    color: #f74e4e !important;
    background-color: #fbdbdb !important;
  }

  .ready {
    color: #4ac53b !important;
    background-color: #cdffc6 !important;
  }

  .process {
    color: #61adff !important;
    background: #dbedff !important;

  }





  .ChipClassDanamic {
    border: transparent;
    height: 35px !important;
    text-transform: capitalize !important;

    .MuiChip-icon {
      margin: 0 0 0 7px !important;

    }

    .MuiChip-label {
      padding: 0px 13px !important;
    }
  }

  .chipOne {
    background-color: rgba(255, 158, 27, 0.1) !important;

    .MuiAvatar-root {
      background: #FF9E1B !important;
      color: #fff !important;
    }
  }

  .chipTwo {
    background: rgba(255, 76, 0, 0.1) !important;

    .MuiAvatar-root {
      background-color: #FF4D00 !important;
      color: #fff !important;

    }
  }

  .chipThree {
    background: rgba(173, 0, 255, 0.1) !important;

    .MuiAvatar-root {
      background: #AD00FF !important;
      color: #fff !important;

    }
  }
  .numberListing{
    border: 1px solid #EEEEEE ;
    border-radius: 4px ;
   height: 24px ;
   width: 24px ;
   font-size: medium !important;
  }
}
// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

#swap-hand {
  // height: 1000px;
  
  .promptBox {
    // height: 1000px;
    width: 100px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 55%;
    opacity: 1;
    transition: 300ms;
    pointer-events: none;
    z-index: 1000;
  }
  
  .tapperoo {
    height: 48px;
    width: 100px;
    position: absolute;
    top: -5px;
    left: 50px;
    border-radius: 100px;
    background-color: #fff;
    z-index: -1;
    animation: tapperoo 3s infinite;
  }
  
  @keyframes tapperoo {
    0% {
      height: 25%;
      width: 25%;
    }
  
    50% {
      height: 5%;
      width: 35%;
      opacity: 0;
      transform: translate(-250%);
    }
  
    100% {
      opacity: 0;
    }
  }
  
  #tap-gesture {
    position: absolute;
    transform: rotate(30deg);
    animation: handMove 3s infinite;
  }
  
  @keyframes handMove {
    0% {
      transform: rotate(30deg);
    }
  
    50% {
      transform: translate(-60%, -10%) rotate(-30deg) scale(0.9);
    }
  
    100% {
      transform: rotate(30deg);
    }
  }
  
}
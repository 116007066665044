// Layout One Scss
// Fonts
// @import url("https://fonts.googleapis.com/css?family=Nunito");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;700&display=swap");
// Variables
@import "variables";
// Mixins
@import "mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "_global";


// Wrapper
#wrapper {
  position: relative;
  // Home Page Started // ----------------------------------------------------------------//



  .storeInfo {
    .DeliveryTitle{
      font-size: 1rem;
      @include below600{
        font-size: 0.8rem !important;
      }
    }
    .DeliveryTitle2{
      font-size: 1rem;
      @include below600{
        font-size: 0.8rem !important;
      }
    }
    .clockIcon {
      font-size: 16px;
      fill: #6c757d;
    }

    @include lowMax {
      font-size: 0.8rem !important;
    }

    .tableShow {
      padding: 10px 10px;
      background: #f9f9f9;
      border-radius: 7px;

      .tableNo {
        font-size: 1rem !important;
      }
    }
  }

  .selectedArea {
    h5 {
      margin: 0;
      font-size: 1rem;
    }
  }

  // Info Section Styling
  // section#info {
  //     // sajid work
  //     // @include lowMax {
  //     //     margin-top: 100px;
  //     // }
  //     border: 1px solid #eee;
  //     background: #fff;
  //     &:hover {
  //         background: #eee;
  //         transition: 0.4s;
  //     }
  //     a {
  //         padding: 15px 20px;
  //         display: block;
  //         color: #000;
  //         &:hover {
  //             text-decoration: none;
  //         }
  //         .logoImg {
  //             width: 60px;
  //             border-radius: 8px;
  //             img {
  //                 border-radius: 8px;
  //             }
  //         }
  //         .infoContent {
  //             h6,
  //             p {
  //                 margin: 0;
  //             }
  //             .small {
  //                 color: grey;
  //             }
  //             .paymentIcons {
  //                 color: rgb(59, 236, 59);
  //                 font-size: 16px;
  //                 padding: 2px 0;
  //                 i.fa {
  //                     margin-right: 7px;
  //                 }
  //             }
  //         }
  //         .infoIcon {
  //             font-size: 18px;
  //             color: grey;
  //             margin-right: 20px;
  //         }
  //     }
  // }

  section#info {
    // background: #f9f9f9;
    background: #fff;
    // border-radius: 10px !important;
    padding: 15px 0px;

    // border-radius: 10px;
    // &:hover {
    //     background: #eee;
    //     transition: 0.4s;
    // }
    .infoContent {
      padding: 15px 20px;
      display: block;
      color: #000;

      &:hover {
        text-decoration: none;
      }

      .logoImg {
        width: 60px !important;
        border-radius: 8px !important;

        img {
          border-radius: 8px !important;
        }

      }

      .infoContent {

        h6,
        p {
          margin: 0;
        }

        .small {
          color: grey;
        }

        .paymentIcons {
          color: rgb(59, 236, 59);
          font-size: 16px;
          padding: 2px 0;

          i.fa {
            margin-right: 7px;
          }
        }
      }

      .infoBtn {
        padding: 5px 15px;
        background: #eeeeee;
        border-radius: 12px;
        border: none;
        font-size: 14px !important;
      }
    }

    .payTableBackBtn {
      button {
        background-color: #fff !important;
        color: #000000 !important;
      }

      @include middleMax {
        display: none !important;
      }
    }
  }

  // Info Section Styling
  section#searchSect {
    display: none;

    @include middleMin {
      display: block;
    }

    margin: 2px 0;
    border: 1px solid #eee;

    a {
      &:hover {
        text-decoration: none;
      }
    }

    .srcBar {
      .form-control {
        border: 0;
        height: 45px;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      .searchIcon {
        margin-left: -30px;
        color: #3b3b3b;

        &:hover {
          text-decoration: none;
        }

        .MuiIconButton-root {
          padding: 12px 0px 0px 12px !important;
        }
      }
    }
  }

  .addonCart {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  // BranchBar Section Styling
  section#branchSection {
    margin-top: 2px;

    .branch_bar {
      background: #fff;
      padding: 20px;
      border: 1px solid #eee;

      a {
        color: #000;
        text-decoration: none;
        padding: 8px 20px;
        border-radius: 5px;
        transition: 0.2s ease;
        font-weight: 300;
        font-size: 12px;

        &:hover {
          background: #eee;
        }
      }
    }
  }

  // Product Category Section Styling
  .prod_main_div {
    margin-top: 0;

    .prod_content {
      p {
        overflow: hidden !important;
      }
    }
  }

  section#Products {
    .circularContainer {
      position: absolute !important;
      top: 30% !important;
      left: 45% !important;
      transform: translate(-50%, -50%) !important;
    }

    .circularContainerAr {
      position: absolute !important;
      top: 40% !important;
      left: 55% !important;
      transform: translate(-50%, -50%) !important;
    }

    &.products {
      margin-top: 30px;
      margin-bottom: 0px !important;

      @include middleMax {
        margin-top: 1%;
      }

      @include lowMax {
        margin-top: 1%;
      }
    }


    .En {
      padding: 0 !important;
      border: 3px solid #fff;
    }

    .Ar {
      padding: 0 !important;
      border: 3px solid #fff;
    }

    // sajid work
    min-height: 50vh;
    margin: 20px 0;

    .inner_prod {
      position: relative;
      .hideProductImage{
        display: none !important;
      }

      a {
        text-decoration: none;
        display: block;
        color: rgb(26, 26, 26);

        .prod_categ_img {
          width: 100%;
          background-size: cover !important;
          color: #fff;
          text-align: center;
          position: relative;
          // height: 180px !important;
          object-fit: cover !important;
          border-radius: 10px;
          height: 20vh !important;
          width: 18vw !important;

          @include lowMax {
            height: 16vh !important;
            width: 40vw !important;
          }

          @include middleMax {
            height: 20vh !important;
            width: 46vw !important;
          }

          // @include middleMin{
          //   height: 220px !important;
          // }
          img {
            border-radius: 10px;
            height: 100%;
            width: 100%;
            // padding-left: 5%;
          }

          h5 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .prod_categ_img_search_inhance {
          border-radius: 10px;
          height: 100%;
          width: 100%;
        }

        .prdCardCat {
          width: 100%;
          background-size: cover !important;
          color: #fff;
          border-radius: 10px !important;
          text-align: center;
          position: relative;
          height: 240px;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
            border-radius: 10px !important;
          }

          img {
            // border-radius: 10px;
            height: 100%;
            width: 100%;
          }

          h6{
            position: absolute;
            text-transform: capitalize;
            bottom: 16px !important;
          }

          .CategoryNameEn{
            position: absolute;
            left: 26px !important;
            // text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
            text-shadow: 1px 1px 6px rgba(0,0,0,.5);
          }
          .CategoryNameAr{
            text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
            position: absolute;
            right: 26px !important;
          }

        }

        .prod_content {
          color: #000 !important;
          h5 {
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
          } 

          h4 {
            // white-space: nowrap !important;
            // overflow: hidden !important;
            // text-overflow: ellipsis !important;
            font-size: 1.6rem !important;

            // @include lowMax {
            //   font-size: 1rem !important;
            // }

            @include middleMax {
              font-size: 1.2rem !important;
            }
          }


          h6 {
            color: #000;
            margin: 5px 0;
            font-weight: 300;
            font-size: 1.3rem;

            // @include lowMax {
            //   font-size: 1rem !important;
            // }
            @include middleMax {
              font-size: 1rem !important;

            }

        
          }

          .hascalorie{
            h6 {
              font-size: 1.3rem !important;
              @include middleMax {
                font-size: 0.8rem !important;
              }
            }
            .calorieColor{
              color: #0076CE !important;
            }
          }
       
     


          p {
            font-size: 12px;
            color: rgba(39, 39, 39, 0.89);

          }
        }
      }
    }

    .ChipClass {
      background: #F4F4F4 !important;
      border: transparent;
      height: 26px !important;
      text-transform: capitalize !important;

      .MuiChip-icon {
        margin: 0 0 0 7px !important;

      }

      .MuiChip-label {
        padding: 0px 7px !important;
      }

    }
  }

  .inner_prod {
    position: relative;

    a {
      text-decoration: none;

      .prdcard {
        width: 100%;
        background-size: cover !important;
        color: #fff;
        text-align: center;
        position: relative;
        height: 160px;

        &::before {
          content: "";
          padding-left: 5%;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        }

        img {
          height: 100%;
          width: 100%;
        }

        h5 {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
        }
      }
    }
  }

  // Location Button Section Styling
  .selectLocaiton {
    position: relative !important;
    z-index: 0 !important;
  }

  section#locationSelect {
    .inner_location_select {
      background: #fff;
      padding: 10px;
    }
  }

  // Home Page Ended // ---------------------------------------------------------------------//
  // Contact Page Started // ---------------------------------------------------------------------//
  // changes 24-May sajid
  .whatsApp {
    a {
      text-decoration-color: #25d366;
    }

    i {
      color: #25d366 !important;
    }
  }

  .cancelBtn {
    color: #ffff !important;
    background-color: red !important;
  }

  #contact-page {
    section#leaveFeedback {
      .inner_leave_feedback {
        background: #fff;
        padding: 10px 0;
        border: 1px solid #eee;
      }
    }

    section#contactSocialIcons {
      .inner-contact_social_icons {
        padding: 10px !important;
        background: #fff;
        border: 1px solid #eee;

        // margin: 5px 0;
        @include middleMax {
          // padding: 0px 20px 20px 20px;
        }

        .col {
          h6 {
            text-decoration: none !important;
            color: $th-primary;
          }

          i.fa {
            font-size: 30px;

            &.fa-instagram {
              color: brown;
            }

            &.fa-globe {
              color: $th-primary;
            }
          }
        }
      }
    }

    section#timings {
      .inner_timings {
        background: #fff;

        .clpseBtn {
          transition: 0.2s ease;

          // padding: 5px;
          i.fa {
            border-radius: 50%;
            width: 30px;
            height: 30px;
            padding: 8px;

            &:hover {
              background: #eee;
            }
          }
        }

        .rotateClpse {
          i {
            transform: rotate(180deg);
          }
        }

        .innerClpse {
          padding: 10px 40px;

          table {
            width: 100%;

            td {
              width: 38%;
            }
          }
        }

        @media screen and (max-width: 480px) {
          .innerClpseContactModalsmallSize {
            padding: 12px 7px !important;

            .btnSmallContact {
              font-size: 11px !important;
            }
          }
        }
      }
    }

    section#feedbackModel {
      // Position
      position: fixed;
      z-index: 1050;
      bottom: -420px;
      transition: 0.5s ease;
      padding: 0;
      background: #f4f5f5 !important;

      //
      .inner_feedback_mdl {
        padding: 20px 10px 0 10px;
        position: relative;

        .close_btn {
          @include close_btn;
        }

        .form-control {
          height: 50px;
          background: transparent;
          color: grey;

          &:focus,
          &:hover {
            border: 2px solid $th-primary;
          }
        }

        .feedbackIcons {
          height: 60px;

          /* use display:inline-flex to prevent whitespace issues. alternatively, you can put all the children of .rating-group on a single line */
          .feedback-group {
            display: inline-flex;
          }

          /* make hover effect work properly in IE */
          .feedback__icon {
            pointer-events: none;
          }

          /* hide radio inputs */
          .feedback__input {
            position: absolute !important;
            left: -9999px !important;
          }

          /* hide 'none' input from screenreaders */
          .feedback__input--none {
            display: none;
          }

          /* set icon padding and size */
          .feedback__label {
            cursor: pointer;
            padding: 0 0.1em;
            margin: 0;
            font-size: 1.8rem;

            &:hover {
              font-size: 2rem;
            }
          }

          /* set default star color */
          .feedback__icon--feedback {
            color: orange;
          }

          /* if any input is checked, make its following siblings grey */
          .feedback__input:checked~.feedback__label .feedback__icon--feedback {
            color: #ddd;
          }

          /* make all stars orange on rating group hover */
          .feedback-group:hover .feedback__label .feedback__icon--feedback {
            color: orange;
          }

          /* make hovered input's following siblings grey on hover */
          .feedback__input:hover~.feedback__label .feedback__icon--feedback {
            color: #ddd;
          }
        }
      }
    }
  }

  // Search Page Started
  .signleImg {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    .prodImage {
      width: 100% !important;
      height: 60% !important;
      
      @include above768 {
      width: 60% !important;
      }
      // max-width: 100%;
      // height: auto;
    }

    .noImage {
      width: 100% !important;
      height: 500px !important;
    }
  }

  section#srchPage {
    #srchNav {
      .bckNavbar {
        background: #fff;
        padding: 10px;
        margin-top: 10px !important;
        border: 1px solid #eee;

        a {
          padding: 5px 10px;
          color: #000;
        }

        .form-control {
          border: 0;
          width: 90%;
        }
      }
    }

    .categHead {
      padding: 10px 20px;

      h6 {
        margin: 0;
      }
    }

    .src_prods {
      padding: 8px 0;
      background: #fff;
    }
  }

  // Single Product Page Started
  section#SingleProduct {
    margin-top: 50px;
    .timer-content{
      font-size: 1rem !important;
      font-weight: 600;
      @include middleMax {
        font-size: 0.8rem !important;
    }
    svg{
      font-size: 1.2rem !important;
      font-weight: 800;
      @include middleMax {
        font-size: 1rem !important;
      }
    }
  }
    @include lowMax {
      margin-top: 0px;
      margin-bottom: 20%;
    }

    .prodImage {
      text-align: center;
      background: #fff;

      img {
        height: 250px;
        width: 100%;

        @include middleMin {
          height: 350px;
        }
      }
    }

    .prod_price_title {
      padding: 15px 20px;
      border: 1px solid #eee;
    }

    .prodQuantity {
      padding: 15px 20px;
      border: 1px solid #eee;
      background: #fff;
    }

    .variations_addons {
      padding: 20px;
      background: #fff;
      border: 1px solid #eee;

      @include lowMax {
        padding-bottom: 80px;
      }

      .custom-control-label {
        display: block;
        user-select: none;
        cursor: pointer;
        font-size: 16px;
      }
    }

    .prodSpecialReq {
      background: #fff;
      padding: 15px 20px;
      margin-bottom: 100px;

      .spReq {
        padding: 0 10px;

        input {
          border: 0;
          border-bottom: 1px solid #000;
        }
      }
    }

    .add_to_cart {
      background: #fff;
      padding: 10px 20px;

      button {
        padding: 10px;
      }
    }
  }

  .formFields {
    label+.MuiInput-formControl {
      // padding: 10px 0px !important;
      font-weight: 300;
    }
  }

  #Branch {
    section#chooseLocation {
      margin-top: 30px;
      padding: 20px;
      background: #fff;

      @include lowMax {
        margin-top: 0px;
      }

      .srch_area {
        padding: 0 10px;

        .srch_area_inp {
          position: relative;

          .srch_icon {
            position: absolute;
            bottom: 6px;
            font-size: 16px;
          }

          input.form-control {
            border: 0;
            padding-left: 22px;
            border-bottom: 1px solid rgb(170, 170, 170);
            border-radius: 0;

            &:focus {
              border-bottom: 2px solid $th-primary;
            }
          }
        }
      }

      section#srchList {
        #delivery {
          margin-top: 20px;
        }

        .deliverySection {
          position: fixed;
          margin-top: 10px;
          height: 100%;
          left: 0;
          background: #fff;
          overflow-y: scroll;
        }

        ul {
          padding-left: 10px;
          list-style: none;

          li {
            &.MainActive {
              position: relative;

              .mainActSpan {
                position: sticky;
                top: 0;
                background: #fff;
                z-index: 1111;
              }
            }

            span {
              display: block;
              padding: 10px 0px;
            }

            .act {
              position: relative;

              span {
                position: sticky;
                top: 30px;
                background: #fff;
              }

              li {
                padding: 10px;
                color: #9a9a9a;
                border-radius: 5px;
                cursor: pointer;
                margin: 2px 0;

                &:hover,
                &.activeSelect {
                  background: $th-primary;
                  color: #fff;
                }
              }
            }
          }
        }
      }

      .pickupSection {
        .srch_area {

          label,
          .srch_icon {
            color: rgba(0, 0, 0, 0.42);
          }
        }

        .AriaSelection {
          margin-top: 40px;
          min-height: 40vh;

          input {
            visibility: hidden;
            position: absolute;
          }

          input:checked+.area_selection_container {
            background: $th-primary;
            color: #fff;
          }

          label {
            display: block;
            width: 100%;
            color: rgba(0, 0, 0, 0.42);
            background: transparent;

            .area_selection_container {
              display: block;
              width: 100%;
              padding: 10px;
              font-size: 15px;
              cursor: pointer;
              border-radius: 8px;
              transition: 0.2s ease;

              &:hover {
                background: #eee;
              }
            }
          }

          .done_Btn {
            bottom: 10px;
          }
        }
      }
    }
  }

  .contact-field {
    border-color: $th-primary  !important;
  }

  #details_section {
    margin-top: 50px;
    height: 90vh;
    background: #fff;

    @include lowMax {
      margin-top: 0;
    }

    .or_line {
      p {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #000;
        line-height: 0.1em;
        margin: 10px 0 20px;

        span {
          background: #fff;
          padding: 0 10px;
        }
      }
    }

    .already-btn {
      /* width: 100%; */
      width: 100%;
      color: $th-secondary  !important;
      background-color: none !important;
      background: none !important;
      border-style: none !important;
      text-align: center;
      padding: 10px;
    }
  }

  #details_section .contactIcon-center {
    text-align: center !important;
  }

  // Single Product Details
  .wpc_single_product_details {
    padding: 1.5rem;

    .wpc_single_product_image {
      height: 55vh;
    }

    .contactIcon {
      padding-top: 100px;
      text-align: center;
    }

    .saveBtn {
      bottom: 10px;
    }
  }

  // .................sajid works
  .contact .custom_inp_group {
    .phone__input {
      padding: 5px 80px;
    }

    .countryInput {
      position: absolute;
      bottom: 2px;
      left: 0;
    }

    button {
      width: 30px;
      padding: 2px 5px;
      margin: 0;
      border: 0;
      background: 0;

      &:hover {
        background: #eee;
      }
    }
  }

  .countrySelectDropwDownList {
    display: none;
    position: absolute;
    // display: none;
    width: 250px;
    background: #fff;
    /* margin: 0; */
    padding: 10px;
    box-shadow: -1px 3px 7px 1px #d8d6d6;
    z-index: 1060;
    top: 0;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        line-height: 2;
        cursor: pointer;

        &:hover {
          background: #eee;
        }
      }

      .countryName {
        font-size: 15px;
        margin: 0 6px;
      }
    }
  }
  .timer-content{
    font-size: 1rem !important;
    font-weight: 600;
    @include middleMax {
      font-size: 0.8rem !important;
  }
  svg{
    font-size: 1.2rem !important;
    font-weight: 800;
    @include middleMax {
      font-size: 1rem !important;
    }
  }
}
  #reviewOrderButtonSingleProduct {
    position: fixed !important;
    left: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    z-index: 9999 !important;
    padding: 3px 3px 0px 3px;
  
    .viewMenuBtn {
      color: #fff !important;
      position: relative;
      text-transform: capitalize !important;
  
      .iconsParent {
        .iconsBg {
          background: #fff !important;
          height: 24px;
          width: 24px;
          border-radius: 50%;
          margin: 0px 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 3px;
        }
      }
    }
  }
}

.paddingForPopup {
  padding-bottom: 400px !important;
}

.paddingFirstTime {
  padding-bottom: 230px !important;

}



.mainSideBG {
  .MuiBadge-anchorOriginTopRightRectangle {
    top: -6 !important;
    right: -4 !important;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
  }

  @include middleMin {
    height: 100vh;
    background-position: -30% center;
    z-index: 1040;
    background-size: cover;
  }

  @include lowMax {
    height: 220px;
    background-size: cover;
    background-position: center;
  }

  width: 100%;

  // background-size: cover;
  .top-left-btns {
    padding: 15px 35px;

    .cartButton,
    .pastOrderButton,
    .searchButton,
    .logout {
      @include middleMin {
        padding-left: 35px;
      }

      @include lowMax {
        padding-left: 25px;
      }

      // .MuiBadge-anchorOriginTopRightRectangle {
      //     top: 8 !important;
      // }
    }

    button,
    a {
      display: block;
      border: 0;
      border-radius: 50%;
      background: $th-primary  !important;
      width: 40px;
      height: 40px;
      font-size: 15px;
      text-align: center;
      line-height: 40px;
      color: #fff;

      i {
        line-height: 40px;
      }
    }

    .fa-user {
      line-height: 0px !important;
    }

    .arabicWordButton {
      position: relative !important;
    }

    .arabicWord {
      font-weight: 300;
      bottom: 3px !important;
      position: absolute !important;
      right: 7px !important;
    }
  }
}


.storeBanner {
  display: block;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    height: 40%;
    width: 100%;
    top: 0%;
    left: 0%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
  }

  // &::before{
  //   top: 0px;
  //   background-image: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
  //   background-color: transparent;
  //   z-index: 1000;
  // }

  // @include above1200 {
  //   display: none;
  // }

  height: 240px;

  img {
    height: 100%;
    object-position: center;
    width: 100%;
    object-fit: cover;
  }

  .top_nav_bar {
    @include TopNavBar;
  }
}

.top_nav_bar_scroll {
  @include TopNavBarScroll;
}

.drawerColor {
  .MuiListItem-root {
    background: #fff !important;
  }

  .MuiListItemText-root {
    color: $th-primary  !important;
  }
}

// Top Nav
#topNav {
  @include middleMax {
    // position: relative !important;
    background-color: #fff;
  }

  // @include lowMax {
  //     margin-top: 220px;
  // }
  .bckNavbar {
    h5 {
      font-weight: 600 !important;
    }

    background: #fff;
    // padding: 15px 20px;
    // border: 1px solid #eee;
    position: relative;

    a {
      position: relative;
      z-index: 99999;
      color: #000;
      font-size: 16px;

      &.bckButton {
        position: absolute;
        left: 5%;
      }
    }
  }
}

.backBtn2 {
  color: #000 !important;
  /* background: #9c9e9f; */
  border-radius: 10px !important;
  border: 1px solid #afafaf !important;
  margin: 8px 10px !important;
  padding: 12px 1px !important;

  .MuiSvgIcon-root {
    fill: currentColor;
    width: 1.1em !important;
    height: 0.6em !important;
    padding: 0px 0px 0px 6px !important;
  }
}

.backBtnBorderLess {
  color: #000 !important;
  /* background: #9c9e9f; */
  border-radius: 10px !important;
  margin: 8px 10px !important;
  padding: 12px 1px !important;

  .MuiSvgIcon-root {
    fill: currentColor;
    width: 1.1em !important;
    height: 0.6em !important;
    padding: 0px 0px 0px 6px !important;
  }
}

.backBtnAr {
  color: #000 !important;
  /* background: #9c9e9f; */
  border-radius: 10px !important;
  border: 1px solid #afafaf !important;
  margin: 8px 10px !important;
  padding: 12px 1px !important;

  .MuiSvgIcon-root {
    fill: currentColor;
    width: 1.1em !important;
    height: 0.6em !important;
    padding: 0px 0px 0px 0px !important;
  }
}

.backBtnArBorderLess {
  color: #000 !important;
  /* background: #9c9e9f; */
  border-radius: 10px !important;
  margin: 8px 10px !important;
  padding: 12px 1px !important;

  .MuiSvgIcon-root {
    fill: currentColor;
    width: 1.1em !important;
    height: 0.6em !important;
    padding: 0px 0px 0px 0px !important;
  }
}

// reviewOrdButton

.top_nav_singlePro_scroll {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 1;
  transition: all 0.5s ease-in-out;

  .row {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    transition: all 0.5s ease-in-out;
    border-bottom: 1px solid lightgray;

    .parentRing {
      .ring-container-open-bill-single {
        position: relative;

        .circle {
          width: 12px;
          height: 12px;
          background-color: #EB5B5E;
          border-radius: 50%;
          position: absolute;
          top: 6px;
          left: 11px;
        }

        .ringring {
          border: 3px solid #EB5B5E;
          border-radius: 30px;
          height: 30px;
          width: 30px;
          position: absolute;
          left: 2px;
          top: -4px;
          animation: pulsate 1s ease-out;
          animation-iteration-count: infinite;
          opacity: 0;
        }

        @keyframes pulsate {
          0% {
            -webkit-transform: scale(0.1, 0.1);
            opacity: 0.0;
          }

          50% {
            opacity: 1.0;
          }

          100% {
            -webkit-transform: scale(1.2, 1.2);
            opacity: 0.0;
          }
        }
      }

    }

    .classForSvgColor {
      svg {
        color: $th-primary  !important;
        transition: all 0.5s ease-in-out;

        path {
          fill: $th-primary  !important;
        }
      }
    }


    a {
      color: $th-primary  !important;
      transition: all 0.5s ease-in-out;
    }


    .signInBtn {
      display: none;

      @include middleMax {
        display: block;
      }

      border: none !important;
      color: $th-primary  !important;
      font-size: 1.2rem !important;
      padding: 15px !important;

      .fa-stack-1x {
        line-height: inherit !important;
        position: absolute !important;
        top: -2px !important;
        color: rgb(24, 21, 21) !important;
        transition: all 0.5s ease-in-out;
      }

      .fa-stack-2x {
        color: rgb(24, 21, 21) !important;
        transition: all 0.5s ease-in-out;
      }
    }
  }
}




.mobileProduct {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1;
  // transition: all 0.5s ease-in-out;

  .row {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    // transition: all 0.5s ease-in-out;
    // border-bottom: 1px solid lightgray;

    .parentRing {
      .ring-container-open-bill-single {
        position: relative;

        .circle {
          width: 12px;
          height: 12px;
          background-color: #EB5B5E;
          border-radius: 50%;
          position: absolute;
          top: 6px;
          left: 11px;
        }

        .ringring {
          border: 3px solid #EB5B5E;
          border-radius: 30px;
          height: 30px;
          width: 30px;
          position: absolute;
          left: 2px;
          top: -4px;
          animation: pulsate 1s ease-out;
          animation-iteration-count: infinite;
          opacity: 0;
        }

        // @keyframes pulsate {
        //   0% {
        //     -webkit-transform: scale(0.1, 0.1);
        //     opacity: 0.0;
        //   }

        //   50% {
        //     opacity: 1.0;
        //   }

        //   100% {
        //     -webkit-transform: scale(1.2, 1.2);
        //     opacity: 0.0;
        //   }
        // }
      }

    }

    .classForSvgColor {
      svg {
        color: $th-primary  !important;
        transition: all 0.5s ease-in-out;

        path {
          fill: $th-primary  !important;
        }
      }
    }


    a {
      background-color: white !important;
      color: black  !important;
      transition: all 0.5s ease-in-out;
    }


    .signInBtn {
      display: none;

      @include middleMax {
        display: block;
      }

      border: none !important;
      color: $th-primary  !important;
      font-size: 1.2rem !important;
      padding: 15px !important;

      .fa-stack-1x {
        line-height: inherit !important;
        position: absolute !important;
        top: -2px !important;
        color: rgb(24, 21, 21) !important;
        transition: all 0.5s ease-in-out;
      }

      .fa-stack-2x {
        color: rgb(24, 21, 21) !important;
        transition: all 0.5s ease-in-out;
      }
    }
  }
}



#reviewOrderButtonCheckout {
  position: fixed !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  z-index: 1202 !important;
  padding: 3px 3px 0px 3px;

  .viewMenuBtn {
    color: #fff !important;
    position: relative;
    text-transform: capitalize !important;

    .iconsParent {
      .iconsBg {
        background: #fff !important;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        margin: 0px 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px;
      }
    }
  }




}
#reviewOrderButton {
  position: fixed !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  z-index: 0 !important;
  padding: 3px 3px 0px 3px;

  .viewMenuBtn {
    color: #fff !important;
    position: relative;
    text-transform: capitalize !important;

    .iconsParent {
      .iconsBg {
        background: #fff !important;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        margin: 0px 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px;
      }
    }
  }




}

.reviewOrdButton {
  // padding: 10px 15px;
  background: #fff;
  // border-radius: 10px !important;

}


.layoutonetabs {
  border-bottom: 2px solid #ebebeb !important;

  .MuiTab-textColorPrimary {
    font-family: Poppins !important;
    font-weight: 700 !important;

    color: #6b6b6b !important;
  }

  .Mui-selected {
    color: $th-primary  !important;
    font-family: Poppins !important;
    font-weight: 700 !important;
  }

  .MuiTab-textColorPrimary {
    .Mui-selected {
      color: $th-primary  !important;
      font-family: Poppins !important;
      font-weight: 700 !important;
    }
  }
}

#tab_section {
  outline: none;
  text-transform: none !important;
}


.qtyBtn {
  color: $th-primary  !important;
}

// User Section
section#User {
  .MuiPaper-root {
    box-shadow: none;
    background: transparent;
    color: #000;
  }

  .MuiTabs-flexContainer {
    justify-content: center !important;
  }

  .MuiTabs-indicator {
    background: $th-primary;
  }
}

.MuiFormLabel-root.Mui-focused {
  color: $th-primary  !important;
}

.MuiInput-underline {
  border-color: $th-primary  !important;

  &::after {
    border-color: $th-primary  !important;
  }
}

section#cart,
section#User {
  position: fixed;
  background: #fff;
  box-shadow: 1px 2px 6px 0px #4a4949;
  // opacity: 0;
  transition: 0.5s ease;
  padding: 15px;
  // top: -100%;
  min-height: 30vh;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  /* height: 100vh; */
  // z-index: -1;
  width: 100%;
  // display: flex !important;
  // align-items: center !important;
  // justify-content: space-between !important;
}

.product_price {
  font-size: 1.5rem;
  font-weight: 300;
}

// Store Busy Component
#notfound {
  padding: 2% 5%;
  // @include lowMax {
  //     margin-top: 100px;
  // }
}

#storeBusy {
  padding: 0 30px;

}

.timerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  font-family: Poppins !important;
}

//
.loadingAnimation {
  position: absolute;
  top: 20%;
  right: 0;
}

// Loading circle
.MuiCircularProgress-colorPrimary {
  color: $th-primary  !important;
}

//  changes May-21
.circularContainer {
  position: absolute !important;
  top: 50% !important;
  left: 45% !important;
  transform: translate(-50%, -50%) !important;
}

.circularContainerAr {
  position: absolute !important;
  top: 40% !important;
  left: 55% !important;
  transform: translate(-50%, -50%) !important;
}

// changes May-21 end
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.MuiCircularProgress-root {
  // position: absolute !important;
  position: fixed !important;

  text-align: center !important;
  // @include lowMax{
  //     left:44% !important;
  // }
  // top: 55vh;
}

#status {
  background: #fff;
  margin-top: 0px;

  @include lowMax {
    margin-top: 250px;
  }

  .accepted_alert {
    background: $th-primary-lighten; // Lighten
    color: #fff;
    padding: 5px 20px !important;

    a {
      color: #fff;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}

// Processing component
.content__center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  top: 0;
  position: fixed;
  z-index: 1070;
  background: #fff;
  display: none;
}


.checkOut_heading {
  font-weight: bold;
  padding-left: 2px;
  font-size: 1.5rem;
}

// sajid work
// search component work
#srchPage {
  .form-control {
    border: 0 !important;
    // padding-left: 40px;
    width: 100% !important;

    &:hover,
    &:focus {
      outline: none;
    }
  }
}

// Category Section
//Sajid Changes May-20
.categoryBanner {
  @include middleMin {
    height: 300px !important;
  }

  @include middleMax {
    height: 350px !important;
  }

  @include below600 {
    height: 300px !important
  }

  img {
    width: 100%;
    height: auto;
  }

  position: relative;

  .categoryBannerImg {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .top_nav_bar {
    @include TopNavBar;

    .row {
      align-items: center;
    }
  }

  .top_nav_bar_scroll {
    @include TopNavBarScroll;

    .row {
      align-items: center;
    }
  }

  &::before {
    content: "";
    position: absolute;
    height: 40%;
    width: 100%;
    top: 0%;
    left: 0%;
    z-index: 1;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), transparent);

    .row {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 5px 20px;

      a {
        color: #fff;
      }

      .signInBtn {
        // color: #fff !important;
        // background: transparent !important;
        // border-radius: 0 !important;
        // border: 1px solid #fff !important;
        display: none;

        @include middleMax {
          display: block;
        }

        border: none !important;
        // background: transparent !important;
        color: #fff !important;
        font-size: 1.2rem !important;
        padding: 15px !important;

        .fa-stack-1x {
          line-height: inherit !important;
          position: absolute !important;
          top: -2px !important;
          color: #fff !important;
        }
      }
    }
  }
}


// Search
section#Search {
  &.search {
    margin-top: 0%;

    @include middleMax {
      margin-top: 1%;
    }

    @include lowMax {
      margin-top: 1%;
    }
  }

  // sajid work
  margin: 20px 0 80px 0;

  .inner_prod_cate {
    position: relative;

    .prod_link {
      background: #f1f1f1;
      padding: 10px;
      color: #000 !important;
      border-radius: 10px;
    }

    a {
      text-decoration: none;
      display: block;
      color: $th-primary;

      .prod_categ_img {
        width: 100%;
        background-size: cover !important;
        color: #fff;
        text-align: center;
        position: relative;
        height: 100px;
        padding: 0;

        &::before {
          content: "";
          padding-left: 5%;
          position: absolute;
          left: 0;
          top: 0;
          // height: 100%;
          // width: 100%;
          // border-radius: 10px;
          // background: linear-gradient(
          //     rgba(0, 0, 0, 0.1),
          //     rgba(0, 0, 0, 0.2)
          // );
        }

        img {
          border-radius: 10px;
          height: 100%;
          width: 100%;
          // padding-left: 5%;
        }

        h5 {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .prdCard {
        width: 100%;
        background-size: cover !important;
        color: black;
        text-align: center;
        position: relative;
        height: 220px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
          border-radius: 8px !important;
        }

        img {
          border-radius: 10px;
          height: 100%;
          width: 100%;
        }

        h5 {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          text-transform: capitalize;
        }
      }

      .prod_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #000;

        h5 {
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
        }

        h6 {
          color: #000;
          margin: 5px 0;
          font-size: 13px;
          font-weight: 300;
        }

        p {
          font-size: 11px;
          color: rgba(0, 0, 0, 0.89);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .inner_prod {
    position: relative;

    a {
      text-decoration: none;

      .prod_categ_img {
        width: 100%;
        background-size: cover !important;
        color: #fff;
        text-align: center;
        position: relative;
        height: 100px;

        &::before {
          content: "";
          padding-left: 5%;
          position: absolute;
          left: 0;
          top: 0;
          // height: 100%;
          // width: 100%;
          // border-radius: 10px;
          // background: linear-gradient(
          //     rgba(0, 0, 0, 0.1),
          //     rgba(0, 0, 0, 0.2)
          // );
        }

        img {
          border-radius: 10px;
          height: 100%;
          width: 100%;
        }

        h5 {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .prod_categ_img_search_inhance {
        border-radius: 10px;
      }

      .prod_content_row {
        margin-top: 15%;
        padding: 10px 0px;
      }

      .prod_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #000;

        h5 {
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
        }

        h6 {
          color: #000;
          margin: 5px 0;
          font-size: 18px;
          font-weight: 300;
        }

        p {
          font-size: 11px;
          color: rgba(0, 0, 0, 0.89);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    // .prod_categ_title {
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     transform: translate(-50%, -50%);
    //     color: #fff;
    // }
  }
}

// CheckOut Component Css
// changes by sajid may-24  ...........
.checkOut_Nav {
  background: #fff;
  padding: 10px;

  // border: 1px solid #eee;
  .MuiSvgIcon-root {
    font-size: 1.4rem !important;
    color: #000;
  }

  strong {
    font-weight: 900;
    font-size: 1.4rem;

    @include middleMax {
      font-size: 1.4rem;
    }
  }

  .userIcon {
    display: block;
    border: 0;
    border-radius: 50%;
    background: $th-primary;
    width: 25px;
    height: 25px;
    // font-size: 20px;
    text-align: center;
    line-height: 40px;
    color: #fff;

    i {
      font-weight: 900;
      margin-top: 20%;
    }
  }
}

// changes end sajid may-24
#paymentSection {
  display: flex;
  margin-top: 2%;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px 20px;

  .payCart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2%;
  }

  h4 {
    font-size: 1rem;
    font-weight: 300;
  }

  h3 {
    font-size: 1.3rem;
    font-weight: 300;
  }

  .totalAmount {
    font-size: 1.3rem;
  }

  .payText {
    color: gray;
  }
}

#cashBack {
  // margin-top: 5%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 15px 20px;

  .cashback-content {
    display: flex;
    background: #f9f9f9;
    padding: 10px !important;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px !important;
  }


  h2 {
    font-size: 1rem;
    font-weight: 300;
  }

  h4 {
    font-size: 1rem;
    font-weight: 300;
    color: #000;
  }

  h3 {
    font-size: 1.3rem;
    font-weight: 300;
  }

  .icons {
    display: flex;
    align-items: center;

    img {
      margin: 0px 10px;
    }
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: $th-primary;
  }
}


.pay_at_table {
  // margin-top: 5%;

  .your_order {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 5px 20px;
  }

  .view_menu {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 15px 20px;

    a {
      text-decoration: none;
      color: #000;
      text-transform: capitalize !important;

      &:hover {
        text-decoration: none;
      }
    }
  }


  .viewMenu-content {
    display: flex;
    background: #FAFAFA !important;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px !important;
  }
  .viewEstimateTime-content {
    display: flex;
    background: #FAFAFA !important;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px !important;
  }

  h2 {
    font-size: 1rem;
    font-weight: 300;
  }

  h4 {
    font-size: 1rem;
    font-weight: 300;
    color: #000;
  }

  h3 {
    font-size: 1.3rem;
    font-weight: 300;
  }

  .icons {
    display: flex;
    align-items: center;

    img {
      margin: 0px 10px;
    }
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: $th-primary;
  }
}

.pay_at_table_orders {

  .your_order {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 5px 20px;
  }

  .border-bottom-pay_tablee {
    border-bottom: 1px solid #000;
    opacity: 0.1 !important;

  }

  h3 {
    font-size: 1rem;
    font-weight: 300;
  }

  .priceColor {
    color: gray !important;
  }
}


.tipCross{
  position: absolute;
  top: 1;
  top: 1 !important;
  left: 92% !important;
  z-index: 9999;
  bottom: 80% !important;
  pointer-events: none !important;
  // background: #f4f4f4;
  // border: 1px solid #666666;
  //   border-radius: 50%;
  //   padding: 1px;
  //   padding: 0px 2px !important;

  @include  below600 {
    left: 80% !important;
    bottom: 82% !important;
    // padding: 0px 4px !important;
  }
  button{
  border: 1px solid #666666;

  }
  .MuiSvgIcon-root{
    color: #000 !important;
    font-size: 1rem !important;
    @include below768{
      font-size: 0.8rem !important;

    }
  }
  button{
    background-color: #f4f4f4;
    padding: 0 !important
  }
}

#tip {
  .custom-tip-btn{
    .MuiButton-startIcon{
      margin: 0px 5px !important;
    }
    .MuiButton-root{
    color: $th-primary !important;  
      font-size: 14px !important;
      font-family: Poppins !important;
    }
  }
  .tip-content {
    .Mui-selected {
      background-color: #f4f4f4 !important;
      border-radius: $borderRadius  !important;
      border: 2px solid #000000;
      color: #000000;
      font-size: 9px !important;
      font-family: Poppins !important;
      font-weight: 800;

      @include below375 {
        font-size: 7px !important;
      }

      @include x-small-max {
        font-size: 6px !important;
      }
    }

    .TabUnstyled-root {
      border: 1px solid #f4f4f4 !important;
      background-color: #fff;
      border-radius: 0 !important;
      font-size: 16px !important;
      font-family: Poppins !important;
      font-weight: 800;
      color: #000000;

      @include below1200 {
        font-size: 13px !important;
      }

      @include below375 {
        font-size: 10px !important;
      }

      @include x-small-max {
        font-size: 8px !important;
      }
    }

    .TabsListUnstyled-root {
      background-color: #ffff !important;
      min-width: 100% !important;
      margin: 0px !important;
    }

    .percentage {
      font-size: 12px !important;
      color: #666666;

      @include below768 {
        font-size: 9px !important;
      }

      @include x-small-max {
        font-size: 6px !important;
      }
    }

    .price {
      font-size: 13px !important;

      @include below768 {
        font-size: 10px !important;
      }

      @include x-small-max {
        font-size: 8px !important;
      }
    }

    img {
      height: 22px !important;
    }
  }

  // margin-top: 5%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 5px 20px;

  .tip-content {
    display: flex;
    // background: #f9f9f9;
    padding: 10px !important;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px !important;
  }

  h2 {
    font-size: 1rem;
    font-weight: 300;
  }

  h4 {
    font-size: 0.8rem;
    font-weight: 300;
    color: #000;
  }

  h3 {
    font-size: 1.1rem;
    font-weight: 300;
  }

  .icons {
    display: flex;
    align-items: center;

    img {
      margin: 0px 10px;
    }
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: $th-primary;
  }
}


#promoCode {
  margin-top: 5%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 0%;
  // padding: 0px 20px;


  .MuiButton-root {
    padding: 0 !important;
  }
  .Add_vocher_btn{
    color: $th-primary !important;
  }

  // .MuiButton-containedPrimary {
  //   color: #3f51b5 !important;
  //   background-color: transparent !important;
  //   font-weight: 600 !important;
  //   box-shadow: none !important;

  //   .MuiButton-label {
  //     width: 100% !important;
  //     display: flex !important;
  //     align-items: flex-start !important;
  //     justify-content: normal !important;
  //     font-size: 1.1rem !important;
  //   }
  // }

  .MuiInput-underline:before {
    border: none !important;
  }
  .MuiFilledInput-underline:after {
    border-bottom: 2px solid $th-primary !important ;
}

  .loyality_card_vocher {
    border-radius: 8px !important;
    background: #fff !important;
    border: 1px solid #EEE !important;
    .CheckboxParent{
      .Mui-checked{
        color: $th-primary !important ;
      }
    }

    .liner{
      color: #5D5E65 !important;
    }
 

  }

  // styling for textarea
  // h3 {
  //     font-size: 1.3rem;
  //     font-weight: 300;
  // }
  // .promoContent {
  //     display: flex;
  //     align-items: center;
  //     justify-content: space-between;
  //     .MuiButton-containedPrimary {
  //         background-color: $th-primary !important;
  //     }
  // }
}


#head-popup {
  display: block;
  align-items: center;
}

#menuContainer {
  display: inline-block;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;

  .pay-btn {
    display: inline;
  }

  .menu-inner-container {
    // width: 3000px;
    padding-left: 0px !important;
    text-align: center;

    strong {
      font-size: 0.9rem;
    }

    .KD {
      font-size: 0.9rem;
    }
  }
}

#menuContainer input {
  display: inline-block;
}

.selectPayment .selectPaymentRow .paymentContainer {
  input:checked~label {
    background: $th-primary  !important;
    border: none !important;
    color: #fff !important;
    opacity: 1 !important;
    border-radius: 5px !important;
  }

  label {
    background-color: $th-primary  !important;
    color: #fff !important;
    border: none !important;
    border-radius: 5px;
    text-align: center;
  }
}

.popUp-content {
  display: block !important;

  h3 {
    strong {
      font-size: 1.2rem !important;
    }
  }

  h4 {
    font-size: 1.1rem !important;
    color: $secondary  !important;
    font-weight: 600 !important;
  }

  .amount-head {
    display: block;
    // padding: 25px 0px;
    padding: 0px;

    h5 {
      font-size: 1rem !important;
      font-weight: 600 !important;
    }
  }

  .btn-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .popUp-amount {
    font-size: 1.1rem;
    font-weight: 900;
    // border: 1px solid $primary;
    // border-radius: 5px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $th-primary  !important;
    }

    .MuiOutlinedInput-input {
      /* padding: 18.5px 14px; */
      padding: 0px;
    }

    p {
      font-size: 0.7rem !important;
      color: gray !important;
    }

    .amount-field:focus {
      border: 1px solid $th-primary  !important;
      border-radius: 5px !important;
    }

    .amount-field {
      padding: 20px;

      @include lowMax {
        padding: 10px !important;
      }

      // color: $primary !important;
      color: #000;
      font-size: 1.2rem !important;

      input {
        text-align: center;
      }

      border: 1px solid $th-primary  !important;
    }
  }
}

.WithStyles\(ForwardRef\(DialogContent\)\)-root-12 {
  padding: 0px 16px !important;

  @include lowMax {
    padding: 0px 16px !important;
  }

  @include x-small-max {
    padding: 0px 16px 10px 16px !important;
  }
}

.logoutButton__icon {
  @include lowMax {}

  position: absolute;
  width: 100%;
  bottom: 0;

  button {
    width: 100%;

    .MuiButton-label {
      justify-content: left;
    }
  }
}

.paymentContainer {
  position: relative;

  input {
    visibility: hidden;
    position: absolute;
  }

  span {
    font-size: 12px;
    // color: $primary;
  }

  label {
    width: 100%;
    // height: 100%;
    line-height: 30px;
    background: #fff;
    // border-radius: 8px;
    cursor: pointer;
    transition: 0.3s ease;
    user-select: none;
    border-bottom: 5px solid #fff;
    opacity: 0.5;
    padding: 10px 0;

    &:hover {
      background: #fff;
      border-color: $th-primary;
      opacity: 1;
    }
  }

  input:checked~label {
    // background: $primary;
    background: #fff;
    border-color: $th-primary;
    opacity: 1;
  }
}

//  /loader Animation
.loader {
  color: $th-primary;
  font-size: 20px;
  margin: 100px auto;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load4 {

  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em,
      -3em 0 0 -1em, -2em -2em 0 0;
  }

  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }

  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }

  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em,
      -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0,
      -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0,
      -2em -2em 0 -1em;
  }

  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0,
      -3em 0em 0 0.2em, -2em -2em 0 0;
  }

  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0,
      -2em -2em 0 0.2em;
  }
}

@keyframes load4 {

  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em,
      -3em 0 0 -1em, -2em -2em 0 0;
  }

  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }

  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }

  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em,
      -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0,
      -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0,
      -2em -2em 0 -1em;
  }

  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0,
      -3em 0em 0 0.2em, -2em -2em 0 0;
  }

  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0,
      -2em -2em 0 0.2em;
  }
}

// loading end
#addNoteSection {
  display: flex;
  padding-top: 10px;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 50px !important;

  h4 {
    font-weight: 300;

    @include lowMax {
      font-size: 1rem;
    }
  }

  .h2 {
    font-weight: 300;
  }

  .textArea {
    width: 100%;
    font-size: 1.2rem;
    font-weight: 300;
    padding: 10px;
    margin-top: 15px;
    border: none;
    resize: none;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.05) !important;

    &:focus {
      outline: none;

      @include lowMax {
        max-height: 180px;
        font-size: 1rem;
      }
    }

    .noteContent {
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
          font-weight: 300;
        }

        h4 {
          font-weight: 300;

          @include lowMax {
            font-size: 1rem;
          }
        }

        span {
          color: gray;
        }
      }
    }
  }
}

#single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;


  // margin-top: 100px;
  // // changes by sajid
  // @include lowMax {
  //     margin-top: 0;
  // }
  .row {
    width: 100%;
  }

  h4 {
    font-weight: 300;
  }

  p {
    padding: 2px 5px;
  }

  .prod-single {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ShadowboxIncDec{
      box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px !important;
      border-radius: 10px !important;
      padding: 10px 4px 4px 4px !important ;

    }
    .ShadowboxIncDecAr{
      box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px !important;
      border-radius: 10px !important;
    }
  }

  .single-prod-qty {
    font-weight: 300;
    font-size: 1.5rem;

    #btn {
      color: $th-primary;
    }
  }

  .single-prod-price {
    font-weight: 300;
    h4{
      font-size: 1.2rem !important;
    }

  }

  .currentColor {
    width: 110px;
    height: 30px;
    margin-left: 15px;
  }

  .colorVariation {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    cursor: pointer;
  }

  .currentSize {
    padding: 2px 5px;
    margin-left: 24px;
    border: 2px solid #000;
    cursor: pointer;
  }

  .SizeVariation {
    text-align: center;
    width: 35px;
    margin-left: 10px;
    border: 2px solid #000;
    cursor: pointer;
    padding: 2px 5px;
  }


}

#single-pro {
  display: flex;
  padding: 10px 20px;



  h5 {
    font-weight: 300;
  }

  .choosecontent {
    font-weight: none;
  }

  .chooseOne-content {
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: grey;
  }

  span {
    font-size: 1.2rem;

    @include lowMax {
      font-size: 1rem;
      // white-space: nowrap;
      overflow: hidden;
      // text-overflow: ellipsis;
    }
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: $th-primary  !important;
  }

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: $th-primary  !important;
  }

  .MuiRadio-colorPrimary.Mui-checked {
    color: $th-primary  !important;
  }

  .MuiCheckbox-root {
    color: $th-primary  !important;

  }

  .MuiCheckbox-root :hover {
    background-color: transparent !important;
  }

  .MuiSvgIcon-root {
    font-size: 1.3em;

    @include lowMax {
      font-size: 1.1em !important;
    }
  }

  .MuiIconButton-label:hover {
    background-color: transparent !important;
  }

  .MuiButtonBase-root:hover {
    background-color: transparent !important;
  }

  .MuiCheckbox-root:hover {
    background-color: transparent !important;
  }
}

#process-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 10px 20px;
  background-color: #fff;

  .ProcessBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 15px !important;
  }
}

.img-pro {
  max-width: 75%;
  height: auto;

  @include lowMax {
    max-width: 85%;
    height: auto;
  }

}

.appBar {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

// css for category tabs
.tabsSec {
  .MuiTabs-flexContainer {
    color: $th-primary  !important;
  }

  .MuiTabs-scrollButtons {
    color: $th-primary  !important;
  }



  .layoutOnetabs {
    .MuiTabs-scroller{
      display: flex !important;
      align-items:  center !important;
    }
    .MuiTabScrollButton-root {
      &:nth-child(1) {
        left: 25px !important;
        z-index: 999;
      }

      &:nth-child(4) {
        right: 25px !important;
        z-index: 999;
      }
    }

    .MuiSvgIcon-root {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: #fff !important;
      color: #000000 !important;
      box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.09);
      padding: 10px !important;
    }

    .Mui-disabled {
      opacity: 2 !important;
    }

    .MuiTab-textColorPrimary {
      font-family: Poppins !important;
      font-weight: 700 !important;
      color: #6b6b6b !important;
      max-width: 210px !important;
      border: 2px solid #f0f0f0 !important;
      border-radius: $borderRadius;
      margin: 0px 5px !important;

      img {
        height: 40px !important;
        width: 40px;
        margin-top: 6px !important;
        object-fit: cover !important;
        border-radius: 8px !important;
      }
    }

    .Mui-selected {
      font-family: Poppins !important;
      font-weight: 700 !important;
      color: #000000 !important;
      border-radius: $borderRadius  !important;
      border: 2px solid #000000 !important;
    }

    .MuiTabs-indicator {
      display: none !important;
    }
  }
}

#tabs_Sec {
  position: absolute !important;
  width: 100% !important;
  bottom: 0% !important;

  // &::before {
  //     content: "";
  //     position: absolute;
  //     // height: 100%;
  //     height: 10%;
  //     width: 100%;
  //     top: 0%;
  //     // background:   !important;
  //     box-shadow: 20px 20px 0px 30px #000;
  //     opacity: .3;
  //     background: #000 !important;}
  .MuiTab-wrapper {
    text-transform: capitalize !important;
  }

  .MuiTab-root {
    font-weight: 900 !important;
  }
}

.servicesOptions {
  @include x-small-max {
    font-size: 11px;
  }
}

#storeInfo {
  // sajid work
  // @include lowMax {
  //     margin-top: 100px;
  // }
  margin-top: 60px;

  @include lowMax {
    margin-top: 0;
  }

  border: 1px solid #eee;
  background: #fff;

  &:hover {
    background: #eee;
    transition: 0.4s;
  }

  // @include lowMax {
  //     margin-top: 100px;
  // }
  margin-top: 80px;

  @include middleMax {
    margin-top: 0;
  }

  border: 1px solid #eee;
  background: #fff;

  .storeInfo-content {
    background: #fff;
    padding: 15px 20px;
    border: 1px solid #eee;

    .storeInfo-content {
      background: #fff;
      padding: 15px 20px;
      border: 1px solid #eee;

      .MuiCheckbox-colorSecondary.Mui-checked {
        color: $th-primary  !important;
      }

      .store-img {
        width: 90px;
      }

      #title {
        color: $th-primary;
        font-weight: 300;
      }

      h5 {
        font-weight: 900;
      }

      #address {
        color: gray;
        font-weight: 300;
      }
    }

    .MuiSvgIcon-root {
      font-size: 1.4rem !important;
    }

    .MuiCheckbox-colorSecondary.Mui-checked {
      color: $th-primary  !important;
    }

    .MuiCheckbox-colorPrimary.Mui-checked {
      color: $th-primary  !important;
    }

    .MuiSvgIcon-root {
      font-size: 1.3em;

      @include lowMax {
        padding: 15px 0px;

        .store-img {
          width: 90px;
        }

        h6 {
          font-size: 0.8rem;
        }

        h5 {
          font-size: 1rem;
        }
      }

      .MuiSvgIcon-root {
        font-size: 1.4rem !important;
      }

      .MuiCheckbox-colorSecondary.Mui-checked {
        color: $th-primary  !important;
      }

      .MuiCheckbox-colorPrimary.Mui-checked {
        color: $th-primary  !important;
      }

      .MuiSvgIcon-root {
        font-size: 1.3em;

        @include lowMax {
          font-size: 1.1em !important;
        }
      }
    }
  }
}

.MyCustomButton:hover {
  background-color: black !important;
}

.backBtn {
  color: #fff !important;
  /* background: #9c9e9f; */
  border-radius: 10px !important;
  border: 1px solid #afafaf !important;
  background-color: rgba(242, 240, 240, 0.3) !important;
  margin: 8px 10px !important;
  padding: 12px 1px !important;

  .MuiSvgIcon-root {
    fill: currentColor;
    width: 1.1em !important;
    height: 0.6em !important;
    padding: 0px 0px 0px 6px !important;
  }
}

.backBtnAr {
  .MuiSvgIcon-root {
    fill: currentColor;
    width: 1.1em !important;
    height: 0.6em !important;
    padding: 0px !important;
  }
}

.paymentContainer {
  h4 {
    font-weight: 900;
  }

  h6 {
    color: gray;
  }
}

.footer-link {
  .MuiBottomNavigationAction-root.Mui-selected {
    color: $th-secondary  !important;
  }

  .MuiBottomNavigationAction-root {
    color: $th-primary  !important;
  }
}

.order_Content {
  margin-top: 20px;

  @include middleMax {
    margin-top: 20px;

    h4 {
      font-size: 1rem;
    }
  }
}

#openOrder {
  padding: 20px 20px 240px 20px;

  .rejectedColor-L1 {
    color: #f74e4e;
  }

  .readyColor-L1 {
    color: #53c146;
  }

  .pendingColor-L1 {
    color: #ffa500;
  }

  .processColor-L1 {
    color: #4595ff;
  }

  #order-pro {
    // padding-bottom: 200px !important;
    .prod_content {
      color: #000;
    }

    h2 {
      font-size: 1rem;

      @include middleMax {
        font-size: 0.9rem;
      }
    }

    h5 {
      font-size: 1.1 rem;

      @include middleMax {
        font-size: 1rem;
      }
    }
    .yourOrderhead{
      font-size: 1.1rem !important;
    }

    .statusContent {
      display: flex;
      align-self: flex-start;
      justify-content: flex-end;
      text-align: center;
      font-size: 0.8rem;

      @include middleMax {
        font-size: 0.7rem;
      }
    }
  }

  .progress_card {
    border-radius: 16px !important;
    border: 1px solid #EBEBEB !important;
    background: #fff !important;
    box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.06) !important;

    .MuiLinearProgress-bar {
      background-color: #FF595A !important;
      height: 10px !important;
      border-radius: 5px !important;
    }

    .MuiLinearProgress-root {
      background-color: #EEEEEE !important;
      height: 10px !important;
      border-radius: 5px !important;
    }

    .MuiAvatar-root {
      height: 96px !important;
      width: 96px !important;
    }

  }
}

// Global
#btnSection {

  // bottom: 3.5%;
  #addMore {
    border: 1px solid #EEEEEE !important;
    background-color: #fff !important;
    color: $th-primary  !important;

    @include lowMax {
      font-size: 0.8rem;
    }
  }

  .MuiSvgIcon-root {
    color: $th-primary  !important;
  }

  #payNow {
    font-size: 1rem;
    padding: 7px 20px;
    width: 100%;

    @include lowMax {
      font-size: 0.8rem;
    }
  }
}

.orderbckBtn {
  .MuiSvgIcon-root {
    color: black !important;
  }
}

// end changes by sajid may-25
// CartPage
#Cart {
  padding: 10px;
  // min-height: 90vh;
  height: 100%;
  background-color: #fff;

  // #wrapper {
  //     height: 100%;
  // }
  // // .noteAndTotal {
  //     position: absolute;
  //     bottom: 3%;
  //     left: 0;
  // }
  .noteContent {
    margin-bottom: 1rem;

    textarea {
      border: 0;
      padding: 5px 15px;
      width: 100%;
      border-radius: 7px;
      background-color: rgba(0, 0, 0, 0.05) !important;

      &:focus {
        outline: none;
      }

      // box-shadow: inset 9px 9px 18px #f5f5f5, inset -9px -9px 18px #ffffff;
    }
  }
}

// Info Section Styling
section#info {
  // border: 1px solid #eee;
  background: #f9f9f9;
  padding: 20px;
  // border-radius: 10px;
  cursor: pointer;

  // &:hover {
  //     background: #eee;
  //     transition: 0.4s;
  // }
  a {
    padding: 15px 20px;
    display: block;
    color: #000;

    &:hover {
      text-decoration: none;
    }

    .logoImg {
      width: 60px;
      border-radius: 8px;

      img {
        border-radius: 8px;
      }
    }

    .infoContent {
      .store-name{
        font-size:  16px !important;
      }
      h6,
      p {
        margin: 0;
      }

      .small {
        color: grey;
      }

      .paymentIcons {
        color: rgb(59, 236, 59);
        font-size: 16px;
        padding: 2px 0;

        i.fa {
          margin-right: 7px;
        }
      }
    }

    .infoIcon {
      font-size: 16px;
      color: grey;
      margin-right: 20px;
    }
  }

  .am_loggedIn {
    background: #55708b;
    padding: 10px 20px;
    border-radius: 8px;
    color: #fff;
    margin-top: 20px;
  }
}

.accpetedPay {
  display: flex !important;
  align-items: center !important;

  span {
    padding: 0px 0px !important;
  }
}

#headerBtn {
  display: inline-block !important;

  @include below1200 {
    display: inline-block !important;
  }

  .lang-text {
    color: white !important;
    font-size: 1.05rem !important;

  }



  .lang-textScroll {
    color: $th-primary  !important;
    font-size: 1.09rem !important;
    transition: all 0.5s ease-in-out;

  }

  .classForPositionAR {
    position: relative !important;
    bottom: 5px !important;
  }
}

.EnOnLarge {
  color: white !important;
  font-size: 1.1rem !important;
}

// Login Modal
#Login-Modal {
  .login-head {
    text-align: center;

    // padding-bottom: 20px;
    h2 {
      font-weight: 800;
      font-family: Montserrat !important;
      color: #000000;
    }

    #social-icons {
      display: flex;
      justify-content: center;
      text-align: center;
      // margin-top: 20px;
      // padding-bottom: 20px;
    }
  }

  .loginOR {
    p {
      text-align: center !important;
      border-bottom: 1px solid #707070 !important;
    }
  }

  .login-fields {
    max-width: 100%;
    // padding: 10px 0px;
    display: block;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    // .MuiFilledInput-underline:after {
    //     border-color: none !important;
    // }

    .MuiFilledInput-input {
      padding: 12px 12px !important;
      // text-transform:  capitalize !important;
    }

    .MuiFilledInput-underline {
      border: none !important;
    }

    .MuiFilledInput-underline:before {
      border: none !important;
    }

    .MuiFilledInput-underline:after {
      border-bottom: 2px solid $primary  !important;
    }

    .row {
      padding: 1px !important;
    }

    p {
      width: 100%;
      // text-align: center;
      // border-bottom: 1px solid #707070;
      line-height: 0.1em;
      margin: 10px 0 20px;

      span {
        background: #fff;
        padding: 0 10px;
        font-weight: 300;
        font-family: Montserrat !important;
      }
    }

    button {
      width: 100%;
      font-weight: 700 !important;
      padding: 12px;
      font-family: Montserrat !important;

      @include x-small-max {
        font-size: 0.7rem !important;
      }
    }

    .register-btn {
      width: 100%;
      color: #0e2855 !important;
      background-color: none !important;
      background: none !important;
      border: 1.5px solid #0e2855 !important;
      border-radius: 5px !important;
      text-align: center;
      padding: 10px;
      font-weight: 300;
      font-family: Montserrat !important;
    }

    .forget-field {
      span {
        font-weight: 300;
        font-family: Montserrat !important;
        color: #000000;

        @include x-small-max {
          font-size: 7px !important;
        }

        @include lowMax {
          font-size: 0.6rem;
        }
      }

      .forgetpass {
        span {
          font-weight: 300 !important;
        }
      }
    }
  }
}

.MuiDialog-paperFullWidth {
  min-height: 0px !important;
  /* width: calc(100% - 64px); */
  /* max-width: 90%; */
  min-width: 0 !important;
}

.phone_code {
  #registerPhoneArrowDown {
    .MuiInput-underline:before {
      left: 0;
      right: 0;
      bottom: 0;
      content: "\00a0";
      position: absolute;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-bottom: none !important;
      pointer-events: none;
    }
  }

  .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin-top: 0px !important;
  }
}

// Login Modal
.MuiBadge-badge {
  // font-size: 0.5rem !important;
  height: 20px !important;
  width: 15px !important;
  padding: 10px 6px !important;
}

.MuiBadge-colorPrimary {
  background-color: red !important;
}

// .MuiBadge-anchorOriginTopRightRectangle {
//     left: 3 !important;
// }
.searhIcon {
  .MuiBadge-anchorOriginTopRightRectangle {
    top: 0px !important;
    // left: 0px;
    right: -5px !important;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;

    span {
      color: white !important;
    }
  }

  .MuiIconButton-root {
    // /* padding: 3px !important; *
    padding: none !important;
  }
}

.nav-link {
  // color: $th-primary;
  padding: 0 !important;

  &.active {
    .nav-link {
      color: $th-secondary;
    }
  }

  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 2px;
  }

  &.other-links {
    color: #666666;

    &::after {
      bottom: 0;
      height: 100%;
      background-color: #eee;
      left: 0;
    }

    &:nth-last-child(3) {
      &::after {
        position: relative;
      }
    }
  }

  &.text-link {
    font-size: 14px;

    &::after {
      right: 0;
      top: 28%;
      height: 50%;
      background: $th-primary;
    }
  }
}

.makeStyles-drawer-24,
.makeStyles-drawer-14,
.makeStyles-drawer-15 {
  width: 0px !important;
}

.drawer {

  // .MuiSvgIcon-root{
  //     color: $th-primary !important;
  // }
  .logOutBtn {
    color: red !important;
  }

  .drawer_header {
    span {
      text-transform: capitalize !important;
      font-weight: bolder !important;
    }
  }
}

#walletBody {
  background-color: #fff;

  Row {
    h5 {
      font-size: 1.2rem;
      font-weight: 300;
    }
  }

  .payWith {
    h5 {
      font-size: 1.2rem;
      font-weight: 300;
    }
  }

  #promoCode {
    margin-top: 5%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 0%;
    padding: 15px 20px;

    .MuiButton-containedPrimary {
      color: $primary  !important;
      background-color: transparent !important;
      font-weight: 300 !important;
      box-shadow: none !important;
      text-align: center !important;
      text-transform: none;

      .MuiButton-label {
        width: 100% !important;
        display: flex !important;
        align-items: flex-start !important;
        // justify-content: normal !important;
        font-size: 1.1rem !important;
        text-align: center !important;
      }
    }

    .MuiInput-underline:before {
      border: none !important;
    }

    // styling for textarea
    // h3 {
    //     font-size: 1.3rem;
    //     font-weight: 300;
    // }
    // .promoContent {
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     .MuiButton-containedPrimary {
    //         background-color: $th-primary !important;
    //     }
    // }
  }
}

.drawerHeader {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.drawerIcon {
  color: $th-primary  !important;
}

.cart-items {
  p {
    font-size: 1.2rem;

    @include lowMax {
      font-size: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.my-element {
  display: inline-block;
  margin: 0 0.5rem;
  animation: bounce;
  /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s;
  /* don't forget to set a duration! */
}

// tool tip
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}


.MuiTabs-indicator {
  background-color: $th-primary  !important;
  // height: 100% !important;
  // border-radius: 8px !important;
}

.MuiTab-textColorInherit.Mui-selected {
  z-index: 099;
  color: $th-primary;
  border-color: $th-primary;
}


// 26 sep night
.MuiFilledInput-root {
  background-color: #f9f9f9 !important;
}



.MuiIconButton-sizeSmall {
  padding: none !important;
  font-size: 1.125rem;
}

// 15 october
@media screen and (min-width: 480px) {
  .passwordCondition p {
    line-height: 14px !important;
  }
}

@media screen and (max-width: 480px) {
  .passwordCondition p {
    line-height: 14px !important;
  }
}

.disableClass {
  pointer-events: none !important;
  opacity: 0.7 !important;
  // background-color: rgba(136, 136, 136, 0.5) !important;
  background-color: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) !important;
}

// 23 october

.forArrowInArabic {
  .MuiSelect-icon {
    position: absolute !important;
    left: 0 !important;
    right: auto !important;
  }
}

.forArrowInArabic {
  .MuiInputLabel-shrink {
    transform: translate(0, 1.5 px) scale(0.75);
    transform-origin: top right;
  }

  .MuiInputBase-input {
    text-align: right !important;
  }
}

.forArrowInArabicPhone {
  direction: ltr !important;
}

.ordersForm {
  span {
    text-transform: capitalize !important;
  }
}

.genderRowSignUpModal {
  .MuiSelect-iconFilled {
    position: absolute;
    right: 22px !important;
  }
}

.offset-xs-2-rtl {
  margin-right: 16.6666666667% !important;
}

@media screen and (max-width: 600px) {
  .offset-xs-2 {
    margin-left: 16.6666666667% !important;
  }
}

.AddPromoCheckout {
  .MuiInputBase-inputMultiline {
    cursor: pointer !important;
  }
.MuiCircularProgress-root{
  position: absolute !important;
}
  .MuiFormLabel-root {
    color: $th-primary  !important;
  }
  .MuiFilledInput-root{
    border: 1px solid #EEE !important;
  }
  .MuiFilledInput-root{
  background-color: #fff !important;
  border-radius: 10px !important;
  }
}
.AddPromoCheckoutAR{
  .MuiFormLabel-root {
    position: absolute;
    right: 20px !important;
    left: auto !important;

  }
}

.topUpPayments {
  h4 {
    font-size: 1rem !important;
  }
}

.kep-login-facebook.metro {
  border-radius: 0;
  margin-bottom: 25px !important;
}

.socialLogin {
  border: 2px solid #d3d1d1 !important;
  border-radius: 50% !important;
  padding: 30px !important;
  height: 50px !important;
  width: 50px !important;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 20px !important;
  color: $th-primary  !important;
  cursor: pointer !important;
}

#DineIn {
  .MuiFormLabel-root.Mui-focused {
    color: $th-primary  !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $th-primary  !important;
  }

  .flagDiv {
    display: flex;
    // padding-top: 13px !important;
    padding-bottom: 3px !important;
  }

  .TableNumber {
    background-color: #fafafa !important;
  }

  .labelFonts {
    .MuiInputLabel-formControl {
      font-weight: 550 !important;
      color: #000 !important;
    }
  }
}

.infoIconOnArabic {
  .MuiButton-startIcon {
    display: inherit;
    margin-left: 8px !important;
    margin-right: -4px !important;
  }
}

.bottomFixedBtn {
  margin-top: auto !important;
  background-color: #ffff !important;
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
}

.singleProductVariationParent {
  .checkboxParent {
    .MuiIconButton-label {
      color: $th-primary  !important;
    }

    .MuiSvgIcon-root {
      color: #bbbbbb !important;
    }

    .Mui-checked {
      .MuiSvgIcon-root {
        color: $th-primary  !important;
      }
    }
  }

  .classforPaddingcheckbox {
    .MuiCheckbox-root {
      padding-left: 0px !important;

      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

.direction-ltr {
  direction: ltr !important;
}
.direction-rtl {
  direction: rtl !important;
}

.d-none-below425 {
  @include below425 {
    display: none !important;
  }
}

.show-above425 {
  @include above425 {
    display: block !important;
  }
}

.fbLoginOnLargeScreen {
  display: flex !important;

  @include below425 {
    display: none !important;
  }
}

.fbLoginOnsmallScreen {
  display: none !important;

  @include below425 {
    display: flex !important;
  }
}


.googleLoginOnLargeScreen {
  display: flex !important;

  @include below425 {
    display: none !important;
  }
}

.googleLoginOnsmallScreen {
  display: none !important;

  @include below425 {
    display: flex !important;
  }
}

.appleLoginOnLargeScreen {
  display: flex !important;

  @include below425 {
    display: none !important;
  }
}

.appleLoginOnsmallScreen {
  display: none !important;

  @include below425 {
    display: flex !important;
  }
}

#TipCheckBoxParent {
  margin-top: 10px !important;
  .tip-btns{
    width: 100%  !important;
  }
  // .TipselectPaymentRow {
  //   display: flex;
  //   flex-wrap: wrap; /* Ensures items wrap to the next line if necessary */
  //   justify-content: space-between; /* Spreads items evenly */
  //   gap: 1rem; /* Adds spacing between items */
  //   width: 100%; /* Ensures the row spans full width */
  // }
  .TipselectPaymentRow {
    .MuiButton-label {
      height: 100% !important;
    }

    .tipManinCol {
      padding: 0 0.25rem !important;

      &:nth-child(1) {
        padding: 0 !important;
      }

      &:nth-child(4) {
        padding: 0 !important;

      }
    }

    .MuiButton-outlined {
      border: none !important;
    }

    .paymentContainer {
      position: relative;
      text-align: center !important;
      height: 100% !important;
      width: 100% !important;

      input {
        visibility: hidden;
        position: absolute;
      }

      .tiptext {
        color: #9898a1 !important;
      }

      p {
        font-size: 20px;

      }

      span {
        font-size: 0.8rem;

        @include below1200 {
          font-size: 0.8rem;
        }

        @include below375 {
          font-size: 0.7rem;
        }


      }

      label {
        padding: 0px !important;
        width: 100%;
        height: 100%;
        background: #fff;
        // line-height: 60px;
        border: 2px solid #f4f4f4 !important;
        border-radius: 10px !important;
        cursor: pointer;
        transition: 0.3s ease;
        user-select: none;
        opacity: 1 !important;
        text-align: center !important;

        &:hover {
          background: #fff;
          border: 2px solid #f4f4f4 !important;
        border-radius: 10px !important;

          // opacity: 1;
        }
      }

      input:checked~label {
        // background: #f4f4f4;
        border: 2px solid #000000 !important;
        border-radius: 10px !important;
        // opacity: 1;
        text-align: center !important;
      }
    }
  }
}

.reviewOrdButton {
  .priceTag2 {
    padding: 5px 10px;
    color: white;

  }

  .itemCounter2 {
    padding: 5px 10px;
    color: white;

  }
}

.disabledCheckoutBtn {
  background-color: #cccccc !important;
  color: #FFF !important;
  border-radius: 10px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 1rem 0.5rem !important;
}

// div,p,h1,h2,h3,h4,h5,h6,body,*{
//   border: 2px solid red;
// }
#iFrameCompo {
  .header {
    .MuiButtonBase-root {
      background: #FFDEDF !important;
      margin: 4px !important;
      border-radius: 10px !important;
      padding: 12px 1px !important;
      min-width: 50px !important;
      width: 50px !important;

    }

    .MuiSvgIcon-root {
      color: #EB5B5E !important;
      // font-size: 1.8rem !important;
    }

    h3 {
      color: #EB5B5E !important;

    }

    .headingDiv {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

}

#cartItems {
  padding-bottom: 6rem !important;
}

#cartItemsNoOrder {
  .noOrderText {
    color: #000000;
    opacity: 0.6;
  }

  .retryButton {
    color: $th-primary;
    font-size: 16px !important;
  }

  .retryText {
    color: #000;
    font-size: 16px !important;
  }
}

.ShoppingCartsvg {
  path {
    fill: yellow !important;
  }
}

.ring-container-open-bill {
  position: relative;

  .circle {
    width: 12px;
    height: 12px;
    background-color: #EB5B5E;
    border-radius: 50%;
    position: absolute;
    top: 7px;
    left: 11px;
  }

  .ringring {
    border: 3px solid #EB5B5E;
    border-radius: 30px;
    height: 30px;
    width: 30px;
    position: absolute;
    left: 2px;
    top: -3px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
  }

  @keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1);
      opacity: 0.0;
    }

    50% {
      opacity: 1.0;
    }

    100% {
      -webkit-transform: scale(1.2, 1.2);
      opacity: 0.0;
    }
  }
}

.storeBusyParent {
  position: relative !important;

  .arrowArabic {
    position: absolute !important;
    right: 0 !important;
  }

  .arrowEng {
    position: absolute !important;
    left: 0 !important;
  }
}


.swing {
  animation-name: swing;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: inherit;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(10deg);
  }

  30% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(0deg);
  }

  70% {
    transform: rotate(0deg);
  }

  90% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}


.pulse {
  animation-name: pulse;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1; /* Pulsing continuously */
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}


.zoom {
  animation-name: zoom;
  animation-duration: 0.5s; /* Adjust duration as needed */
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1; /* Ensures it runs once */
}

@keyframes zoom {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}






#message-alert-checkout {
  bottom: -45% !important;
}



.animated-mail {
  height: 150px;
  width: 200px;
  -webkit-transition: .4s;
  -moz-transition: .4s;
  transition: .4s;
  border: 2px solid red !important; 
  .letter {
    width: 160px;
    height: 60px;
    background: white;
    z-index: 1;
    overflow: hidden;
    -webkit-transition: .4s .2s;
    -moz-transition: .4s .2s;
    transition: .4s .2s;
  }
}
.letter-image {
  width: 200px;
  height: 200px;
  // -webkit-transform: translate(-50%, -50%);
  // -moz-transform: translate(-50%, -50%);
  // transform: translate(-50%, -50%);
  cursor: pointer;
  border: 2px solid  yellow !important;
}

  .letter-image:hover {
    .animated-mail {
      transform: translateY(50px);
      -webkit-transform: translateY(50px);
      -moz-transform: translateY(50px);
    }
     
    .animated-mail .letter {
      height: 180px;
    }
  }

  .openBillDetails{
    z-index: 9999 !important;
  }

  .confirmPopup-disabled {
    pointer-events: none !important;
    opacity: 1 !important;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
     background-color:  #fff !important;
    }


     .store-name{
      font-size:  16px !important;
    }

    .child-name-center {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.25rem;
      text-transform: capitalize;
      color: #333;
      margin: 1.5rem 0;
    }
    
    .child-name-center::before,
    .child-name-center::after {
      content: "";
      flex: 1;
      border-bottom: 1px solid #ccc;
      margin: 0 0.75rem;
    }
    
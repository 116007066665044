// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

#error404{
    @font-face {
        font-family:"BAHNSCHRIFT";
        src: url("../../assets/fonts/bahnschrift/BAHNSCHRIFT.TTF") format("truetype");
      }
    height: 100vh;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    .all{
        border: 2px solid white;
    }
    .oops{
        font-size: 0.8rem;
        color: #808080;
        line-height: 2rem;
        letter-spacing: 4px;
    }
    .center-1f{
        font-family: 'BAHNSCHRIFT';
        letter-spacing: -30px;
        text-shadow: -5px 0px 0px #ffffff;
        font-size: 10rem;
        line-height: 6rem;
        font-weight: 900;
    }
    .center-0{
        font-family: 'BAHNSCHRIFT';
        letter-spacing: -30px;
        text-shadow: -5px 0px 0px #ffffff;
        font-size: 10rem;
        line-height: 6rem;
        font-weight: 900;
    }
    .center-lf{
        font-family: 'BAHNSCHRIFT';
        letter-spacing: 0px;
        text-shadow: -5px 0px 0px #ffffff;
        font-size: 10rem;
        line-height: 6rem;
        font-weight: 900;
    }
    .sorry{
        color: #808080;
        font-size: 0.8rem;
        line-height: 2rem;
        white-space: nowrap;
    }
}


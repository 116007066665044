// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
:root {

    --primary: #eb5b5e !important;
    --secondary: #df2124 !important;
    --success: rgb(0, 175, 0);
    --primaryLight: #4BB9EE
}

$colors: (
  blue: #3490dc,
  indigo: #6574cd,
  purple: #9561e2,
  pink: #f66d9b,
  red: #e3342f,
  orange: #f6993f,
  yellow: #ffed4a,
  green: #38c172,
  teal: #4dc0b5,
  cyan: #6cb2eb,
);

$primary: var(--primary);
$secondary: var(--secondary);
$success: var(--success);
$primaryLight: var(--primaryLight);

// Media Query Sizes
$Large: 992px;
$Medium: 768px;
$x-small-max: 360px;

// layout Three usage

// screens sizes
$large: 1200px;
$low: 768px;
$x-small: 360px;
$medium: 992px;
$x-large: 1440px;
$M-mobile: 375px;
$Moderate-mobile: 425px;

// fonts
$borderRadius: 10px;
$headingSize: 24px;
$paragraphSize: 16px;
// colors
$borderColor: #e1e1e1;

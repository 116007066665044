// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";


#google-map-locations{
  .map-styles{
  height: 70vh;
  width: 100%;
  position: relative;
  border-radius: 10px; // Set border-radius here;
  overflow: hidden;
}
}
// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";


#ProductsFeatured{
  .menuProd{
    justify-content: space-between !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    height: 250px !important;
    align-items: normal !important;
  }
}
.addToCartBtn{
  border-radius: 10px !important;
}


.smallIcons {
  button {
    min-width: 48px !important;
    padding: 6px 10px !important;
  }

  .deleteIcon {
    background-color: #EB5B5E !important;
  }

}


.FPIncDecBtnMain {
  .MuiButton-root {
    padding: 0px !important;
    border-radius: $borderRadius;
    height: 26px !important;
    min-width: 26px !important;
    width: 26px;
  }

  .decrementBtn {
    background-color: #BABABA !important;
  }

  .incrementBtn {
    background-color: $th-primary  !important;
  }




  .IncDecValue {
    height: 27px !important;
    width: 42px !important;
  }
}



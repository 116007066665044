// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

#applePayButtonl1{
  padding: 17px 0px !important;
  border-radius: 12px !important;
}

.applePayButtonl1 {

  background: #000 !important;
  font-family: HelveticaNeue-Medium !important;
  font-size: 17px !important;
  padding-bottom: 1.7px !important;
  padding-right: 6px !important;
  letter-spacing: 0px !important;
  color: white !important;

  .payWord {
    text-transform: capitalize !important;

  }

  .payIcon {
    text-transform: capitalize !important;
  }



}
// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

.groupOrderInviteMain {
  .InviteBtn {
    background-color: $th-primary !important;
    color: #fff !important;
    border-radius: 10px !important;
    padding: 9px 32px !important;
  }

  h3 {
    font-size: 1rem !important;
  }
  .MuiAvatar-root{
    height: 32px !important;
    width: 32px !important;
    font-size: 0.9rem !important;
    background-color: $th-primary !important;
    color: #fff !important;
  }


}

.groupOrderInviteMainArabic {}
// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

.SingleProductPopup {
  height: 90vh !important;
  position: fixed !important;
  z-index: 9999;
  bottom: 0;
  // left: 0;
  // right: 0;
  background-color: #F8F8F8 !important;
  overflow-x: hidden;
  transition: 0.5s;
  // width: 100% !important;
  border-radius: 20px 20px 0px 0px;
// @include middleMin {
//     width: 49.8% !important;
// }
@include above1200 {
    width: 49.9% !important;
}
  .cross-btn{
    position: fixed !important;
    padding: 5px 25px;
 
    @include above1200 {
      padding: 10px 25px;
      width: 49.8% !important;
  }    // right: 20px !important;
    // top: 95px !important;
    z-index: 99999 !important;
    // button{
    //   border: 1px solid rgba(0, 0, 0, 0.54) !important;
    // }
    // .MuiSvgIcon-root {
    //   fill: currentColor;
    //   width: 0.7em;
    //   height: 0.7em;
    // }
    button{
      background-color: #e8e8e8 !important;
      color: #000000 !important;
      font-family: GraphikArabic !important;
      font-weight: 600 !important;
      box-shadow: none !important;
    }
  }

  .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    display: none !important;
  }


  .bottomBtn {
    .confirmBtn {
      background-color: $th-primary !important;
      color: #fff !important;
      // font-size: inherit !important;
    }
    .clearBtn{
      background-color: #FF9E1B !important;
      color: #fff !important;
    }
    .deletebtn {
      background-color: transparent !important;
      color: $th-primary !important;
      // font-size: inherit !important;
    }
  }

  .IncDecBtnMain {
    background: #f1f1f1;
    border-radius: 16px;
    padding: 2px;

    .MuiSvgIcon-root {
      font-size: 1.5rem !important;
    }

    .decrementBtn {
      border: 1px solid !important;
      color: #000000 !important;
    }

    .incrementBtn {
      //   background-color: $th-primary  !important;
      border: 1px solid !important;
      color: #000000 !important;
    }
  }


}

// .filterProductMainArabic {
//   margin-left: auto !important;
//   right: 0 !important;
//   left: auto !important;
//   width: 100% !important;

// //   @include above1200 {
// //     width: 49.8% !important;
// // }
// }

// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

.SwipeableDrawerHomel1 {
    height: auto !important;
    position: fixed !important;
    z-index: 9999;
    bottom: 0;
    // left: 0;
    // right: 0;
    background-color: #F8F8F8 !important;
    overflow-x: hidden;
    transition: 0.5s;
    // width: 100% !important;
    border-radius: 20px 20px 0px 0px;
  // @include middleMin {
  //     width: 49.8% !important;
  // }
  @include above1200 {
      width: 49.8% !important;
  }
  
    a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 25px;
        color: #818181;
        display: block;
        transition: 0.3s;

        &:hover {
            color: #f1f1f1;
        }
    }

    .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
        display: none !important;
    }
    .content {
        h1 {
            font-size: 24px !important;
            color: #000;
          
            @include below1200 {
                font-size: 18px !important;
            }
            @include below425 {
                font-size: 14px !important;
            }
        }

        .MuiButton-root {
            border-radius: $borderRadius !important;
            width: 100% !important;
            pointer-events: initial !important;
            font-size: $paragraphSize;
            color: #000000;
            font-family: Manrope;
            font-weight: 300;
            background-color: #f4f4f4;
            cursor: pointer !important;
            @include x-small-max {
                font-size: 0.7rem;
                border-radius: 8px !important;
            }
        }
        .MuiSvgIcon-root {
            font-size: 1.1rem !important;
        }
    }
}
// .SwipeableDrawerHomel1Arabic {
//     // margin-left: auto !important;
//     // right: 0 !important;
//     left: auto !important;
//     width: 100% !important;
//     @include above1200 {
//         width: 49.8% !important;
//     }
// }


@font-face {
  font-family: "GraphikArabic";
  src: local("GraphikArabic"),
    url("../Assets/font-faimly/Graphik\ Arabic.ttf") format("truetype");
  // font-weight: bold;
}

@font-face {
  font-family: almari;
  src: url(../Assets/font-faimly/Almarai-Regular.ttf);
}

body {
  background: #f4f4f4;
  font-family: GraphikArabic !important;
}

.text-primary {
  color: $primary !important;
}

.text-secondary {
  color: $secondary !important;
}

.text-primary-light {
  color: $primary !important; //lighten($primary, 30%) !important;
}

button:focus {
  outline: none;
}

.payment_method_icon {
  width: 30px;

  @include LargeMax {
    line-height: 60px;
  }
}

.MuiInput-underline:after,
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-color: $primary !important;
}

.MuiFormLabel-root.Mui-focused {
  color: $primary !important;
}

.bg-primary {
  background-color: $primary !important;
  color: #fff !important;

  button {
    background-color: $primary !important;
  }

  &:hover {
    text-decoration: none;
    background-color: $secondary !important;
  }
}

.MuiAlert-root {
  width: 100%;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: $primary !important;
}

.am_field {
  border: 2px solid $primary;
  width: 100%;
  padding: 10px 30px;
  text-align: center;
  border-radius: 8px;
  position: relative;

  &:hover {
    border: 2px solid $secondary;
  }

  .inp_fields {
    width: 100%;
    border: none;
    // border-bottom: 1px solid rgb(139, 139, 139);
    text-align: center;
    outline: none;
    background: transparent;
    color: rgb(139, 139, 139);
  }

  .inpCurrency {
    position: absolute;
    right: 5%;
    top: 25%;
  }
}

.inp_fields[type="number"]::-webkit-inner-spin-button,
.inp_fields[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bg-primary-light {
  color: #fff !important;
  box-shadow: none !important;
  font-size: 12px !important;
  background-color: #f0f7fa !important;
}

a {
  text-decoration: none !important;
}

.small-KD {
  font-size: 16px;
}

.text-muted {
  // color: rgba(255, 255, 255, 0.8) !important;
  color: black !important;
  opacity: 0.5 !important;
}

.disableClass {
  pointer-events: none !important;
  opacity: 0.7 !important;
  // background-color: rgba(136, 136, 136, 0.5) !important;
  background-color: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ) !important;
}

.invoiceBtn {
  padding: 5px 100px !important;
}

.textOver {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

// kanwar sikandar start
.flex-wrap {
  flex-wrap: wrap !important;
}
.cursorPointer {
  cursor: pointer !important;
}
.blackBtn {
  background-color: #000000 !important ;
  color: #ffff !important;
  width: 100% !important;
}
.fbBtn {
  background-color: #4267b2 !important;
  color: #ffff !important;
  width: 100% !important;
}
.googleBtn {
  background-color: #dd4b39 !important;
  color: #ffff !important;
  width: 100% !important;
}
.samsungBtn {
  background-color: #004da6 !important;
  color: #ffff !important;
  width: 100% !important;
}
.familyManrope800 {
  font-family: Manrope !important;
  font-weight: 800 !important;
}
.familyManrope500 {
  font-family: Manrope !important;
  font-weight: 500 !important;
}
.familyManrope600 {
  font-family: Manrope !important;
  font-weight: 600 !important;
}
.familyManrope700 {
  font-family: Manrope !important;
  font-weight: 700 !important;
}
.familyManrope {
  font-family: Manrope !important;
}
.textBlack {
  color: #000000;
}
.textWhite {
  color: #fff !important;
}
.bgBlack {
  background-color: #000000 !important;
}
.bgEnabill {
  background-color: #ff5a5a !important;
}
.btnEnabill2 {
  color: #ff5a5a  !important;
  background-color: inherit !important;
}

.card-ui {
  background-color: #fafafa;
  border-radius: $borderRadius;
  border: 2px solid #ebebeb;
}
.card-simple {
  background-color: #f4f4f4;
  border-radius: $borderRadius;
}
.negative-color {
  color: #d83939 !important;
}
.w-70 {
  width: 70% !important;
}


// kanwar sikandar end
.closeBtn {
  display: flex;
}

// arabic font

.directionRtl {
  direction: rtl !important;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div,
  span,
  a,
  label,textarea {
    font-family: GraphikArabic !important ;
  }
}
.loginBackground {
  background: url(../Assets/images2/background2.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh !important;
  overflow: auto !important;
}
.fontSize1rem{
  font-size: 1rem !important;
}
.fontSize0P9rem{
  font-size: 0.9rem !important;
}
 
.textf4f4{
  color: #4F4F4F !important
}
.textf9f9{
  color: #9F9F9F !important;
}
// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

#loyalityCardsMain {
  position: relative !important;
  z-index: 0 !important;

  .largeAvatar {
    .MuiAvatar-root {
      height: 70px !important;
      width: 70px !important;

      @include below425 {
        height: 70px !important;
        width: 70px !important;
      }

      img {
        border-radius: 10px;
      }
    }
  }


  .reOrderheading {
    font-size: 20px;
    color: #000000;

    @include below1200 {
      font-size: 14px !important;
    }

    @include below768 {
      font-size: 18px !important;
    }

    @include below375 {
      font-size: 14px !important;
    }
  }

  .imgDiv {
    border-radius: $borderRadius;
    width: 80px;
  }

  .h-80px {
    height: 80px !important;
  }

  .owl-carousel {
    .owl-nav {
      display: none !important;
      justify-content: space-between !important;
      position: absolute !important;
      top: 80px !important;
      width: 100% !important;
      margin-top: 0px !important;

      button {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: #fff !important;
        color: #000000 !important;
        box-shadow: 0px 2px 18px rgb(0 0 0 / 9%);
        font-size: 1.25rem;
        margin: 0 !important;

      }
    }

    .owl-dots {
      display: none !important;
    }

    .owl-stage-outer {
      .owl-stage {
        display: flex !important;
        align-items: center !important;
      }

      .owl-item {
        // background-color: #FFF;
        border-radius: 16px;
        // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.09) !important;
        // drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.03)) drop-shadow(0px 12px 16px rgba(0, 0, 0, 0.08));
        // box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
        padding: 0px 0px !important;
        color: #ffff !important;
        // margin: 10px 10px 10px 10px !important;

        // right shide
        .CardRow {
          border-radius: $borderRadius !important;
          padding: 0 15px !important;
          .cardContent {
            // padding: 30px 0px !important;
            .punchRatingMain {
              .Mui-disabled {
                opacity: 100 !important;
                font-size: 1.3rem !important ;
                gap: 3px !important;
              }

              .MuiRating-decimal {

                .MuiRating-iconFilled {
                  color: #fff  !important;
                }

                .MuiRating-iconEmpty {
                  color: #fff   !important;

                }
              }
            }
          }

          .cardImage {
            // padding: 30px 0px !important;
          }
        }

      }
      // .active {
      //   padding: 20px 0px !important;
      // }
    }

    .cardProductName {
      color: #FFF !important;
      font-size: 16px !important;

      @include below375 {
        font-size: 10px !important;
      }
    }

    .cardPrice {
      color: rgba(255, 255, 255, 0.60) !important;
      font-size: 16px !important;
      font-weight: 300;

      // opacity: 0.6 !important;
      @include below375 {
        font-size: 11px !important;
      }
    }

    .cardDates {
      color: #666666 !important;
      font-size: 12px !important;

      @include below375 {
        font-size: 10px !important;
      }
    }

    .item {
      padding: 0px 4px !important;

      .cardNotActive {
        opacity: 0.8 !important;
        pointer-events: none !important;
      }

      .cardActive {
        cursor: pointer !important;
      }
      .disableClass{
        opacity: 0.7 !important;
      }
    }

    .orderAgain {
      .MuiButton-root {
        color: rgb(32, 32, 32) !important;
        // border-radius: 15px !important;
        background-color: transparent !important;
        font-weight: bolder !important;
      }

      .MuiButton-label {
        @include below768 {
          font-size: 10px !important;
        }
      }
    }

    .skipBtn {
      color: #000000 !important;
      text-decoration: underline !important;
    }

    .smallAvatar {
      .MuiAvatarGroup-root {
        justify-content: flex-end !important;
      }

      .MuiAvatar-root {
        @include below1200 {
          height: 30px !important;
          width: 30px !important;
        }
      }

      .MuiAvatar-root {
        border-radius: $borderRadius  !important;
      }
    }

  }
}

.classForArabicloyalityCardsMain {
  .owl-carousel {
    direction: ltr !important;

    .owl-stage-outer {
      direction: ltr !important;

      .owl-stage {
        direction: ltr !important;
      }

      .owl-item {
        direction: ltr !important;
      }

      .active {
        direction: ltr !important;
      }
    }
  }
}
// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

#Selected-fields{
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $th-primary !important; /* Focused border color */
      }
    .MuiOutlinedInput-root{
        border: $th-primary !important;
        border-radius: 10px !important;
      }
      .MuiInputBase-root{
        width: 100% !important;
      }
      .MuiInputLabel-root{
        color: $th-primary !important;
      }
}
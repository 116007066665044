// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";
#product-v3{
    .circularContainer {
      position: absolute !important;
      top: 30% !important;
      left: 45% !important;
      transform: translate(-50%, -50%) !important;
    }

    .circularContainerAr {
      position: absolute !important;
      top: 40% !important;
      left: 55% !important;
      transform: translate(-50%, -50%) !important;
    }

    &.products {
      margin-top: 30px;
      margin-bottom: 70px !important;

      @include middleMax {
        margin-top: 1%;
      }

      @include lowMax {
        margin-top: 1%;
      }
    }


    .En {
      padding: 0 !important;
      border: 3px solid #fff;
    }

    .Ar {
      padding: 0 !important;
      border: 3px solid #fff;
    }

    // sajid work
    min-height: 20vh;
    margin: 20px 0;

    .inner_prod {
      position: relative;
      .hideProductImage{
        display: none !important;
      }

      a {
        text-decoration: none;
        display: block;
        color: rgb(26, 26, 26);

        .prod_categ_img {
          width: 100%;
          background-size: cover !important;
          color: #fff;
          text-align: center;
          position: relative;
          // height: 180px !important;
          object-fit: cover !important;
          border-radius: 10px;
          height: 20vh !important;
          width: 18vw !important;

          @include lowMax {
            height: 16vh !important;
            width: 40vw !important;
          }

          @include middleMax {
            height: 20vh !important;
            width: 46vw !important;
          }

          // @include middleMin{
          //   height: 220px !important;
          // }
          img {
            border-radius: 10px;
            height: 100%;
            width: 100%;
            // padding-left: 5%;
          }

          h5 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .prod_categ_img_search_inhance {
          border-radius: 10px;
          height: 100%;
          width: 100%;
        }

        .prdCardCat {
          width: 100%;
          background-size: cover !important;
          color: #fff;
          border-radius: 10px !important;
          text-align: center;
          position: relative;
          height: 240px;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
            border-radius: 10px !important;
          }

          img {
            // border-radius: 10px;
            height: 100%;
            width: 100%;
          }

          h6{
            position: absolute;
            text-transform: capitalize;
            bottom: 16px !important;
          }

          .CategoryNameEn{
            position: absolute;
            left: 26px !important;
            // text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
            text-shadow: 1px 1px 6px rgba(0,0,0,.5);
          }
          .CategoryNameAr{
            text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
            position: absolute;
            right: 26px !important;
          }

        }

        .prod_content {
          color: #000 !important;
          h5 {
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
          } 

          h4 {
            // white-space: nowrap !important;
            // overflow: hidden !important;
            // text-overflow: ellipsis !important;
            font-size: 1.6rem !important;

            // @include lowMax {
            //   font-size: 1rem !important;
            // }

            @include middleMax {
              font-size: 1.2rem !important;
            }
          }


          h6 {
            color: #000;
            margin: 5px 0;
            font-weight: 300;
            font-size: 1.3rem;

            // @include lowMax {
            //   font-size: 1rem !important;
            // }
            @include middleMax {
              font-size: 1rem !important;

            }

        
          }

          .hascalorie{
            h6 {
              font-size: 1.3rem !important;
              @include middleMax {
                font-size: 0.8rem !important;
              }
            }
            .calorieColor{
              color: #0076CE !important;
            }
          }
       
     


          p {
            font-size: 12px;
            color: rgba(39, 39, 39, 0.89);

          }
        }
      }
    }

    .ChipClass {
      background: #F4F4F4 !important;
      border: transparent;
      height: 26px !important;
      text-transform: capitalize !important;

      .MuiChip-icon {
        margin: 0 0 0 7px !important;

      }

      .MuiChip-label {
        padding: 0px 7px !important;
      }

    }
    color: green !important
  }
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import "bootstrap";
@import "../Sass/variables";
@import "../Sass/mixins";
@import "../Sass/global";
@import "../Sass/svg_animate";

#signupPhoneModalMain {

  h1 {
    font-size: 30px !important;
    line-height: 41px;
  }
  .loginTitle {
    font-size: 16px;
    line-height: 22px;
    color: #4f4f4f !important;
  }
  .notRegisterMain {
    span {
      font-size: 16px !important;
      @include below768 {
        font-size: 12px !important;
      }
      @include extraSmall {
        font-size: 8px !important;
      }
    }
  }
  .notRegister {
    color: #4f4f4f;
  }
  .createAccountAndforgotPass {
    cursor: pointer !important;
    color: #000000 !important;
    text-decoration: underline !important;
  }
  .MuiButton-root {
    border-radius: $borderRadius !important;
    text-transform: none !important;
    font-size: 0.75rem !important;

  }

  .selectCountry {
    legend {
      display: none !important;
    }
    .MuiSelect-icon {
      @include below375 {
        display: none !important;
      }
    }
  }
  // padding for sajid bhai
  .classforBtnPadding {
    .MuiButton-root {
      // padding: 5px 16px !important;
    }
  }

  .classforPaddingTextfield {
    .MuiOutlinedInput-input {
      // padding: 11px 14px !important;
      text-align: left !important;
    }
    .MuiInputLabel-root {
      // top: -6px !important;
      color: #353535 !important;
      font-size: $paragraphSize;
      font-family: Manrope ;
      font-weight: 300;
    }
  }
  .classforPaddinglogo {
    img {
      height: 52px !important;
    }
  }


  .classforPaddingSeclectTextfield {
    .MuiOutlinedInput-input {
      padding: 15px 8px !important;
      // padding: 6.5px 14px !important;
    }
    .MuiFormControl-root {
      margin: 11px !important;
    }
  }
  // padding for sajid bhai


    // className for arabic
    .classforArabic {
      .MuiTextField-root {
        position: relative !important;
      }
      .MuiInputLabel-root {
        right: 22px !important;
        width: fit-content !important;
      }
      .MuiOutlinedInput-notchedOutline {
        text-align: right !important;
      }
      .MuiOutlinedInput-input {
        text-align: right !important;
      }
     
    }

    .classforArabicSelectField{
      .MuiSelect-outlined{
        position: relative !important;
      }
       .MuiSelect-icon {
        position: absolute !important;
        left: 5px !important;
        right: auto !important;
      }
    }
    // className for arabic
  .arabicBtn {
    background-color: #e8e8e8 !important;
    color: #000000 !important;
    font-family: GraphikArabic !important;
    font-weight: 600 !important;
  }

  .MuiOutlinedInput-root {
    border-radius: $borderRadius !important;
    font-size: $paragraphSize;
    font-family: Poppins !important;
    color: #353535;
    font-weight: 300;
    text-align: left !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 2px solid #e3e3e3 !important;
    // border: 2px solid $th-primary !important;
    text-align: left !important;
  }
  // arabic font

  .classForNonSelectableInput{
    .MuiOutlinedInput-input{
      padding: 14.5px 14px !important;
    }
    .countryCode{
      @include below375 {
        display: none !important;
      }
    }
    .flagImg{
      @include below375 {
        display: none !important;
      }
    }
  }
  .socialLoginIconOnLargeScreen {
    @include below425 {
      display: none !important;
    }
    
  }

  .socialLoginIconOnsmallScreen {
    display: none !important;
    @include below425 {
      display: flex !important;
    }
  }

}
.MuiDialog-paperFullWidth {
  max-width: 490px !important ;
  border-radius: 24px !important ;
}
.directionRtl {
  direction: rtl !important;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div,
  span,
  a,label {
    font-family: GraphikArabic !important ;
  }
}
// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";
#AddToCartPopupMain {
  background-color: #ffff;
  @include lowMax {
    display: none !important;
  }

  .MuiAlert-filledSuccess {
    background: none !important;
  }
  .MuiAlert-icon {
    display: none !important;
  }
  .MuiPaper-elevation6 {
    box-shadow: none !important;
  }
  .MuiAlert-action {
    display: none !important;
  }
  .MuiAlert-root {
    width: 60% !important;
    @include below425 {
      width: 86% !important;
    }
  }
  .MuiSnackbar-root {
    @include below375 {
      position: fixed !important;
      right: 290px !important;
    }
  }
  .circleDivParent {
    height: 32px;
    width: 32px;
    position: absolute;
    left: -16px;
    top: 34px;
    border-radius: 50%;
    background-color: #fff !important;
    z-index: 9999 !important;
  }
  .circleDiv {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: #33BA9A !important;
    @include lowMax {
      height: 25px;
      width: 25px;
    }
  }
  .imgParent {
    width: 300px !important;
    height: 82px !important;
    @include below375 {
      width: 260px !important;
    }
  }
  .imgDiv {
    border-radius: $borderRadius;
    width: 100%;
    height: 100%;
  }
  h3 {
    font-size: 14px;
    color: #000000;
    @include below1200 {
      font-size: 14px !important;
    }
    @include below375 {
      font-size: 11px !important;
    }
  }
  .card-main {
    position: relative;
    background-color: #ffff;
    border-radius: $borderRadius;
    box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.09);
  }
}
// css for scroll hide and modal width size
.MuiDialog-paperFullWidth {
  max-width: 490px !important;
  border-radius: 24px !important;
}
.MuiDialogContent-root::-webkit-scrollbar {
  display: none !important;
}
// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

#reOrderMain {
  position: relative !important;
  z-index: 0 !important;
.largeAvatar{
  .MuiAvatar-root {
    height: 70px !important;
    width: 70px !important;
    @include below425 {
      height: 70px !important;
      width: 70px !important;
    }
  
    img {
      border-radius: 10px;
    }
  }
}
  
  
  .reOrderheading {
    font-size: 20px;
    color: #000000;

    @include below1200 {
      font-size: 14px !important;
    }
    @include below768 {
      font-size: 18px !important;
    }
    @include below375 {
      font-size: 14px !important;
    }
  }

  .imgDiv {
    border-radius: $borderRadius;
    width: 80px;
  }
  .h-80px{
    height: 80px !important;
  }
  .owl-carousel {
    .owl-nav {
      display: flex !important;
      justify-content: space-between !important;
      position: absolute !important;
      top:80px !important;
      width: 100% !important;
      margin-top: 0px !important;
      button{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: #fff !important;
        color: #000000 !important;
        box-shadow: 0px 2px 18px rgb(0 0 0 / 9%);
        font-size: 1.25rem;
        margin: 0 !important;
       
      }
    }
    .owl-dots {
      display: none !important;
    }
    .owl-stage-outer {
      .owl-stage {
        display: flex !important;
        align-items: center !important;
      }

      .owl-item {
        background-color: #ffff;
        border-radius: $borderRadius;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.09) !important;
        padding: 0px 0px !important;
        color: #ffff !important;
        margin: 10px !important;
        
      }
      .active {
        padding: 20px 0px !important;
        // width: 490px !important;
      }
    }
    .cardProductName {
      color: #000000 !important;
      font-size: 16px !important;
      @include below375 {
        font-size: 10px !important;
      }
    }
    .cardPrice {
      color: #050505 !important;
      font-size: 16px !important;
      @include below375 {
        font-size: 11px !important;
      }
    }
    .cardDates {
      color: #666666 !important;
      font-size: 12px !important;
      @include below375 {
        font-size: 10px !important;
      }
    }
    .item {
      padding: 0px 15px !important;
    }
    .orderAgain {
      .MuiButton-root {
        color: rgb(32, 32, 32) !important;
        // border-radius: 15px !important;
        background-color: transparent !important;
        font-weight: bolder !important;
      }
      .MuiButton-label{
        @include below768 {
          font-size: 10px !important;
        }
      }
    }
    .skipBtn {
      color: #000000 !important;
      text-decoration: underline !important;
    }
    .smallAvatar{
      .MuiAvatarGroup-root {
        justify-content: flex-end !important;
      }
      .MuiAvatar-root {
        @include below1200 {
          height: 30px !important;
          width: 30px !important;
        }
      }
      .MuiAvatar-root {
        border-radius: $borderRadius !important;
      }
    }
   
  }
}

.classForArabicReOrderMain {
  .owl-carousel {
    direction: ltr !important;
    .owl-stage-outer {
      direction: ltr !important;
      .owl-stage {
        direction: ltr !important;
      }

      .owl-item {
        direction: ltr !important;
      }
      .active {
        direction: ltr !important;
      }
    }
  }
}
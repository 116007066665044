// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

.FeaturedProductPopupMain {
  height: 600px  !important;
  position: fixed !important;
  z-index: 9999;
  bottom: 0;
  // left: 0;
  // right: 0;
  background-color: #fff !important;
  overflow-x: hidden;
  transition: 0.5s;
  // width: 100% !important;
  border-radius: 20px 20px 0px 0px;

  @include above1200 {
    width: 49.8% !important;
  }

  .featuredProductImage {
    height: 50% !important;
    width: 50% !important;
    border-radius: 4px !important;
  }
// .MuiAvatar-square{
//   display: flex !important;
//   align-items: center !important;
//   justify-content:  center !important;
// }
  .ChipClass {
    background: #f4f4f4 !important;
    border: transparent;
    // font-size: 12px !important;
    height: 26px !important;
    text-transform: capitalize !important;

    .MuiChip-icon {
      // font-size: 12px !important;
      // margin: 0 !important;
    }
  }

  #TagCheckBoxParent {
    margin-top: 0px !important;

    .TagselectPaymentRow {
      .tipManinCol {
        padding: 0 0.25rem !important;

        &:nth-child(1) {
          padding: 0 !important;
        }

        &:nth-child(4) {
          padding: 0 !important;
        }
      }

      .MuiButton-outlined {
        border: none !important;
      }

      .paymentContainer {
        position: relative;
        text-align: center !important;
        // height: 100% !important;

        input {
          visibility: hidden;
          position: absolute;
        }

        .tiptext {
          color: #9898a1 !important;
        }

        p {
          font-size: 20px;
        }

        span {
          font-size: 0.8rem;

          @include below1200 {
            font-size: 0.8rem;
          }

          @include below375 {
            font-size: 0.7rem;
          }
        }

        label {
          padding: 0px 3px !important;
          width: 100%;
          //   height: 100%;
          background: #f4f4f4 !important;
          // line-height: 60px;
          border: 2px solid #f4f4f4 !important;
          cursor: pointer;
          transition: 0.3s ease;
          user-select: none;
          opacity: 1 !important;
          text-align: center !important;
          border-radius: 48px !important;

          &:hover {
            background: #fff;
            border: 2px solid #f4f4f4 !important;
            opacity: 1;
          }
        }

        input:checked~label {
          // background: #f4f4f4;
          border: 2px solid !important;
          // opacity: 1;
          text-align: center !important;
        }
      }
    }
  }

  a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;

    &:hover {
      color: #f1f1f1;
    }
  }

  .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    display: none !important;
  }

  .content {
    h1 {
      font-size: 18px !important;
      color: #000;
    }

    .MuiButton-root {
      border-radius: $borderRadius  !important;
      width: 100% !important;
      pointer-events: initial !important;
      font-size: $paragraphSize;
      font-family: "Poppins", sans-serif;
      font-weight: 600 !important;
      background-color: #fff !important;
      cursor: pointer !important;
    }

    .classforDietries {
      button {
        color: #000000 !important;
      }
    }

    .MuiSvgIcon-root {
      font-size: 1.1rem !important;
    }
  }

  .totalAmount {
    // font-size: 18px !important;
    color: #000000;
  }

  .bottomBtn {
   
    position: sticky !important;
    bottom: 0 !important;
    background: #fff !important;
 
    .confirmBtn {
      padding: 3px 3px 0px 3px;
      background-color: $th-primary  !important;
      color: #fff !important;
      // font-size: inherit !important;
    }

    .clearBtn {
      background-color: #FF9E1B !important;
      color: #fff !important;
    }

    .deletebtn {
      background-color: transparent !important;
      color: $th-primary  !important;
      // font-size: inherit !important;
    }
  }

  .IncDecBtnMain {
    background: #f1f1f1;
    border-radius: 16px;
    padding: 2px;

    .MuiSvgIcon-root {
      font-size: 1.5rem !important;
    }

    .decrementBtn {
      border: 1px solid !important;
      color: #000000 !important;
    }

    .incrementBtn {
      //   background-color: $th-primary  !important;
      border: 1px solid !important;
      color: #000000 !important;
    }
  }

  .pieChart {
    svg {
      width: 50% !important;
    }
  }
}

// .FeaturedProductPopupMainArabic {
//   margin-left: auto !important;
//   right: 0 !important;
//   left: auto !important;
//   width: 100% !important;

//   @include above1200 {
//     width: 41.66666667% !important;
// }
// }
// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";

.shareInvoiceQrcode {
    height: auto !important;
    position: fixed !important;
    z-index: 9999;
    bottom: 0;
    // left: 0;
    // right: 0;
    background-color: #F8F8F8 !important;
    overflow-x: hidden;
    transition: 0.5s;
    width: 100% !important;
    border-radius: 20px 20px 0px 0px;
  // @include middleMin {
  //     width: 49.8% !important;
  // }
  @include above1200 {
      width: 49.8% !important;
  }

    a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 25px;
        color: #818181;
        display: block;
        transition: 0.3s;

        &:hover {
            color: #f1f1f1;
        }
    }

    .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
        display: none !important;
    }

    .content {
        h1 {
            font-size: 18px !important;
            color: #000;

        }

        .MuiButton-root {
            border-radius: $borderRadius  !important;
            width: 100% !important;
            pointer-events: initial !important;
            font-size: $paragraphSize;
            color: #000 !important;
            font-family: 'Poppins', sans-serif;
            font-weight: 600 !important;
            background-color: #FFF !important;
            cursor: pointer !important;

            @include x-small-max {
                font-size: 0.7rem;
                border-radius: 8px !important;
            }
        }


    }

    .totalAmount {
        font-size: 18px !important;
        color: #000000;

    }

    .bottomBtn {
        .confirmBtn {
            background-color: $th-primary  !important;
            color: #FFF !important;
            // font-size: inherit !important;
        }

        .deletebtn {
            background-color: transparent !important;
            color: $th-primary  !important;
            // font-size: inherit !important;
        }

    }

    .IncDecBtnMain {
        .MuiButton-root {
            height: 40px !important;
            min-width: 36px !important;
            padding: 0px !important;
            border-radius: $borderRadius;
            width: 36px;
            border-radius: 50% !important;

            @include below1200 {
                height: 30px !important;
                min-width: 26px !important;
                width: 26px;
            }
        }

        .MuiSvgIcon-root {
            font-size: 1.5rem !important;
        }

        .decrementBtn {
            background-color: #BABABA !important;
        }

        .incrementBtn {
            //   background-color: $th-primary  !important;
            border: 1px solid !important;
            color: #000000 !important
        }



        .IncDecValue {
            height: 43px;
            width: 36px;

            @include below1200 {
                height: 27px !important;

                width: 36px;
            }
        }
    }

    .MscBannerQrcodeInner {
        .MuiIconButton-root {
            border-radius: 5px !important;
            background-color: $th-primary;
            color: #fff !important;
        }

        .downloadBtn {
            background: $th-primary  !important;
            color: #fff !important;
        }

        .cancelBtn {
            border: 1px solid $th-primary  !important;
            color: $th-primary  !important;
        }

        .MuiInputBase-input {
            padding: 14.5px 14px !important;
        }

        fieldset {
            border-radius: 10px !important;
        }

        .MuiButtonBase-root {
            &:hover {
                background-color: $th-primary  !important;
                color: #fff !important;
            }

        }


    }


}

// .shareInvoiceQrcodeArabic {
//     margin-left: auto !important;
//     right: 0 !important;
//     left: auto !important;
//     width: 100% !important;

//     // @include above1200 {
//     //     width: 49.8% !important;
//     // }
// }

.web-share-fade {
    z-index: 99999 !important;
    .web-share-fade-in-up {
        button {
          background-color: $th-primary !important;
          color: #fff !important;
        }
      }
}

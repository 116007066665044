@mixin Large {
    @media screen and (min-width: 992px) {
        @content;
    }
}

@mixin Medium {
    @media (min-width: #{$Medium}) {
        @content;
    }
}

@mixin x-small-max {
    @media (max-width: #{$x-small-max}) {
        @content;
    }
}
// Fonts
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
// Variables
@import "../../../sass/variables";
// Mixins
@import "../../../sass/mixins";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Globals
@import "../../../sass/global";


.inner_prod_v2 {

    position: relative;
    .hideProductImage{
      display: none !important;
    }

    a {
      text-decoration: none;
      display: block;
      color: rgb(26, 26, 26);

      .prod_categ_img {
        width: 100%;
        background-size: cover !important;
        color: #fff;
        text-align: center;
        position: relative;
        // height: 180px !important;
        object-fit: cover !important;
        border-radius: 10px;
        height: 16vh !important;
        // width: 50% !important;
        width: 14vw !important;

        @include lowMax {
          height: 12vh !important;
          width: 36vw !important;
        }

        @include middleMax {
          height: 16vh !important;
          width: 40vw !important;
        }

        // @include middleMin{
        //   height: 220px !important;
        // }
        img {
          border-radius: 10px;
          height: 100%;
          width: 100%;
          // padding-left: 5%;
        }

        h5 {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .prod_categ_img_search_inhance {
        border-radius: 10px;
        height: 100%;
        width: 100%;
      }

      .prdCardCat {
        width: 100%;
        background-size: cover !important;
        color: #fff;
        border-radius: 10px !important;
        text-align: center;
        position: relative;
        height: 240px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
          border-radius: 10px !important;
        }

        img {
          // border-radius: 10px;
          height: 100%;
          width: 100%;
        }

        h6{
          position: absolute;
          text-transform: capitalize;
          bottom: 16px !important;
        }

        .CategoryNameEn{
          position: absolute;
          left: 26px !important;
          // text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
          text-shadow: 1px 1px 6px rgba(0,0,0,.5);
        }
        .CategoryNameAr{
          text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
          position: absolute;
          right: 26px !important;
        }

      }

      .prod_content {
        color: #000 !important;
        h5 {
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
        } 

        h4 {
          // white-space: nowrap !important;
          // overflow: hidden !important;
          // text-overflow: ellipsis !important;
          font-size: 1.3rem !important;

          // @include lowMax {
          //   font-size: 1rem !important;
          // }

          @include middleMax {
            font-size: 0.9rem !important;
          }
        }

        .timer-content{
            font-size: 1rem !important;
            font-weight: 600;
            @include middleMax {
              font-size: 0.8rem !important;
          }
          svg{
            font-size: 1.2rem !important;
            font-weight: 800;
            @include middleMax {
              font-size: 1rem !important;
            }
          }
        }
        h6 {
          color: #000;
          margin: 5px 0;
          font-weight: 300;
          font-size: 1.1rem;
          opacity: .6!important;

          // @include lowMax {
          //   font-size: 1rem !important;
          // }
          @include middleMax {
            font-size: 0.9rem !important;

          }

      
        }

        .hascalorie{
          h6 {
            font-size: 1.2rem !important;
            @include middleMax {
              font-size: 0.7rem !important;
            }
          }
          .calorieColor{
            color: #0076CE !important;
          }
        }
     
   


        p {
          font-size: 0.75rem;
          color: rgba(39, 39, 39, 0.89);

        }
      }
    }

    position: relative;

    a {
      text-decoration: none;

      .prdcard {
        width: 100%;
        background-size: cover !important;
        color: #fff;
        text-align: center;
        position: relative;
        height: 160px;

        &::before {
          content: "";
          padding-left: 5%;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        }

        img {
          height: 100%;
          width: 100%;
        }

        h5 {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
        }
      }
    }

  

  }
.prod_main_div_v2 {


}